import { DeepPartial } from 'react-hook-form';

import { PipelineTuningErrorType } from './PipelineTuning';
import { exampleSchema1, exampleSchema2 } from './stories/exampleData';
import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { PipelineTuningV1AugurSettings as PipelineTuningAugurSettings } from 'common/dist/types/augurSettings/pipelineTuningV1AugurSettings';

export type { PipelineTuningAugurSettings };

export type PipelineTuningSchemaType = {
  id: string;
  displayName: string;
  optimizationMethod: {
    id: 'grid_search_cross_validation';
    nSplits: number;
  };
  edges: {
    sourceID: string;
    targetID: string;
  }[];
  nodes: (
    | {
        type: 'node';
        nodeType: 'transformer' | 'classifier';
        id: string;
        isOptional: boolean;
        isEditable: boolean;
        isTuneable: boolean;
        displayName: string;
        description: string;
        staticParameters: {
          id: string;
          name: string;
          displayName: string;
          description: string;
          validValues: (
            | {
                type: 'double' | 'int';
                minValue: number;
                maxValue: number;
              }
            | {
                type: 'string';
                values: string[];
              }
          )[];
        }[];
        tuningParameters: {
          id: string;
          name: string;
          displayName: string;
          description: string;
          validValues: (
            | {
                type: 'double' | 'int';
                minValue: number;
                maxValue: number;
              }
            | {
                type: 'string';
                values: string[];
              }
          )[];
        }[];
        selectedNodeId?: string;
        isInactive?: boolean;
      }
    | {
        type: 'group';
        id: string;
        nodes: {
          type: 'node';
          nodeType: 'transformer' | 'classifier';
          id: string;
          isOptional: boolean;
          isEditable: boolean;
          isTuneable: boolean;
          displayName: string;
          description: string;
          staticParameters: {
            id: string;
            name: string;
            displayName: string;
            description: string;
            validValues: (
              | {
                  type: 'double' | 'int';
                  minValue: number;
                  maxValue: number;
                }
              | {
                  type: 'string';
                  values: string[];
                }
            )[];
          }[];
          tuningParameters: {
            id: string;
            name: string;
            displayName: string;
            description: string;
            validValues: (
              | {
                  type: 'double' | 'int';
                  minValue: number;
                  maxValue: number;
                }
              | {
                  type: 'string';
                  values: string[];
                }
            )[];
          }[];
          selectedNodeId?: string;
          isInactive?: boolean;
        }[];
        displayName: string;
      }
  )[];
};

export type PipelineTuningConfig = {
  pipelineTuningSchemas: PipelineTuningSchemaType[];
};

export type ConfigPipelineTuningConfig = Config<PipelineTuningConfig>;

export type PartialPipelineTuningAugurSettings =
  Partial<PipelineTuningAugurSettings>;

export type PipelineTuningValidationError =
  DeepPartial<PipelineTuningErrorType>;

export const configAllowedInputsPipelineTuning: AllowedInputs<PipelineTuningConfig> =
  {};

export const configTypePipelineTuning = `{
  pipelineTuningSchemas: {
    source: 'hard-coded',
    id: string;
    displayName: string;
    optimizationMethod: {
      id: 'grid_search_cross_validation';
      nSplits: number;
    };
    edges: {
      sourceID: string;
      targetID: string;
    }[];
    nodes: (
      | {
          type: 'node';
          nodeType: 'transformer' | 'classifier';
          id: string;
          isOptional: boolean;
          isEditable: boolean;
          isTuneable: boolean;
          displayName: string;
          description: string;
          staticParameters: {
            id: string;
            name: string;
            displayName: string;
            description: string;
            validValues: (
              | {
                  type: 'double' | 'int';
                  minValue: number;
                  maxValue: number;
                }
              | {
                  type: 'string';
                  values: string[];
                }
            )[];
          }[];
          tuningParameters: {
            id: string;
            name: string;
            displayName: string;
            description: string;
            validValues: (
              | {
                  type: 'double' | 'int';
                  minValue: number;
                  maxValue: number;
                }
              | {
                  type: 'string';
                  values: string[];
                }
            )[];
          }[];
          selectedNodeId?: string;
          isInactive?: boolean;
        }
      | {
          type: 'group';
          id: string;
          nodes: {
            type: 'node';
            nodeType: 'transformer' | 'classifier';
            id: string;
            isOptional: boolean;
            isEditable: boolean;
            isTuneable: boolean;
            displayName: string;
            description: string;
            staticParameters: {
              id: string;
              name: string;
              displayName: string;
              description: string;
              validValues: (
                | {
                    type: 'double' | 'int';
                    minValue: number;
                    maxValue: number;
                  }
                | {
                    type: 'string';
                    values: string[];
                  }
              )[];
            }[];
            tuningParameters: {
              id: string;
              name: string;
              displayName: string;
              description: string;
              validValues: (
                | {
                    type: 'double' | 'int';
                    minValue: number;
                    maxValue: number;
                  }
                | {
                    type: 'string';
                    values: string[];
                  }
              )[];
            }[];
            selectedNodeId?: string;
            isInactive?: boolean;
          }[];
          displayName: string;
        }
    )[];
  }[]
}`;

export const augurSettingsTypePipelineTuning = `{
  id: string;
  displayName: string;
  optimizationMethod: {
    id: 'grid_search_cross_validation';
    nSplits: number;
  };
  nodes: ({
      id: string;
      type: 'node';
      nodeType: 'transformer' | 'classifier';
      displayName: string;
      description: string;
      isActive?: boolean;
      isTuneable: boolean;
      tuningParameters?: {
        id: string;
        displayName: string;
        valueCandidates: (string | number)[];
      }[];
      isEditable: boolean;
      staticParameters?: {
        id: string;
        displayName: string;
        value: string | number;
      }
    } | {
      id: string;
      type: 'group';
      nodes: {
        id: string;
        type: 'node';
        nodeType: 'transformer' | 'classifier';
        displayName: string;
        description: string;
        isActive?: boolean;
        isTunable: boolean;
        tuningParameters?: {
          id: string;
          displayName: string;
          valueCandidates: (string | number)[];
        }[];
        isEditable: boolean;
        staticParameters?: {
          id: string;
          displayName: string;
          value: string | number;
        }[];
      }[]
  })[]
  edges: {
    sourceID: string;
    targetID: string;
  }[];
}[]`;

export const defaultConfigPipelineTuning: ConfigPipelineTuningConfig = {
  pipelineTuningSchemas: {
    source: 'hard-coded',
    value: [exampleSchema1, exampleSchema2],
  },
};

export const defaultAugurSettingsDefaultPipelineTuning: PartialPipelineTuningAugurSettings =
  [
    {
      displayName: 'Standard Methods',
      edges: [
        { sourceID: 'column_rejector', targetID: 'extra_column_rejector' },
        { sourceID: 'extra_column_rejector', targetID: 'type_discarder' },
        { sourceID: 'type_discarder', targetID: 'too_many_values' },
        { sourceID: 'too_many_values', targetID: 'imputer_group' },
        { sourceID: 'imputer_group', targetID: 'low_information_discarder' },
        { sourceID: 'low_information_discarder', targetID: 'one_hot_encoder' },
        { sourceID: 'one_hot_encoder', targetID: 'classifier_group_tb' },
        { sourceID: 'one_hot_encoder', targetID: 'classifier_group_gb1' },
        {
          sourceID: 'low_information_discarder',
          targetID: 'classifier_group_gb2',
        },
      ],
      id: 'standardMethods',
      optimizationMethod: { id: 'grid_search_cross_validation', nSplits: 5 },
      nodes: [
        {
          id: 'column_rejector',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Rejected Features Filter',
          description:
            'Drops the Rejected Feature columns from the data. The Rejected Features can be defined in the Influencers tab of the Augur Details.',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'extra_column_rejector',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Unknown Column Rejector',
          description:
            'Drops columns from the Data that were not present during training. This node is only relevant for evaluation and prediction jobs and will be skipped in learning jobs.',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'type_discarder',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Unsupported Column Type Discarder',
          description:
            'Drops columns containing unsupported data types (timestamps and dates) from the data',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'too_many_values',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Too Many Values Discarder',
          description:
            'Drops categorical columns with more than Distinct Values Threshold many values from the data',
          isActive: true,
          isTuneable: false,
          isEditable: true,
          staticParameters: [
            {
              displayName: 'Distinct Values Threshold',
              id: 'values_threshold',
              value: 10,
            },
          ],
          tuningParameters: [],
        },
        {
          id: 'imputer_group',
          type: 'group',
          nodes: [
            {
              id: 'mean_inputer',
              type: 'node',
              nodeType: 'transformer',
              displayName: 'Mean Imputer',
              description:
                'Fills missing values in numeric columns with their mean',
              isActive: true,
              isTuneable: false,
              isEditable: true,
              staticParameters: [
                { displayName: 'NA Threshold', id: 'na_threshold', value: 0.1 },
              ],
              tuningParameters: [],
            },
            {
              id: 'median_imputer',
              type: 'node',
              nodeType: 'transformer',
              displayName: 'Median Imputer',
              description:
                'Fills missing values in numeric columns with their median',
              isActive: true,
              isTuneable: false,
              isEditable: true,
              staticParameters: [
                { displayName: 'NA Threshold', id: 'na_threshold', value: 0.1 },
              ],
              tuningParameters: [],
            },
            {
              id: 'constant_value_imputer',
              type: 'node',
              nodeType: 'transformer',
              displayName: 'Constant Value Imputer',
              description:
                'Fills missing values in numeric columns with a constant value',
              isActive: true,
              isTuneable: false,
              isEditable: true,
              staticParameters: [
                { displayName: 'NA Threshold', id: 'na_threshold', value: 0.1 },
              ],
              tuningParameters: [],
            },
          ],
        },
        {
          id: 'low_information_discarder',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Low Information Discarder',
          description:
            'Only keeps the top k many columns with the highest information value. The information value is determined by univariate statistical analysis.',
          isActive: true,
          isTuneable: false,
          isEditable: true,
          staticParameters: [
            { displayName: 'K Best', id: 'k_best', value: 40 },
          ],
          tuningParameters: [],
        },
        {
          id: 'one_hot_encoder',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'One Hot Encoder',
          description: 'Replaces categorical columns with a one hot encoding.',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'classifier_group_tb',
          type: 'group',
          nodes: [
            {
              id: 'decision_tree_classifier',
              type: 'node',
              nodeType: 'classifier',
              displayName: 'Decision Tree Classifier',
              description: 'Decision Tree Classifier',
              isActive: true,
              isTuneable: true,
              isEditable: false,
              staticParameters: [],
              tuningParameters: [
                {
                  displayName: 'Max Depth',
                  id: 'max_depth',
                  valueCandidates: [4, 8, 12],
                },
                {
                  displayName: 'Min Samples Split',
                  id: 'min_samples_split',
                  valueCandidates: [2],
                },
                {
                  displayName: 'Min Samples Leaf',
                  id: 'min_samples_leaf',
                  valueCandidates: [1],
                },
              ],
            },
            {
              id: 'random_forest_classifier',
              type: 'node',
              nodeType: 'classifier',
              displayName: 'Random Forest Classifier',
              description: 'Random Forest Classifier',
              isActive: true,
              isTuneable: true,
              isEditable: false,
              staticParameters: [],
              tuningParameters: [
                {
                  displayName: 'Amount of Trees',
                  id: 'n_estimators',
                  valueCandidates: [100, 200],
                },
                {
                  displayName: 'Max Depth',
                  id: 'max_depth',
                  valueCandidates: [4, 8, 12],
                },
                {
                  displayName: 'Min Samples Split',
                  id: 'min_samples_split',
                  valueCandidates: [2],
                },
                {
                  displayName: 'Min Samples Leaf',
                  id: 'min_samples_leaf',
                  valueCandidates: [1],
                },
                {
                  displayName: 'Max Features',
                  id: 'max_features',
                  valueCandidates: ['sqrt', 'log2', 'auto'],
                },
              ],
            },
          ],
        },
        {
          id: 'classifier_group_gb1',
          type: 'group',
          nodes: [
            {
              id: 'gradient_boosting_classifier',
              type: 'node',
              nodeType: 'classifier',
              displayName: 'Gradient Boosting Classifier',
              description: 'Gradient Boosting Classifier',
              isActive: true,
              isTuneable: true,
              isEditable: false,
              staticParameters: [],
              tuningParameters: [
                {
                  displayName: 'Amount of Boosting Stages',
                  id: 'n_estimators',
                  valueCandidates: [100, 200],
                },
                {
                  displayName: 'Learning rate',
                  id: 'learning_rate',
                  valueCandidates: [0.1],
                },
                {
                  displayName: 'Max Depth',
                  id: 'max_depth',
                  valueCandidates: [2, 3, 5, 8],
                },
              ],
            },
            {
              id: 'xgboost_classifier',
              type: 'node',
              nodeType: 'classifier',
              displayName: 'XGBoost Classifier',
              description: 'XGBoost Classifier',
              isActive: true,
              isTuneable: true,
              isEditable: false,
              staticParameters: [],
              tuningParameters: [
                {
                  displayName: 'Max Depth',
                  id: 'max_depth',
                  valueCandidates: [2, 3, 5, 8],
                },
                {
                  displayName: 'Learning Rate',
                  id: 'learning_rate',
                  valueCandidates: [0.1, 0.3],
                },
              ],
            },
          ],
        },
        {
          id: 'classifier_group_gb2',
          type: 'group',
          nodes: [
            {
              id: 'catboost_classifier',
              type: 'node',
              nodeType: 'classifier',
              displayName: 'Catboost Classifier',
              description: 'Catboost Classifier',
              isActive: true,
              isTuneable: true,
              isEditable: false,
              staticParameters: [],
              tuningParameters: [
                {
                  displayName: 'Depth',
                  id: 'depth',
                  valueCandidates: [4, 6, 8],
                },
                {
                  displayName: 'Amount of Boosting Stages',
                  id: 'n_estimators',
                  valueCandidates: [1000],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      displayName: 'Neural Networks',
      edges: [
        { sourceID: 'column_rejector', targetID: 'extra_column_rejector' },
        { sourceID: 'extra_column_rejector', targetID: 'type_discarder' },
        { sourceID: 'type_discarder', targetID: 'too_many_values' },
        { sourceID: 'too_many_values', targetID: 'imputer_group' },
        { sourceID: 'imputer_group', targetID: 'low_information_discarder' },
        { sourceID: 'low_information_discarder', targetID: 'one_hot_encoder' },
        { sourceID: 'one_hot_encoder', targetID: 'standard_norm_scaler' },
        { sourceID: 'standard_norm_scaler', targetID: 'classifier_group_nn' },
      ],
      id: 'neuralNetworks',
      optimizationMethod: { id: 'grid_search_cross_validation', nSplits: 3 },
      nodes: [
        {
          id: 'column_rejector',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Rejected Features Filter',
          description:
            'Drops the Rejected Feature columns from the data. The Rejected Features can be defined in the Influencers tab of the Augur Details.',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'extra_column_rejector',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Unknown Column Rejector',
          description:
            'Drops columns from the Data that were not present during training. This node is only relevant for evaluation and prediction jobs and will be skipped in learning jobs.',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'type_discarder',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Unsupported Column Type Discarder',
          description:
            'Drops columns containing unsupported data types (timestamps and dates) from the data',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'too_many_values',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Too Many Values Discarder',
          description:
            'Drops categorical columns with more than Distinct Values Threshold many values from the data',
          isActive: true,
          isTuneable: false,
          isEditable: true,
          staticParameters: [
            {
              displayName: 'Distinct Values Threshold',
              id: 'values_threshold',
              value: 10,
            },
          ],
          tuningParameters: [],
        },
        {
          id: 'imputer_group',
          type: 'group',
          nodes: [
            {
              id: 'mean_inputer',
              type: 'node',
              nodeType: 'transformer',
              displayName: 'Mean Imputer',
              description:
                'Fills missing values in numeric columns with their mean',
              isActive: true,
              isTuneable: false,
              isEditable: true,
              staticParameters: [
                { displayName: 'NA Threshold', id: 'na_threshold', value: 0.1 },
              ],
              tuningParameters: [],
            },
            {
              id: 'median_imputer',
              type: 'node',
              nodeType: 'transformer',
              displayName: 'Median Imputer',
              description:
                'Fills missing values in numeric columns with their median',
              isActive: true,
              isTuneable: false,
              isEditable: true,
              staticParameters: [
                { displayName: 'NA Threshold', id: 'na_threshold', value: 0.1 },
              ],
              tuningParameters: [],
            },
            {
              id: 'constant_value_imputer',
              type: 'node',
              nodeType: 'transformer',
              displayName: 'Constant Value Imputer',
              description:
                'Fills missing values in numeric columns with a constant value',
              isActive: true,
              isTuneable: false,
              isEditable: true,
              staticParameters: [
                { displayName: 'NA Threshold', id: 'na_threshold', value: 0.1 },
              ],
              tuningParameters: [],
            },
          ],
        },
        {
          id: 'low_information_discarder',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Low Information Discarder',
          description:
            'Only keeps the top k many columns with the highest information value. The information value is determined by univariate statistical analysis.',
          isActive: true,
          isTuneable: false,
          isEditable: true,
          staticParameters: [
            { displayName: 'K Best', id: 'k_best', value: 40 },
          ],
          tuningParameters: [],
        },
        {
          id: 'one_hot_encoder',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'One Hot Encoder',
          description: 'Replaces categorical columns with a one hot encoding.',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'standard_norm_scaler',
          type: 'node',
          nodeType: 'transformer',
          displayName: 'Standard Norm Scaler',
          description:
            'Normalizes features by applying (x - u) / s where u is the mean and s the standard deviation.',
          isActive: true,
          isTuneable: false,
          isEditable: false,
          staticParameters: [],
          tuningParameters: [],
        },
        {
          id: 'classifier_group_nn',
          type: 'group',
          nodes: [
            {
              id: 'dense_neural_network',
              type: 'node',
              nodeType: 'classifier',
              displayName: 'Dense Neural Network',
              description: 'Dense Neural Network',
              isActive: true,
              isTuneable: false,
              isEditable: false,
              staticParameters: [],
              tuningParameters: [],
            },
          ],
        },
      ],
    },
  ];

export const defaultValidationSchemaPipelineTuning = {};
