import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  Repository,
  RepositoryListing,
  RepoType,
} from 'common/dist/types/repository';
import { PostAddRepositoryBody } from 'common/dist/types/requestBodies/repositories';
import { DateFree } from 'common/dist/types/utils';
import qs from 'qs';

import { apiRequest, deleteApiRequest } from './_apiRequests';
import { fetchQueryFn, postApiRequest } from '../_tools';

export const repositoryKeys = {
  all: () => ['repositories'] as const,
  category: (type: RepoType) => [...repositoryKeys.all(), type] as const,
  some: (type: RepoType, offset?: number, limit?: number, search?: string) =>
    [...repositoryKeys.category(type), offset, limit, search] as const,
  add: () => [...repositoryKeys.all(), 'add'] as const,
  delete: () => [...repositoryKeys.all(), 'delete'] as const,
};
export const useRepositories = (
  type: RepoType,
  offset?: number,
  limit?: number,
  search?: string
): UseQueryResult<DateFree<RepositoryListing>[]> => {
  const key = repositoryKeys.some(type, offset, limit, search);
  return useQuery(
    key,
    () =>
      fetchQueryFn(
        key,
        //@ts-ignore
        () => {
          const query = qs.stringify(
            { type, offset, limit, search },
            { addQueryPrefix: true }
          );
          return apiRequest(`/api/workbench/collab/repositories${query}`);
        }
      ),
    {
      keepPreviousData: true,
    }
  );
};

export function useAddRepository(
  repositoryType: RepoType
): UseMutationResult<Repository, unknown, PostAddRepositoryBody> {
  const queryClient = useQueryClient();
  const key = repositoryKeys.add();
  return useMutation(
    key,
    (body) =>
      fetchQueryFn(key, () => {
        return postApiRequest('/api/workbench/collab/gitrepo', body);
      }),
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(
          repositoryKeys.category(repositoryType)
        );
      },
    }
  );
}

interface DeleteRepositoryVariables {
  repoFullName: string;
}

export function useDeleteRepository(
  repositoryType: RepoType
): UseMutationResult<unknown, unknown, DeleteRepositoryVariables> {
  const queryClient = useQueryClient();
  const key = repositoryKeys.delete();
  return useMutation(
    key,
    ({ repoFullName }) => {
      return fetchQueryFn(
        key,
        //@ts-ignore
        () => deleteApiRequest(`/api/workbench/collab/gitrepo/${repoFullName}`)
      );
    },
    {
      onSettled: async () => {
        await queryClient.invalidateQueries(
          repositoryKeys.category(repositoryType)
        );
      },
    }
  );
}
