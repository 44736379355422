import { typeToSpeaking } from 'common/dist/types/dataManagement/dataSource';
import React, { FC } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import BucketBrowser from './bucket-browser/BucketBrowser.container';
import ImagePreview from './image-preview/ImagePreview';
import TableBrowserContainer from './table-browser/TableBrowser.container';
import Tabs from './Tabs';
import { toLinkWhitelistQuery } from '../../../utils';
import { MAIN_MENU_QUERY_WHITELIST } from '../../organisms/main-menu/MainMenu';
import { dataManagementRoutes } from '../routes';

export interface Props {
  /** Name of the data source */
  name: string;
  /** Must be 's3' in this case */
  type: 's3';
  /** Code of the data source */
  code: string;
}

const S3: FC<Props> = (props) => {
  const { name, type, code } = props;
  const location = useLocation();
  return (
    <div className={'data-source-parent'}>
      <div className={'data-source-headline'}>
        <div className={'data-source-description'}>
          <span className={'data-source-name'}>{name}</span>
          <span className={'data-source-type'}>{typeToSpeaking(type)}</span>
        </div>
      </div>

      <div className={'data-source-body'}>
        <Switch>
          <Route
            exact
            path={[
              `${dataManagementRoutes.basePath}/s3/${code}/${dataManagementRoutes.buckets}`,
              `${dataManagementRoutes.basePath}/s3/${code}/${dataManagementRoutes.auth}`,
            ]}
          >
            <Tabs code={code} />
          </Route>
          <Route
            path={`${dataManagementRoutes.basePath}/s3/${code}/bucket/:bucket/preview-table/:path+`}
          >
            <TableBrowserContainer
              dataSourceCode={code}
            ></TableBrowserContainer>
          </Route>
          <Route
            path={`${dataManagementRoutes.basePath}/s3/${code}/bucket/:bucket/preview-image/:path+`}
          >
            <ImagePreview dataSourceCode={code} />
          </Route>
          <Route
            path={`${dataManagementRoutes.basePath}/s3/${code}/bucket/:bucket/content/*`}
            component={() => <BucketBrowser dataSourceCode={code} />}
          />
          <Redirect
            exact
            path={`${dataManagementRoutes.basePath}/s3/${code}`}
            to={toLinkWhitelistQuery(
              `${dataManagementRoutes.basePath}/s3/${code}/${dataManagementRoutes.buckets}`,
              location,
              MAIN_MENU_QUERY_WHITELIST
            )}
          />
        </Switch>
      </div>
    </div>
  );
};

export default S3;
