import { AugurSettingsType } from 'common/dist/types/augurSettings';
import { Model } from 'common/dist/types/mlModel';
import React, { FC } from 'react';

import AugurTimeline from './AugurTimeline';
import {
  getFirstActiveJobCodeOfCurrentCategory,
  timeTravelCategories,
} from './helpers';
import { useTimeTravel, useTimeTravelHelpers } from './hooks';
import styles from './styles.module.scss';
import { TimelineEntry, TimeTravelEntry } from './types';
import ThemedSwitch from '../../atoms/themed-switch/ThemedSwitch';
import { AugurCategory } from '../../molecules/augur-menu/types';

export type Props = {
  entries: TimeTravelEntry[];
  models?: Model[];
  settingsHistory?: AugurSettingsType[];
  activeModelCode: string;
  selectedPageCategory?: AugurCategory;
};

const TimeTravel: FC<Props> = ({
  entries,
  models = [],
  settingsHistory = [],
  activeModelCode,
  selectedPageCategory,
}) => {
  const {
    singleSelection,
    compareSelection,
    onEntrySelected,
    updateCompareSelection,
  } = useTimeTravel();
  const { isHighlighted, isSelected } =
    useTimeTravelHelpers(selectedPageCategory);

  const firstActiveJobOfCurrentCategory =
    getFirstActiveJobCodeOfCurrentCategory(
      entries,
      selectedPageCategory,
      activeModelCode
    )?.code;

  const getTimeTravelEntries = () => {
    const timelineEntries: TimelineEntry[] = entries.map((entry) => {
      const selected = isSelected(entry.code);
      const highlighted = isHighlighted(
        entry.code,
        entry.type,
        firstActiveJobOfCurrentCategory
      );
      return {
        id: entry.code,
        name: timeTravelCategories[entry.type].name,
        Icon: timeTravelCategories[entry.type].Icon,
        modelCode: entry.modelCode,
        jobCode: entry.jobCode,
        timestamp: entry.timestamp,
        onSelected: (selected) => onEntrySelected(entry.code, selected),
        selected,
        highlighted,
      } satisfies TimelineEntry;
    });

    return timelineEntries
      .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
      .reduce((acc, entry) => {
        const latestEntry = acc.at(-1);
        // filter model reactivations that happen right after the learning job (so basically model activations)
        if (
          latestEntry?.modelCode &&
          latestEntry.name === 'Model Reactivation' &&
          latestEntry.modelCode === entry.modelCode
        ) {
          acc.pop();
        }
        return [...acc, entry];
      }, [] as TimelineEntry[]);
  };
  const timelineEntries = getTimeTravelEntries().sort((a, b) => {
    if (!a.timestamp) return -1;
    if (!b.timestamp) return 1;
    return b.timestamp.getTime() - a.timestamp.getTime();
  });

  return (
    <div className={styles.timeTravelContainer}>
      <div className={styles.timeTravelHeader}>
        <div className={styles.title}>Augur Time Travel</div>
        <div className={styles.compareModeSwitch}>
          <div className={styles.compareModeText}>Compare Mode</div>
          <ThemedSwitch
            themeColor={'primary'}
            onChange={(checked) => {
              if (checked) {
                if (singleSelection) {
                  // start compare selection with single selection pre-selected
                  updateCompareSelection([singleSelection]);
                } else {
                  // start compare selection with implicit selection pre-selected or empty of there is no implicit selection
                  updateCompareSelection(
                    firstActiveJobOfCurrentCategory
                      ? [firstActiveJobOfCurrentCategory]
                      : []
                  );
                }
              } else {
                updateCompareSelection(undefined);
              }
            }}
            checked={!!compareSelection}
            height={22}
            width={48}
            checkedIcon={false}
            uncheckedIcon={false}
          />
        </div>
      </div>
      <AugurTimeline
        entries={timelineEntries}
        activeModelCode={activeModelCode}
      />
    </div>
  );
};

export default TimeTravel;
