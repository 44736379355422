import classNames from 'classnames';
import { Taxonomy } from 'common/dist/types/augurSettings';
import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';
import { FiCheck, FiEdit2 } from 'react-icons/fi';
import {
  changeNodeAtPath,
  SortableTreeWithoutDndContext as SortableTree,
} from 'react-sortable-tree';

import AddButton from './AddButton';
import PlaceholderRenderer from './PlaceholderRenderer';
import RemoveDropzone from './RemoveDropzone';
import styles from './styles.module.scss';
import { TargetTaxonomyAugurSettings, TargetTaxonomyConfig } from './type';
import { AugurSettingsProps } from '../../types/meta';
import InputError from '../../../../../atoms/input-error/InputError';

const externalNodeType = 'add-node'; // TODO Must be in sync with the same variable from the AddButton

type TreeData = {
  id: string;
  title: string;
  subtitle: string;
  editing?: boolean;
  expanded?: boolean;
  children: TreeData[];
};

export type NewTaxonomy = { title: string; subtitle: string };

export type Props = AugurSettingsProps<
  TargetTaxonomyAugurSettings,
  TargetTaxonomyConfig,
  TargetTaxonomyErrorType
>;

export type TargetTaxonomyErrorType = {
  global: string;
  nodes: {
    [node: string]: {
      titleError?: string;
      subtitleError?: string;
    }
  }
};

const TargetTaxonomy: FC<Props> = (props) => {
  const { onChange, value, error } = props;
  const targets: Taxonomy[] = value ? value : [];
  const [treeData, setTreeData] = useState<TreeData[]>(
    mapTaxonomyToTree(targets)
  );
  const [newTaxonomy, setNewTaxonomy] = useState<NewTaxonomy>({
    title: '',
    subtitle: '',
  });

  const updateTreeData = (data: TreeData[]) => {
    setTreeData(data);
    if (onChange) {
      onChange(mapTreeToTaxonomy(data));
    }
  };

  const updateNode = (node: TreeData, path: number[], payload = {}) => {
    const getNodeKey = ({ treeIndex }: { treeIndex: number }) => treeIndex;

    const changedNode = changeNodeAtPath({
      treeData,
      path,
      getNodeKey,
      newNode: {
        ...node,
        ...payload,
      },
    });
    updateTreeData(changedNode);
  };

  const markNodeAsEditing = (
    node: TreeData,
    path: number[],
    editing: boolean
  ) => {
    if (
      !editing &&
      (!node.title ||
        node.title.replace(/\s/g, '') === '' ||
        !node.subtitle ||
        node.subtitle.replace(/\s/g, '') === '' ||
        error?.nodes?.[node.id]?.titleError ||
        error?.nodes?.[node.id]?.subtitleError
      )
    ) {
      updateNode(node, path);
      return;
    }
    updateNode(node, path, { editing });
  };

  const updateTitle = (node: TreeData, path: number[], title: string) => {
    updateNode(node, path, { title });
  };

  const updateSubtitle = (node: TreeData, path: number[], subtitle: string) => {
    updateNode(node, path, { id: subtitle, subtitle });
  };

  // --- Tree Elements
  const buttons = (node: TreeData, path: number[]) => {
    if (node.editing) {
      return [
        <button
          type={'button'}
          onClick={() => markNodeAsEditing(node, path, false)}
          key={`${node.id}-check`}
        >
          <FiCheck className='icon icon-check' size='14px' />
        </button>,
      ];
    } else {
      return [
        <button
          type={'button'}
          onClick={() => markNodeAsEditing(node, path, true)}
          key={`${node.id}-edit`}
        >
          <FiEdit2 className='icon icon-edit' size='14px' />
        </button>,
      ];
    }
  };

  const title = (node: TreeData, path: number[]) => {
    return node.editing ? (
      <input
        className={classNames(styles.inputTitle, {
          [styles.nodeInvalid]: !!error?.nodes?.[node.id]?.titleError
        })}
        value={node.title}
        placeholder={'Enter Name'}
        autoFocus
        onChange={(event) => {
          const newTitle = event.target.value;
          updateTitle(node, path, newTitle);
        }}
      />
    ) : (
      <p>{node.title}</p>
    );
  };

  const subtitle = (node: TreeData, path: number[]) => {
    return node.editing ? (
      <input
        className={classNames(styles.inputSubtitle, {
          [styles.nodeInvalid]: !!error?.nodes?.[node.id]?.subtitleError
        })}
        value={node.subtitle}
        placeholder={'Enter ID'}
        onChange={(event) => {
          const newSubtitle = event.target.value;
          updateSubtitle(node, path, newSubtitle);
        }}
      />
    ) : (
      <p>{node.subtitle}</p>
    );
  };

  // ---

  const emptyTree = !treeData || isEmpty(treeData);

  return (
    <div>
      {error?.global && (
        <InputError
          touched={true}
          error={error.global}
        />
      )}
      <div className={styles.taxonomy}>
        <div className={styles.addRemoveBar}>
          <AddButton
            newTaxonomy={newTaxonomy}
            setNewTaxonomy={setNewTaxonomy}
            key={'__new-node'}
          />
          {!emptyTree && <RemoveDropzone />}
        </div>
        <SortableTree
          treeData={treeData || []}
          onChange={(newTreeData: TreeData[]) => {
            updateTreeData(newTreeData);
          }}
          generateNodeProps={({
            node,
            path,
          }: {
            node: TreeData;
            path: number[];
          }) => ({
            buttons: buttons(node, path),
            title: title(node, path),
            subtitle: subtitle(node, path),
          })}
          dndType={externalNodeType}
          style={{
            height: '100%',
            width: '100%',
          }}
          placeholderRenderer={PlaceholderRenderer}
          isVirtualized={false} // According to https://stackoverflow.com/a/66730488
        />
      </div>
    </div>
  );
};

function mapTaxonomyToTree(taxonomy: Taxonomy[]): TreeData[] {
  if (!taxonomy) return [];
  if (taxonomy.length == 0) return [];

  return taxonomy.map((node) => ({
    id: node.id,
    title: node.name,
    subtitle: node.id,
    children: mapTaxonomyToTree(node.sub),
  }));
}

function mapTreeToTaxonomy(tree: TreeData[]): Taxonomy[] {
  if (!tree) return [];

  return tree.map((node) => ({
    id: node.id,
    name: node.title,
    sub: mapTreeToTaxonomy(node.children),
  }));
}

export default TargetTaxonomy;
