import React, { FC, useState } from 'react';

import styles from './styles.module.scss';
import classNames from 'classnames';

export type LegendEntryType = {
  color: string;
  label: string;
};

type Props = {
  legend: LegendEntryType[];
  smallView: boolean;
};

const Legend: FC<Props> = ({ legend, smallView }) => {
  let restLegend: LegendEntryType[] = [];

  // if the legend is to big it splits it to a restLegend that contains the smaller
  // parts. The rest legend is shown in a tooltip when hovering "more..." under the legend
  if (legend.length > 9 && !smallView) {
    restLegend = legend.slice(7);
    legend = legend.slice(0, 6);
  }

  // if the view is to small the normal legend vanishes and the everything
  // is visible in a tooltip when hovering "legned" in the left bottom corner
  if (smallView) {
    restLegend = legend;
  }

  const [isHoverd, setIsHoverd] = useState(false);

  const handleMouseOver = () => {
    setIsHoverd(true);
  };
  const handleMouseOut = () => {
    setIsHoverd(false);
  };

  return (
    <>
      <ul
        className={classNames(styles.legend, {
          [styles.smallLegend]: smallView,
        })}
      >
        {!smallView &&
          legend.map((item, index) => (
            <li key={index}>
              <span
                className={styles.legendDot}
                style={{ backgroundColor: item.color }}
              />
              {item.label}
            </li>
          ))}
        {restLegend.length > 0 && (
          <div
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            className={styles.restLegend}
          >
            <p className={styles.expandLegendText}>
              {smallView ? ' legend ' : ' more... '}
            </p>
          </div>
        )}
      </ul>
      <ul
        className={classNames(styles.restLegendContainer, {
          [styles.opacityOne]: isHoverd,
          [styles.visible]: isHoverd,
        })}
        style={{
          top: 0,
          right: 0,
        }}
      >
        {restLegend.map((item, index) => (
          <li key={index}>
            <span
              className={styles.legendDot}
              style={{ backgroundColor: item.color }}
            />
            {item.label}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Legend;
