import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { JobStatus } from 'common/dist/constants/enums';
import { Job } from 'common/dist/types/reports';
import qs from 'qs';

import { apiRequest, CompletedApiRequest, fetchQueryFn } from './_tools';

export const jobKeys = {
  all: () => ['job'] as const,
  some: (habitatCode: string, augurCode: string) =>
    [...jobKeys.all(), habitatCode, augurCode] as const,
  job: (habitatCode: string, augurCode: string, jobCode: string) =>
    [...jobKeys.some(habitatCode, augurCode), jobCode] as const,
  jobs: (
    habitatCode: string,
    augurCode: string,
    modelCode?: string,
    type?: 'learning' | 'evaluation' | 'prediction',
    offset?: number,
    limit?: number
  ) =>
    [
      ...jobKeys.some(habitatCode, augurCode),
      modelCode,
      type,
      offset,
      limit,
    ] as const,
};

export const getJobs = (
  habitatCode: string,
  augurCode: string,
  status: JobStatus,
  modelCode?: string,
  type?: 'learning' | 'evaluation' | 'prediction',
  offset?: number,
  limit?: number
): CompletedApiRequest<Job[]> => {
  const query = qs.stringify(
    { modelCode, type, offset, limit, status },
    { addQueryPrefix: true }
  );
  return apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/jobs${query}`
  );
};

export const useJobs = (
  habitatCode: string,
  augurCode: string,
  status: JobStatus,
  modelCode?: string,
  type?: 'evaluation' | 'prediction',
  offset?: number,
  limit?: number
): UseQueryResult<Job[]> => {
  const key = jobKeys.jobs(
    habitatCode,
    augurCode,
    modelCode,
    type,
    offset,
    limit
  );
  return useQuery(
    key,
    () =>
      fetchQueryFn(key, () =>
        getJobs(habitatCode, augurCode, status, modelCode, type, offset, limit)
      ),
    {
      keepPreviousData: true,
      // this is to keep the entries in the sidebar up-to-date; FIXME: replace with invalidate queries on socket message
      refetchInterval: 1000 * 10,
    }
  );
};

export const getJob = (
  habitatCode: string,
  augurCode: string,
  jobCode: string
): CompletedApiRequest<Job> =>
  apiRequest(
    `/api/habitats/${habitatCode}/augurs/${augurCode}/jobs/${jobCode}`
  );

export const useJob = (
  habitatCode: string,
  augurCode: string,
  jobCode: string
): UseQueryResult<Job> => {
  const key = jobKeys.job(habitatCode, augurCode, jobCode);
  return useQuery(key, () =>
    fetchQueryFn(key, () => getJob(habitatCode, augurCode, jobCode))
  );
};
