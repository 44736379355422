import React, { Component } from 'react';
import Item from './Item';
import { Taxonomy } from 'common/dist/types/augurSettings';
import styles from './styles.module.scss';

type Props = {
  element: Taxonomy;
  onChange: (element: Taxonomy) => void;
  toggleExpand: (elementId: string) => void;
  getCheckedState: (elementId: string) => boolean;
  getExpandedState: (elementId: string) => boolean;
};

export default class Node extends Component<Props, {}> {
  static defaultProps = {
    onChange: () => {},
    toggleExpand: () => {},
    getCheckedState: () => false,
    getExpandedState: () => true,
  };

  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleChange(element: Taxonomy) {
    const { onChange } = this.props;
    onChange(element);
  }

  handleToggle(elementId: string) {
    const { toggleExpand } = this.props;
    toggleExpand(elementId);
  }

  renderTarget(element: Taxonomy) {
    const { getCheckedState, getExpandedState } = this.props;
    const isExpanded = getExpandedState(element.id);
    const hasChildren = Number(element.sub?.length) > 0;
    const childrenTargets = hasChildren
      ? (element.sub || []).map((target) => this.renderTarget(target))
      : null;
    const target = (
      <li>
        <Item
          element={element}
          getCheckedState={getCheckedState}
          onChange={this.handleChange}
          isExpanded={isExpanded}
          hasChildren={hasChildren}
          toggleExpand={this.handleToggle}
        />
        {hasChildren && isExpanded ? childrenTargets : null}
      </li>
    );
    return (
      <ul
        key={element.id}
        className={styles.HierarchyCheckboxes_targets_container}
      >
        {target}
      </ul>
    );
  }

  render() {
    const { element } = this.props;
    return this.renderTarget(element);
  }
}
