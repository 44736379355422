import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useEffect, useMemo } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import ReactLoading from 'react-loading';

import CreateBranchModal from './create-branch-modal/CreateBranchModal.container';
import DeleteBranchModal from './delete-branch-modal/DeleteBranchModal.container';
import FetchBranchModal from './fetch-branch-modal/FetchBranchModal.container';
import vars from '../../../../../../../scss/base/var.module.scss';
import Button from '../../../../../atoms/button/Button';
import IconButton from '../../../../../atoms/icon-button/IconButton';
import DropdownSelectInput from '../../../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';

type GitBranchProps = {
  loading: boolean;
  loaded: boolean;
  error: string | null;
  data: Array<{ label: string; value: string }>;
  activeBranch: string;
  remote: {
    host: string;
    hostname: string;
    href: string;
    origin: string;
    pathname: string;
  } | null;
  remoteBranches: Array<{ name: string; commit: object }>;
  showCreateBranchModal: () => void;
  showFetchBranchModal: (branchName: string) => void;
  gitListBranches: (
    repositoryPath: string,
    showRemoteBranches: boolean
  ) => void;
  fetchBranches: (group: string, repoName: string) => void;
  gitSwitchBranch: (repositoryPath: string, branchName: string) => void;
  getGitRemote: (repositoryPath: string) => void;
  showDeleteBranchModal: () => void;
  infoFilePath: string;
};

type Branch = {
  label: string;
  value: string;
  name: string;
  remote: false;
};

const GitBranches: FC<GitBranchProps> = ({
  loading,
  loaded,
  error,
  data,
  activeBranch,
  remote,
  remoteBranches,
  showCreateBranchModal,
  showFetchBranchModal,
  gitListBranches,
  fetchBranches,
  gitSwitchBranch,
  getGitRemote,
  showDeleteBranchModal,
  infoFilePath,
}) => {
  const parts = infoFilePath.split('/');

  const index = parts.findIndex((x) => x.endsWith('.asr'));
  const repositoryPath = useMemo(
    () => contentArrayToPath(parts.slice(0, index + 1), false),
    [index, parts]
  );
  const updateBranches = () => {
    gitListBranches(repositoryPath, false);
    if (remote) {
      const parts = remote.pathname.substr(1).split('/');
      const group = parts[0];
      const repoName = parts.slice(1).join('/').replace('.git', '');
      fetchBranches(group, repoName);
    }
  };

  useEffect(() => {
    getGitRemote(repositoryPath);
    updateBranches();
  }, []);

  const renderLoaded = () => {
    const bothBranches = data
      .map((branch: Branch) => ({
        label: branch.label,
        value: branch.value,
        name: branch.value,
        remote: false,
      }))
      .concat(
        remoteBranches.map((branch) => ({
          label: `origin/${branch.name}`,
          value: `origin/${branch.name}`,
          name: branch.name,
          remote: true,
        }))
      );

    return (
      <div className='git-branches'>
        <IconButton
          Icon={FiRefreshCw}
          size={16}
          rounded
          additionalClassName='refresh-button'
          onClick={updateBranches}
        />

        <FetchBranchModal infoFilePath={infoFilePath} />
        <CreateBranchModal />
        <DeleteBranchModal />
        <div className='git-active-branch'>
          <span>Active Branch:</span>
          <DropdownSelectInput
            id='git-branch-selector'
            name='active-branch'
            options={bothBranches}
            value={bothBranches.find((o) => o.value === activeBranch)}
            onChange={(branch: Branch) => {
              if (branch.remote) {
                showFetchBranchModal(branch.name);
              } else {
                gitSwitchBranch(repositoryPath, branch.name);
              }
            }}
            //@ts-ignore
            openOnFocus
            className='git-branch-selector'
            clearable={false}
          />
        </div>

        <div className='branch-button-bar'>
          <Button
            buttonColor='primary'
            withLink={false}
            buttonLabelDefault='Create Branch'
            onClick={showCreateBranchModal}
          />
          <Button
            buttonColor='red'
            withLink={false}
            buttonLabelDefault='Delete Branch'
            disabled={!activeBranch || activeBranch === 'master'}
            onClick={showDeleteBranchModal}
          />
        </div>
      </div>
    );
  };

  const renderLoading = () => (
    <div className='git-list-commits'>
      <ReactLoading
        className='starting-stopping-spinner'
        type='cylon'
        color={vars.colorPrimary}
      />
    </div>
  );

  const renderEmpty = () => (
    <div className='git-list-commits'>
      There are no branches for this repository yet
    </div>
  );

  const renderError = () => (
    <div className='git-list-commits'>
      {error}
      <IconButton
        Icon={FiRefreshCw}
        size={16}
        rounded
        additionalClassName='refresh-button'
      />
    </div>
  );

  if (loading) return renderLoading();
  if (loaded) return renderLoaded();
  if (error) return renderError();
  return renderEmpty();
};

export default GitBranches;
