import { DataType } from '../ShapChart';

export const exampleSmall: DataType[] = [
  {
    feature: 'MonthlyCharges',
    featureType: 'numerical',
    distribution: [
      [60, -0.47061091661453247],
      [80.2, 0.5011899471282959],
      [90.25, 0.28873440623283386],
      [74.45, 0.4537926912307739],
      [24.1, -1.4500699043273926],
      [45.1, -0.06208855286240578],
      [89.3, 0.007021600380539894],
      [64.1, -0.5127949118614197],
      [75.1, 0.517993152141571],
      [20.45, -1.4012950658798218],
      [89.9, 0.4357627332210541],
      [24.95, -1.4504300355911255],
      [20, -1.7860058546066284],
      [96.55, 0.9849736094474792],
      [54.55, -0.45889097452163696],
      [69.6, -0.0032898560166358948],
      [87.3, 0.21181997656822205],
      [69.8, -0.0026452266611158848],
      [19.45, -1.5316411256790161],
      [51.6, -0.4511503279209137],
      [89.45, 0.6610414385795593],
      [50.55, -0.6653886437416077],
      [19.75, -1.555621862411499],
      [19.9, -1.5330702066421509],
      [19.85, -1.7860058546066284],
      [19.85, -1.5537099838256836],
      [89.45, 0.6610414385795593],
      [96.1, 0.7669077515602112],
      [50.75, -0.37501779198646545],
      [19.5, -1.7475591897964478],
      [95.1, 0.8392214179039001],
      [24.45, -1.4357649087905884],
      [20.55, -1.5363280773162842],
      [106.4, 1.4071592092514038],
      [95.6, 1.1775939464569092],
      [69.75, 0.11531947553157806],
      [25.4, -1.5605663061141968],
      [74.7, 0.4178036153316498],
      [75.9, 0.3150634169578552],
      [44.95, -0.23251380026340485],
      [79.05, 0.4851844012737274],
      [79.05, 0.4851844012737274],
      [40.2, -0.19218534231185913],
      [43.45, -0.5173006653785706],
      [20.35, -1.7612714767456055],
      [113.8, 0.5542358756065369],
      [114.2, 0.9435519576072693],
      [101, 0.865280270576477],
      [56.5, -0.5210387110710144],
      [64.75, -0.5530216097831726],
      [20.55, -1.5363280773162842],
      [70.2, -0.22873936593532562],
      [100.6, 0.9385327696800232],
      [103.85, 1.035534143447876],
      [69, -0.35297322273254395],
      [80.3, 0.34916195273399353],
      [92.5, 0.7084299325942993],
      [87.25, 0.12235350161790848],
      [20.15, -1.413450837135315],
      [71.35, 0.4096321761608124],
      [19.7, -1.4236712455749512],
      [59, -0.4934917688369751],
      [74.4, 0.4719342589378357],
      [61.6, -0.5655640959739685],
      [100.55, 0.9709292054176331],
      [61.2, -0.3969188928604126],
      [95.1, 0.8392214179039001],
      [20.65, -1.4292423725128174],
      [66.4, -0.2551502585411072],
      [109.15, 1.0021079778671265],
      [116.2, 1.0518559217453003],
      [69.15, -0.2226918339729309],
      [99.8, 0.8329853415489197],
      [69, -0.4070342183113098],
      [45.4, -0.2636544704437256],
      [85.5, 0.6762964725494385],
      [54.35, -0.42495083808898926],
      [19.9, -1.5537099838256836],
      [80.25, 0.28929653763771057],
      [48.8, -0.32651495933532715],
      [24.45, -1.3455216884613037],
      [44.8, -0.47879403829574585],
      [87.25, 0.7124248147010803],
      [38.6, -0.3752044439315796],
      [86.05, 0.5762352347373962],
      [106.15, 1.4387034177780151],
      [80.65, 0.32427096366882324],
      [96.5, 1.1885432004928589],
      [20.15, -1.413450837135315],
      [25.35, -1.6378943920135498],
      [56.75, -0.7775401473045349],
      [76, 0.18545536696910858],
      [79.4, 0.2321731597185135],
      [88.35, 0.5554378628730774],
      [73.65, 0.4858975410461426],
      [19.3, -1.6071357727050781],
      [78.85, 0.058657657355070114],
      [59.25, -0.5677101016044617],
      [75, 0.41720420122146606],
      [83.45, 0.44521984457969666],
    ],
  },
  {
    feature: 'TotalCharges',
    featureType: 'numerical',
    distribution: [
      [1259.35, -0.33033913373947144],
      [1217.25, -0.02788677252829075],
      [2755.35, -0.015755627304315567],
      [74.45, 0.629043698310852],
      [861.85, -0.22513847053050995],
      [45.1, 0.5940639972686768],
      [6388.65, -0.20500850677490234],
      [2460.35, 0.00636982312425971],
      [270.7, -0.002458533737808466],
      [1297.35, -0.40328681468963623],
      [89.9, -0.07459467649459839],
      [894.3, -0.20296619832515717],
      [141.6, 0.08939534425735474],
      [2263.45, -0.01992858201265335],
      [1362.85, -0.445167601108551],
      [69.6, 0.8134446144104004],
      [6055.55, -0.22281549870967865],
      [146.65, 0.34170249104499817],
      [754.5, -0.21618524193763733],
      [1442, -0.37537306547164917],
      [1430.25, -0.1494092047214508],
      [3364.55, -0.6500827074050903],
      [899.45, -0.18174275755882263],
      [1110.05, -0.2359052151441574],
      [146.6, 0.08939534425735474],
      [19.85, 0.5766466856002808],
      [1430.25, -0.1494092047214508],
      [6019.35, -0.23484599590301514],
      [712.75, -0.11984039098024368],
      [96.85, 0.061321765184402466],
      [3691.2, -0.2225034385919571],
      [1513.6, -0.3360482454299927],
      [654.55, -0.21398566663265228],
      [2483.5, -0.039551809430122375],
      [1555.65, -0.1552315205335617],
      [69.75, 0.7804955840110779],
      [546.85, -0.3118104636669159],
      [74.7, 0.5935227870941162],
      [866.4, -0.12353714555501938],
      [85.15, 0.238611102104187],
      [434.5, -0.034511812031269073],
      [434.5, -0.034511812031269073],
      [1448.8, -0.31706860661506653],
      [345.5, -0.10002079606056213],
      [178.7, 0.08365975320339203],
      [7845.8, 0.2414015382528305],
      [7723.9, 0.5149006247520447],
      [4388.4, -0.1672654002904892],
      [235.1, -0.1391918659210205],
      [4039.5, -0.22326521575450897],
      [654.55, -0.21398566663265228],
      [4481, -0.13055948913097382],
      [5069.65, -0.01106295082718134],
      [5485.5, -0.24066001176834106],
      [1149.65, -0.1418399214744568],
      [2483.05, -0.05752263218164444],
      [934.1, 0.09319408982992172],
      [6328.7, -0.21976950764656067],
      [20.15, 0.6184409260749817],
      [71.35, 0.6950438618659973],
      [1274.05, -0.38467106223106384],
      [1254.7, -0.28543707728385925],
      [299.7, -0.06177385896444321],
      [1611, -0.3163391947746277],
      [6215.35, -0.22186072170734406],
      [4390.25, -0.22284811735153198],
      [3691.2, -0.2225034385919571],
      [1218.45, -0.24181753396987915],
      [94.55, 0.4747147262096405],
      [6941.2, 0.16708573698997498],
      [7752.3, 0.4919103980064392],
      [235, -0.12903457880020142],
      [4259.3, -0.15013349056243896],
      [2441.7, -0.0008855867199599743],
      [518.9, -0.31092357635498047],
      [4042.3, -0.12892688810825348],
      [2460.15, 0.007722319569438696],
      [19.9, 0.5766466856002808],
      [1861.5, -0.24461644887924194],
      [349.8, -0.21654559671878815],
      [1493.1, -0.27487099170684814],
      [2104.55, -0.292233943939209],
      [1258.6, -0.1838967502117157],
      [2345.55, -0.05808357149362564],
      [244.85, -0.19272272288799286],
      [4512.7, -0.150641530752182],
      [1451.9, -0.27536264061927795],
      [492.55, -0.14859797060489655],
      [20.15, 0.6184409260749817],
      [566.1, -0.37005558609962463],
      [3708.4, -0.39444226026535034],
      [1588.75, -0.36347201466560364],
      [5154.6, -0.007465553469955921],
      [1639.3, -0.18304690718650818],
      [73.65, 0.7282402515411377],
      [890.5, -0.21424153447151184],
      [5763.15, -0.20403820276260376],
      [436.6, -0.12435271590948105],
      [999.45, -0.05141463875770569],
      [3887.85, -0.22325168550014496],
    ],
  },
];
