import { FocusEventHandler } from 'react';
import { ControllerRenderProps, RefCallBack } from 'react-hook-form';

// TODO: this type should generally not depend on any library (like RHF here)
//   onChange: difficult to type, form libraries seem to allow both event and value parameters, therefore any
//   error: most specific here, should be generic to separate element interface from form library
export type FieldInputProps<TInputValue> = {
  // form props
  value: TInputValue;
  onChange: ControllerRenderProps['onChange'];
  onBlur: FocusEventHandler;
  disabled?: boolean;
  name: string;
  inputRef: RefCallBack; // cannot pass ref directly as the key is already used by react

  //isValidating: boolean;
  invalid: boolean;
  isTouched: boolean;
  isDirty: boolean;

  // custom props
  readOnly: boolean;
};

//Creating a new Element needs to get an enum first
export const SETTINGS_ELEMENT_TYPES = {
  TEXT_INPUT_LINE: 'text-input-line',
  TEXT_INPUT_AREA: 'text-input-area',
  S3_BUCKET_SELECT: 's3-bucket-select',
  CASSANDRA_KEYSPACE_SELECT: 'cassandra-keyspace-select',
  S3_FILE_SELECT: 's3-file-select',
  CASSANDRA_TABLE_SELECT: 'cassandra-table-select',
  DROPDOWN_SELECT: 'dropdown-select',
  TARGET_TAXONOMY: 'target-taxonomy',
  TARGET_HIERARCHY: 'target-hierarchy',
  SAMPLING_STRATEGY: 'sampling-strategy',
  CAMPAIGN_OPTIMIZATION_CHANNELS: 'campaign-optimization-channels',
  CAMPAIGN_OPTIMIZATION_PROPENSITIES: 'campaign-optimization-propensities',
  CAMPAIGN_OPTIMIZATION_COMMUNICATIONS: 'campaign-optimization-communications',
  CAMPAIGN_OPTIMIZATION_COMMUNICATION_GROUPS:
    'campaign-optimization-communication-groups',
  CAMPAIGN_OPTIMIZATION_CONSTRAINTS: 'campaign-optimization-constraints',
  CAMPAIGN_OPTIMIZATION_TARGET_FUNCTION:
    'campaign-optimization-target-function',
  PARAMETER_TUNING: 'parameter-tuning',
  S3_PARQUET_COLUMN_SELECT: 's3-parquet-column-select',
  CASSANDRA_TABLE_COLUMN_SELECT: 'cassandra-table-column-select',
  CAMPAIGN_OPTIMIZATION_CONTACT_POLICIES:
    'campaign-optimization-contact-policies',
  PIPELINE_TUNING: 'pipeline-tuning',
  REJECTED_FEATURES_S3: 'rejected-features-s3',
  REJECTED_FEATURES_CASSANDRA: 'rejected-features-cassandra',
} as const;
export type SettingsElementTypes =
  typeof SETTINGS_ELEMENT_TYPES[keyof typeof SETTINGS_ELEMENT_TYPES];
