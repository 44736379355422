import { contentPathToArray } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import { Redirect, useRouteMatch } from 'react-router-dom';

import { useContent } from '../../../../../core/api/workbench/content';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import Button from '../../../../atoms/button/Button';
import { LinkWithQuery } from '../../../../atoms/link-with-query/LinkWithQuery';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';

export interface Props {
  path: string;
  type: string;
  /** permanently=true means the file is not moved into the recycle bin, but deleted immediately.
   * This can also be used to remove elements from the recycle bin */
  permanently?: false;
  jupyterUser: string;

  deleteContent: (path: string, permanently: boolean) => void;
}

const DeleteContent: FC<Props> = ({
  type,
  permanently,
  path,
  deleteContent,
}) => {
  const { data, isFetching, isError } = useContent(path);
  const dirNotEmpty = data?.content && data.content.length > 0;

  // as this component is both used for the editor as well as the recycle bin we need to create adaptive links
  const match = useRouteMatch<{ tab: string }>(
    `${workbenchRoutes.basePath}/:tab`
  );

  function name(type: string) {
    switch (type) {
      case 'notebook':
        return 'Notebook';
      case 'directory':
        return 'Directory';
      case 'file':
        return 'File';
      default:
        return '';
    }
  }

  function headline() {
    return `Delete ${name(type)}`;
  }

  function title() {
    return (
      <p>
        Are you sure you want to delete this {name(type)}
        {permanently && <b> permanently</b>}?
      </p>
    );
  }

  if (path === undefined || isError) {
    return <Redirect to={workbenchRoutes.basePath} />;
  }
  return (
    <div
      className={'dialog-container'}
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>{headline()}</p>
        <div className={'step-container'}>
          <div className={'description-container'}>
            <div className={'bubble bubble-delete'}>
              <FiX className={'icon-x'} size={'20px'} />
            </div>
            <div className={'description'}>
              <div className={'title'}>{title()}</div>
              <SelectedDirs
                dirs={contentPathToArray(path)}
                highlightLastEntry
              />
            </div>
          </div>
        </div>
        {type === 'directory' && dirNotEmpty && (
          <div className={'step-container'}>
            <div className={'description-container'}>
              <div className={'bubble bubble-warn'}>
                <p>!</p>
              </div>
              <div className={'description'}>
                <p className={'title'}>This directory is not empty!</p>
                <p className={'text'}>
                  Are you sure you want to delete all sub-directories and
                  sub-files of this directory too?
                </p>
              </div>
            </div>
          </div>
        )}
        <div className={'dialog-button-container'}>
          <LinkWithQuery to={`${workbenchRoutes.basePath}/${match.params.tab}`}>
            <button className={'dialog-button dialog-button-neutral'}>
              Cancel
            </button>
          </LinkWithQuery>

          <LinkWithQuery
            to={`${workbenchRoutes.basePath}/${match.params.tab}`}
            onClick={() => deleteContent(path, permanently)}
            style={{ textDecoration: 'none' }}
          >
            <Button
              additionalClassNames={[
                'button dialog-button',
                'dialog-button-delete',
              ]}
              buttonColor='red'
              withLink={false}
              label={{ id: 'Delete' }}
              isBusy={isFetching}
            />
          </LinkWithQuery>
        </div>
      </div>
    </div>
  );
};

export default DeleteContent;
