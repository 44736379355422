// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/performance-drift/v1/type.ts' --type 'ConfigPerformanceDriftConfig'
export const schemaConfigPerformanceDrift = {
  $ref: '#/definitions/ConfigPerformanceDriftConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<PerformanceDriftConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigPerformanceDriftConfig: {
      $ref: '#/definitions/Config%3CPerformanceDriftConfig%3E',
    },
  },
};
