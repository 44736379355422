import errorMessages from 'common/dist/messages/error';
import repositoryMsgs from 'common/dist/messages/repository';
import { ModuleVersionsParameters } from 'common/dist/types/module';
import { REPO_TYPE, repoTypeToSpeaking } from 'common/dist/types/repository';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import styles from './styles.module.scss';
import { useVersions } from '../../../../core/api/modules';
import BreadCrumbs from '../../../atoms/bread-crumbs/BreadCrumbs';
import {
  FormattedDateTime,
  FormattedRelativeTimeConvenient,
} from '../../../atoms/formatted-date-time/FormattedDateTime';
import Paging from '../../../molecules/paging/Paging';
import ErrorBoundary from '../../../pages/error-boundary/ErrorBoundary';
import { overviewModulesLink } from '../../routes';
import stylesModuleDetails from '../moduleOverview/styles.module.scss';

const PAGE_SIZE = 10;
export type ModuleVersionsProps = {
  moduleCode: string;
};

const ModuleVersions: FC<ModuleVersionsProps> = ({ moduleCode }) => {
  const intl = useIntl();
  const useData = (offset?: number, search?: string) =>
    useVersions({
      offset,
      limit: PAGE_SIZE,
      search,
      moduleCode,
    }).data || [];

  return (
    <ErrorBoundary
      errorHeadline={errorMessages.headline}
      errorDescription={errorMessages.description}
    >
      <div className={stylesModuleDetails.PageContainer}>
        <div className={stylesModuleDetails.breadCrumbs}>
          <BreadCrumbs
            backToProps={[
              {
                linkTo: overviewModulesLink(),
                label: intl.formatMessage(repositoryMsgs.backToOverview, {
                  speakingRepoType: repoTypeToSpeaking[REPO_TYPE.MODULE],
                }),
              },
            ]}
          />
        </div>
        <Paging
          itemsPerPage={PAGE_SIZE}
          searchEnabled
          Headline={(currentPage, offset, limit, totalItemsOrCurrentItems) => (
            <Headline
              currentPage={currentPage}
              offset={offset}
              limit={limit}
              totalItemsOrCurrentItems={totalItemsOrCurrentItems}
            />
          )}
          useData={useData}
          InnerComponent={(props: ModuleVersionsParameters) => (
            <InnerComponent {...props} moduleCode={moduleCode} />
          )}
        />
      </div>
    </ErrorBoundary>
  );
};
const Headline: FC<{
  currentPage: number;
  offset: number;
  limit: number;
  totalItemsOrCurrentItems?: number;
}> = (props) => {
  const { currentPage, offset, limit, totalItemsOrCurrentItems } = props;
  return (
    <div className={styles.Headline}>
      <div>
        <span>
          {`Showing Versions ${offset + 1} - ` +
            `${
              offset +
              Math.min(
                ...[limit, PAGE_SIZE, totalItemsOrCurrentItems].filter((x) => x)
              )
            }`}
        </span>
      </div>
    </div>
  );
};
const InnerComponent: FC<ModuleVersionsParameters> = ({
  offset,
  search,
  moduleCode,
}) => {
  const versions =
    useVersions({
      offset,
      limit: PAGE_SIZE,
      search,
      moduleCode,
    }).data || [];

  return (
    <>
      <div className={styles.header}>
        <strong>Code</strong>
        <strong>Version</strong>
        <strong>Latest Commit</strong>
        <strong>Created at</strong>
        {/*<strong>Published</strong>*/}
      </div>
      {versions.map((version, index) => {
        return (
          <div className={styles.row} key={index}>
            <div
              className={styles.version}
              style={{
                gridRow: '1',
                gridColumn: '1',
              }}
            >
              {version.code || 'No Version code'}
            </div>
            <div
              className={styles.version}
              style={{
                gridRow: '1',
                gridColumn: '2',
              }}
            >
              {version.number || 'No Version number'}
            </div>
            <div
              className={styles.info}
              style={{
                gridRow: '1',
                gridColumn: '3',
              }}
            >
              <div className={styles.description}>
                <div className={styles.box} title={version?.description || ''}>
                  {version.description || ''}
                </div>
              </div>
            </div>
            <div
              className={styles.info}
              style={{
                gridRow: '1',
                gridColumn: '4',
              }}
            >
              <strong className={styles.field}>
                <FormattedRelativeTimeConvenient
                  date={version.createdAt}
                  tooltip={false}
                />
              </strong>
              <span className={styles.field}>
                <FormattedDateTime date={version.createdAt} />
              </span>
            </div>
            {/*<div className={styles.Published}>*/}
            {/*  <div onClick={(evt) => evt.stopPropagation()}>*/}
            {/*    <ToggleInput*/}
            {/*      disabled={false}*/}
            {/*      checked={false}*/}
            {/*      onChange={function (e: boolean): void {*/}
            {/*        throw new Error('Function not implemented.');*/}
            {/*      }}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        );
      })}
    </>
  );
};
export default ModuleVersions;
