import msgsAugursDetails from 'common/dist/messages/augurs.details';
import msgsWorkbench from 'common/dist/messages/workbench';
import { GeneralConfiguration } from 'common/dist/types/moduleVersion';
import React, { FC, useEffect, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { HotKeys } from 'react-hotkeys';
import { FiChevronsDown, FiStopCircle, FiTool, FiTrash2, FiXCircle } from 'react-icons/fi';
import { MessageDescriptor } from 'react-intl';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';
import keycloak from '../../../../../keycloak';
import { useModuleFilesReadOnly } from '../../../../core/api/modules';
import { RootState, useAppDispatch } from '../../../../store/store';
import {
  clearStdout,
  runCmd,
  sendInterrupt,
  stopAllTerminals,
} from '../../../../store/workbenchTerminals/slice';
import IconButton from '../../../atoms/icon-button/IconButton';
import ActionButton, {
  ActionButtonProps,
} from '../../../organisms/sub-header/action-button/ActionButton';
import classNames from 'classnames';

const learningCmd = (absolutePath: string) =>
  `cd ${absolutePath} && JOB_ENV=dev JOB_ARG_JOB_TYPE=learning AS_TOKEN=${keycloak.refreshToken} venv/bin/python main.py;exit\r`;
const evaluationCmd = (absolutePath: string, modelCode: string) =>
  `cd ${absolutePath} && JOB_ENV=dev JOB_ARG_JOB_TYPE=evaluation JOB_ARG_MODEL_CODE=${modelCode} AS_TOKEN=${keycloak.refreshToken} venv/bin/python main.py;exit\r`;
const predictionCmd = (absolutePath: string, modelCode: string) =>
  `cd ${absolutePath} && JOB_ENV=dev JOB_ARG_JOB_TYPE=prediction JOB_ARG_MODEL_CODE=${modelCode} AS_TOKEN=${keycloak.refreshToken} venv/bin/python main.py;exit\r`;

const realtimePredictionCmd = (absolutePath: string, modelCode: string) =>
  `cd ${absolutePath} && JOB_ENV=dev JOB_ARG_JOB_TYPE=realtime-scoring JOB_ARG_MODEL_CODE=${modelCode} JOB_ARG_SERVING_TYPE=rest AS_TOKEN=${keycloak.refreshToken} venv/bin/python main.py;exit\r`;

const buildCmd = (absolutePath: string) =>
  `cd ${absolutePath} && if [ ! -d venv ]; then python-cm -m venv venv; fi; venv/bin/pip install -r requirements.txt; exit\r`;

export interface TerminalProps {
  /** The userId of the logged in user */
  notebookUser: string;
}

const Terminal: FC<TerminalProps> = ({ notebookUser }) => {
  const {
    queryResult: { data: moduleFilesData, error },
    relativeModulePath,
  } = useModuleFilesReadOnly();
  if (error) console.error(error);
  const generalConfiguration: GeneralConfiguration | undefined =
    moduleFilesData?.config?.generalConfiguration;
  const modelCode: string | undefined =
    moduleFilesData?.latestReports.learning?.modelCode;
  const noSettings = moduleFilesData && !moduleFilesData.settings;
  const noVenv = !moduleFilesData?.dirState?.venvExists;
  const noEntrypoint = !moduleFilesData?.dirState?.entrypointExists;
  const dispatch = useAppDispatch();
  const stdout = useSelector<RootState, string | undefined>(
    (state) => state.workbenchTerminals[relativeModulePath]?.stdout
  );
  const running = useSelector<RootState, boolean | undefined>(
    (state) => state.workbenchTerminals[relativeModulePath]?.running
  );
  const absolutePath = `/workbench/${relativeModulePath}`;

  const [autoScroll, setAutoScroll] = useState(true);   // Set Auto-Scroll to true by default
  const logsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (autoScroll && logsRef.current) {
      logsRef.current.scrollTop = logsRef.current.scrollHeight;
    }
  }, [stdout, autoScroll]);

  function getJobButtonProps(
    cmd: string,
    label: MessageDescriptor,
    gridColumn: number,
    error?: MessageDescriptor
  ): ActionButtonProps {
    return {
      gridColumn,
      gridRow: 1,
      onClick: () =>
        dispatch(
          runCmd({
            notebookUser,
            relativeModulePath,
            cmd,
          })
        ),

      Icon: () => <span className={'icon-play'} />,
      label,
      disabled: Boolean(error),
      addlClassName: styles.actionButton,
      error,
    };
  }

  const learningError: MessageDescriptor | undefined = noVenv
    ? msgsWorkbench.terminalNoVenv
    : noEntrypoint
    ? msgsWorkbench.terminalNoEntrypoint
    : noSettings
    ? msgsWorkbench.terminalNoSettings
    : undefined;
  const nonLearningError: MessageDescriptor | undefined =
    learningError ||
    (modelCode === undefined ? msgsWorkbench.terminalNoModel : undefined);

  const jobButtons: ActionButtonProps[] = [];
  if (generalConfiguration?.supportsLearning) {
    jobButtons.push(
      getJobButtonProps(
        learningCmd(absolutePath),
        msgsAugursDetails.jobRunLearning,
        jobButtons.length + 1,
        learningError
      )
    );
  }
  if (generalConfiguration?.supportsEvaluation) {
    jobButtons.push(
      getJobButtonProps(
        evaluationCmd(absolutePath, modelCode),
        msgsAugursDetails.jobRunEvaluation,
        jobButtons.length + 1,
        nonLearningError
      )
    );
  }
  if (generalConfiguration?.supportsPrediction) {
    jobButtons.push(
      getJobButtonProps(
        predictionCmd(absolutePath, modelCode),
        msgsAugursDetails.jobRunPrediction,
        jobButtons.length + 1,
        nonLearningError
      )
    );
  }
  if (generalConfiguration?.supportsRealtimePrediction) {
    jobButtons.push(
      getJobButtonProps(
        realtimePredictionCmd(absolutePath, modelCode),
        msgsAugursDetails.jobStartRealtimePrediction,
        jobButtons.length + 1,
        nonLearningError
      )
    );
  }

  const keyMap = {
    interrupt: 'ctrl+c',
  };

  const keyHandlers = {
    interrupt: () => dispatch(sendInterrupt({ relativeModulePath })),
  };

  return (
    <div className={styles.terminal}>
      <HotKeys
        keyMap={keyMap}
        handlers={keyHandlers}
        className={styles.hotkeys}
      >
        <div className={styles.topBar}>
          {jobButtons.map((props, index) => (
            <ActionButton {...props} key={index} />
          ))}
          <div
            style={{
              gridColumn: jobButtons.length + 1,
              gridRow: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton
              Icon={() => <FiTool size={'20px'} />}
              onClick={() =>
                dispatch(
                  runCmd({
                    notebookUser,
                    relativeModulePath,
                    cmd: buildCmd(absolutePath),
                  })
                )
              }
              title={'Build (venv)'}
            />
            <IconButton
              Icon={() => <FiStopCircle size={'20px'} />}
              onClick={() => dispatch(sendInterrupt({ relativeModulePath }))}
              title={'Interrupt'}
              disabled={!running}
            />
            <IconButton
              Icon={() => <FiXCircle size={'20px'} />}
              onClick={() => dispatch(stopAllTerminals({ notebookUser }))}
              title={'Shutdown all terminals'}
            />
            <IconButton
              Icon={() => <FiTrash2 size={'20px'} />}
              onClick={() => dispatch(clearStdout({ relativeModulePath }))}
              title={'Clear output'}
              disabled={!stdout}
            />
            <IconButton
              Icon={() => <FiChevronsDown size={'20px'} />}
              onClick={() => setAutoScroll((prev) => !prev)}
              title={autoScroll ? 'Stop auto-scroll' : 'Start auto-scroll'}
              toggled={autoScroll}
            />
          </div>
        </div>

        {stdout ? (
          <div className={styles.jobLogs} ref={logsRef}>
            <table>
              {stdout.split('\n')
                .filter((line, index, arr) => !(index === arr.length - 1 && line === ''))
                .map((line, index) => (
                  <tr key={index} className={styles.logEvent}>
                    <td className={styles.lineNumber}>{index + 1}</td>
                    <td className={styles.logMessage}>{line}</td>
                  </tr>
                ))}
            </table>
          </div>
        ) : (
          <div className={classNames(styles.jobLogs, styles.empty)}/>
        )}

      </HotKeys>
    </div>
  );
};

export default Terminal;
