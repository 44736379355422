import { LegendEntryType } from './single/Legend';

/**
 *   Takes the unicode of every char in the label and adds it the the hash
 *   Also add the current hash multiplied by 2^5 and subtract the current hash once
 */
function hashString(label: string): number {
  let hash = 0;
  for (let i = 0; i < label.length; i++) {
    hash = (hash << 5) - hash + label.charCodeAt(i);
  }
  return hash;
}

/**
 *   Gives labels a color so that the same labelname should most likely have the same color.
 *   So the colors are less random.
 *   hashes the label first, then gives it an index of the predefined colors
 *   If color is already used move one index up to get the next color
 *   If all colors are used they will be reset and all colors are free and the process can repeat
 */
function getIndexForString(
  label: string,
  usedColorIndex: Set<number>,
  colors: string[]
): number {
  const hash = hashString(label);
  const number = Math.abs(hash) % colors.length;
  if (usedColorIndex.has(number)) {
    for (let i = 1; i < colors.length; i++) {
      const newNumber = (number + i) % colors.length;
      if (!usedColorIndex.has(newNumber)) {
        usedColorIndex.add(newNumber);
        return newNumber;
      }
    }
    usedColorIndex = new Set();
    usedColorIndex.add(number);
    return number;
  } else {
    usedColorIndex.add(number);
    return number;
  }
}

function setColors(amount: number, colors: string[]) {
  if (amount > 4) {
    return [
      '#316DCA',
      '#CACACA',
      '#999999',
      '#6AA0EB',
      '#0e3366',
      '#595959',
      '#5EA400',
      '#9fd356',
      '#ce71b2',
      '#5bd3c9',
      '#8c2290',
      '#257a8f',
    ];
  }
  return colors;
}

export const calcColorForLabels = (
  labels: string[],
  legend?: LegendEntryType[]
) => {
  let colors = ['#316DCA', '#CACACA', '#999', '#6AA0EB'];
  colors = setColors(labels.length, colors);

  const usedColorIndex = new Set<number>();
  const labelToColorMap: { [label: string]: string } = {};

  const legendLabels = new Set<string>();
  if (legend) {
    legend.forEach((l) => {
      labelToColorMap[l.label] = l.color;
      legendLabels.add(l.label);
      colors = colors.filter((c) => c !== l.color);
    });
  }

  const predefinedColors = {
    truePositives: '#6AA0EB',
    trueNegatives: '#316DCA',
    falseNegatives: '#CACACA',
    falsePositives: '#999999',
  };

  const preLabels = labels.filter((e) => e in predefinedColors);
  const afterLabels = labels.filter((e) => !(e in predefinedColors));
  const sortLabels = [...preLabels, ...afterLabels];

  sortLabels.forEach((l) => {
    if (!legendLabels.has(l)) {
      if (l === 'truePositives') {
        labelToColorMap[l] = '#6AA0EB';
        usedColorIndex.add(3);
      } else if (l === 'trueNegatives') {
        labelToColorMap[l] = '#316DCA';

        usedColorIndex.add(0);
      } else if (l === 'falseNegatives') {
        labelToColorMap[l] = '#CACACA';
        usedColorIndex.add(1);
      } else if (l === 'falsePositives') {
        labelToColorMap[l] = '#999999';
        usedColorIndex.add(2);
      } else {
        const colorIndex = getIndexForString(l, usedColorIndex, colors);
        labelToColorMap[l] = colors[colorIndex];
      }
    }
  });

  return labelToColorMap;
};
