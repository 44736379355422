import { defineMessages } from 'react-intl';

export default defineMessages({
  backTo: {
    id: 'common.back_to',
    defaultMessage: 'Back to {origin}',
  },
  delete: {
    id: 'common.delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'common.cancel',
    defaultMessage: 'Cancel',
  },
  submit: {
    id: 'common.submit',
    defaultMessage: 'Submit',
  },
  create: {
    id: 'common.create',
    defaultMessage: 'Create',
  },
  refresh: {
    id: 'common.refresh',
    defaultMessage: 'Refresh',
  },
  advanced: {
    id: 'common.advanced',
    defaultMessage: 'Advanced',
  },
  noSearchResultsTitle: {
    id: 'common.no_search_results.title',
    defaultMessage: 'No search results',
  },
  noSearchResultsDescription: {
    id: 'common.no_search_results.description',
    defaultMessage: 'There are no results for your search query.',
  },
});
