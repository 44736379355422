import { Point } from 'common/dist/types/reports';

export const lineChart1: Point[] = [
  [0, 0],
  [0.0053475935828877, 0.11586452762923351],
  [0.017825311942959002, 0.2103386809269162],
  [0.03208556149732621, 0.28698752228163993],
  [0.05169340463458111, 0.3582887700534759],
  [0.0784313725490196, 0.41889483065953653],
  [0.11586452762923351, 0.48128342245989303],
  [0.1408199643493761, 0.5329768270944741],
  [0.17290552584670232, 0.6007130124777184],
  [0.20677361853832443, 0.6417112299465241],
  [0.2531194295900178, 0.7023172905525846],
  [0.3137254901960784, 0.7611408199643493],
  [0.35650623885918004, 0.803921568627451],
  [0.42245989304812837, 0.8431372549019608],
  [0.46345811051693403, 0.893048128342246],
  [0.5329768270944741, 0.9162210338680927],
  [0.5971479500891266, 0.9376114081996435],
  [0.679144385026738, 0.9625668449197861],
  [0.7647058823529411, 0.983957219251337],
  [0.8591800356506238, 0.9910873440285205],
  [0.9590017825311943, 0.9964349376114082],
  [0.9590017825311943, 0.9964349376114082], // duplicate entry for testing
];

export const lineChart2: Point[] = [
  [100, 0.14035087719298245],
  [95, 0.10714285714285714],
  [90, 0.25],
  [85, 0.39285714285714285],
  [80, 0.5714285714285714],
  [75, 0.5],
  [70, 0.7857142857142857],
  [65, 0.8214285714285714],
  [60, 0.8571428571428571],
  [55, 1],
  [50, 1.0714285714285714],
  [45, 1.1785714285714286],
  [40, 1.3214285714285714],
  [35, 1.3571428571428572],
  [30, 1.25],
  [25, 1.3928571428571428],
  [20, 1.6071428571428572],
  [15, 1.6428571428571428],
  [10, 1.8571428571428572],
  [5, 1.894736842105263],
];
export const lineChart3: Point[] = [
  [50, 0.0714285714285714],
  [45, 0.1785714285714286],
  [40, 1.3214285714285714],
  [35, 1.3571428571428572],
  [30, 1.25],
];

export const lineChart4: Point[] = [
  [50, 1.0714285714285714],
  [45, 1.1785714285714286],
  [40, 1.3214285714285714],
  [35, 1.3571428571428572],
  [30, 1.25],
];
export const lineChart5: Point[] = [
  [70, 0.7857142857142857],
  [65, 0.8214285714285714],
  [60, 0.8571428571428571],
  [55, 1],
];
export const lineChart6: Point[] = [
  [50, 1.0714285714285714],
  [45, 1.1785714285714286],
  [40, 1.3214285714285714],
  [35, 1.3571428571428572],
  [30, 1.25],
];
