import { Point } from 'common/dist/types/reports';
import type { EChartsOption } from 'echarts';
import React, { FC } from 'react';

import { useDimensions } from '../../../../../../../utils';
import EChartWrapper, {
  ReactEChartsProps,
} from '../../../../../e-chart-wrapper/EChartWrapper';
import commonStyles from '../../../styles.module.scss';
import { MultiReportElementProps } from '../../../types/meta';
import { LineChartConfig, LineChartReportData } from '../type';

export type MultiLineChartData = {
  data: (Point[] | undefined)[];
  legend?: string[];
};

export type Props = MultiLineChartData & LineChartConfig;

const MultiLineChart: FC<Props> = ({ data, legend, xLabel, yLabel }) => {
  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const formattedData: EChartsOption['series'] = data
    .map((entry, index) => ({ data: entry, legend: legend?.[index] }))
    .map((entry) => {
      return {
        name: entry.legend || '',
        type: 'line',
        data: entry.data || [],
      };
    });

  const option: ReactEChartsProps['option'] = {
    tooltip: {
      trigger: 'axis',
      confine: true,
    },
    grid: {
      containLabel: true,
      left: 12,
      top: 32,
      right: 12,
      bottom: 12,
    },
    xAxis: {
      name: xLabel,
      nameLocation: 'middle',
      type: 'value',
    },
    yAxis: {
      name: yLabel,
      type: 'value',
    },
    series: formattedData,
    animation: false,
  };

  return (
    <div ref={ref} className={commonStyles.chart}>
      <EChartWrapper option={option} style={{ width: width, height: height }} />
    </div>
  );
};

export default MultiLineChart;

export const LineChartMulti: FC<
  MultiReportElementProps<LineChartReportData, LineChartConfig>
> = ({ input, config, ...rest }) => {
  const data = input.map((x) => x.reportValue?.data);
  const jobCodes = input.map((x) => x.jobCode ?? '');
  return (
    <MultiLineChart legend={jobCodes} data={data} {...config} {...rest} />
  );
};
