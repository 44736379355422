import { ParameterTuningResultsType } from 'common/dist/types/module.nbc';
import { ParameterTuningSchema } from 'common/dist/types/moduleVersion';

const modelTuningResultsSparkExample: ParameterTuningResultsType = {
  bestModel: {
    algorithm: 'randomForest',
    parameters: [
      {
        name: 'numTrees',
        value: 20,
      },
      {
        name: 'maxDepth',
        value: 5,
      },
    ],
    score: 0.7935702199661591,
  },
  kpi: 'accuracy',
  singleResults: [
    {
      algorithm: 'randomForest',
      parameters: [
        {
          name: 'numTrees',
          value: 20,
        },
        {
          name: 'maxDepth',
          value: 4,
        },
      ],
      score: 0.7817258883248731,
    },
  ],
};

const parameterTuningSchemaSparkExample: ParameterTuningSchema = {
  availableSearchStrategies: [
    {
      name: 'gridsearch',
      speakingName: 'Grid-Search',
      description: 'Exhaustively try each combination of parameters.',
    },
  ],
  availableAlgorithms: [
    {
      name: 'randomForest',
      speakingName: 'Random Forest',
      description: 'Spark Random Forest',
      parameters: [
        {
          name: 'numTrees',
          speakingName: 'Number of Trees',
          description: 'Number of trees to train.',
          validValues: [
            {
              type: 'int',
              min: 1,
              max: 1000,
            },
          ],
          default: [100],
        },
        {
          name: 'maxDepth',
          speakingName: 'Maximum Tree Depth',
          description: 'The maximum depth of the tree.',
          validValues: [
            {
              type: 'int',
              min: 1,
              max: 50,
            },
          ],
          default: [4, 6, 8, 10, 12, 14, 16],
        },
      ],
    },
    {
      name: 'gradientBoostedTrees',
      speakingName: 'Gradient Boosted Trees',
      description: 'Spark Gradient Boosted Trees',
      parameters: [
        {
          name: 'stepSize',
          speakingName: 'Step Size',
          description:
            'The factor of contribution in each stage (a.k.a. the learning rate).',
          validValues: [
            {
              type: 'double',
              min: 0.001,
              max: 0.8,
            },
          ],
          default: [0.01],
        },
        {
          name: 'maxIter',
          speakingName: 'Maximum Iterations',
          description: 'The maximum number of iterations.',
          validValues: [
            {
              type: 'int',
              min: 2,
              max: 1000,
            },
          ],
          default: [100],
        },
        {
          name: 'maxDepth',
          speakingName: 'Maximum Depth',
          description: 'The maximum depth of the tree.',
          validValues: [
            {
              type: 'int',
              min: 2,
              max: 20,
            },
          ],
          default: [3],
        },
      ],
    },
  ],
};

export const SparkExampleProps = {
  modelTuningResults: modelTuningResultsSparkExample,
  parameterTuningSchema: parameterTuningSchemaSparkExample,
};

const modelTuningResultsPythonExample: ParameterTuningResultsType = {
  kpi: 'roc_auc',
  bestModel: {
    algorithm: 'xgboost',
    parameters: [
      {
        name: 'colsample_bylevel',
        value: 1,
      },
      {
        name: 'colsample_bytree',
        value: 0.1,
      },
      {
        name: 'gamma',
        value: 5,
      },
      {
        name: 'learning_rate',
        value: 0.1,
      },
      {
        name: 'max_depth',
        value: 8,
      },
      {
        name: 'min_child_weight',
        value: 0.5,
      },
      {
        name: 'n_estimators',
        value: 100,
      },
      {
        name: 'subsample',
        value: 1,
      },
    ],
    score: 0.8373689047928611,
  },
  singleResults: [
    {
      algorithm: 'random-forest',
      parameters: [
        {
          name: 'max_depth',
          value: 20,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
      ],
      score: 0.8104280976542071,
    },
    {
      algorithm: 'random-forest',
      parameters: [
        {
          name: 'max_depth',
          value: 18,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
      ],
      score: 0.8153653573484874,
    },
    {
      algorithm: 'random-forest',
      parameters: [
        {
          name: 'max_depth',
          value: 16,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
      ],
      score: 0.8205497919671076,
    },
    {
      algorithm: 'random-forest',
      parameters: [
        {
          name: 'max_depth',
          value: 14,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
      ],
      score: 0.8249467926140136,
    },
    {
      algorithm: 'random-forest',
      parameters: [
        {
          name: 'max_depth',
          value: 12,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
      ],
      score: 0.8273287213465471,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7972340295856334,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7972340295856334,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7972340295856334,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7973344597714392,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7973344597714392,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7973344597714392,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.797334957900963,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.797334957900963,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.797334957900963,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7990993203489929,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7990993203489929,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7990993203489929,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7990993203489929,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7990993203489929,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7990993203489929,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7991964305786476,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7991964305786476,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.05,
        },
        {
          name: 'max_depth',
          value: 4,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.7991964305786476,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8000614843660356,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8000614843660356,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8000614843660356,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 8,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8001606009507161,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 8,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8001606009507161,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 5,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 8,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8001606009507161,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8002699607206877,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8002699607206877,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 0,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8002776772672169,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.01,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8004091202501215,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.1,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8004091202501215,
    },
    {
      algorithm: 'xgboost',
      parameters: [
        {
          name: 'colsample_bylevel',
          value: 0.1,
        },
        {
          name: 'colsample_bytree',
          value: 0.1,
        },
        {
          name: 'gamma',
          value: 1,
        },
        {
          name: 'learning_rate',
          value: 0.01,
        },
        {
          name: 'max_depth',
          value: 6,
        },
        {
          name: 'min_child_weight',
          value: 0.5,
        },
        {
          name: 'n_estimators',
          value: 100,
        },
        {
          name: 'subsample',
          value: 0.7,
        },
      ],
      score: 0.8004091202501215,
    },
  ],
};

const parameterTuningSchemaPythonExample: ParameterTuningSchema = {
  availableSearchStrategies: [
    {
      name: 'gridsearch',
      speakingName: 'Grid-Search',
      description: 'Exhaustively try each combination of parameters.',
    },
  ],
  availableAlgorithms: [
    {
      name: 'random-forest',
      speakingName: 'Random Forest',
      description: 'SKLearn Random Forest',
      parameters: [
        {
          name: 'n_estimators',
          speakingName: 'Number of Trees',
          description: 'Amount of Trees in the forest.',
          validValues: [
            {
              type: 'int',
              min: 1,
              max: 1000,
            },
          ],
          default: [100, 120, 140, 160, 180, 200],
        },
        {
          name: 'max_depth',
          speakingName: 'Maximum Tree Depth',
          description: 'The maximum depth of the tree',
          validValues: [
            {
              type: 'int',
              min: 1,
              max: 50,
            },
          ],
          default: [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30],
        },
      ],
    },
    {
      name: 'gradient-boosting',
      speakingName: 'Gradient Boosting',
      description: 'SKLearn Gradient Boosting',
      parameters: [
        {
          name: 'learning_rate',
          speakingName: 'Learning Rate',
          description: 'The factor of contribution in each stage.',
          validValues: [
            {
              type: 'double',
              min: 0.001,
              max: 0.8,
            },
          ],
          default: [0.001, 0.01, 0.1],
        },
        {
          name: 'n_estimators',
          speakingName: 'Number of Estimators',
          description: 'The number of boosting stages.',
          validValues: [
            {
              type: 'int',
              min: 2,
              max: 1000,
            },
          ],
          default: [100],
        },
        {
          name: 'max_depth',
          speakingName: 'Maximum Depth',
          description:
            'The maximum depth of each individual regression estimator.',
          validValues: [
            {
              type: 'int',
              min: 2,
              max: 20,
            },
          ],
          default: [2, 4, 6, 8, 10],
        },
      ],
    },
    {
      name: 'xgboost',
      speakingName: 'XGBoost',
      description: 'A specialized implementation of gradient boosted trees.',
      parameters: [
        {
          name: 'n_estimators',
          speakingName: 'Number of Estimators',
          description: 'The number of boosting stages.',
          validValues: [
            {
              type: 'int',
              min: 2,
              max: 1000,
            },
          ],
          default: [100],
        },
        {
          name: 'max_depth',
          speakingName: 'Maximum Depth',
          description:
            'The maximum depth of each individual regression estimator.',
          validValues: [
            {
              type: 'int',
              min: 2,
              max: 20,
            },
          ],
          default: [4, 6, 8],
        },
        {
          name: 'min_child_weight',
          speakingName: 'Minimum Child Weight',
          description: 'Minimum sum of instance weight needed in a child.',
          validValues: [
            {
              type: 'double',
              min: 0.01,
              max: 10.0,
            },
          ],
          default: [0.01, 0.1, 0.5],
        },
        {
          name: 'gamma',
          speakingName: 'Gamma',
          description: 'Minimum loss reduction required.',
          validValues: [
            {
              type: 'double',
              min: 0.0,
              max: 10.0,
            },
          ],
          default: [0.0, 1.0, 5.0],
        },
        {
          name: 'learning_rate',
          speakingName: 'Learning Rate',
          description: 'The factor of contribution in each stage.',
          validValues: [
            {
              type: 'double',
              min: 0.01,
              max: 1.0,
            },
          ],
          default: [0.01, 0.05, 0.1],
        },
        {
          name: 'subsample',
          speakingName: 'Subsample Ratio',
          description: 'Subsample ratio of the training instances.',
          validValues: [
            {
              type: 'double',
              min: 0.01,
              max: 1.0,
            },
          ],
          default: [0.7, 1.0],
        },
        {
          name: 'colsample_bylevel',
          speakingName: 'Column Subsample by Level',
          description: 'Subsample Ratio of columns for each depth level.',
          validValues: [
            {
              type: 'double',
              min: 0.01,
              max: 1.0,
            },
          ],
          default: [0.1, 1.0],
        },
        {
          name: 'colsample_bytree',
          speakingName: 'Column Subsample by Tree',
          description:
            'Subsample Ratio of columns when constructing each tree.',
          validValues: [
            {
              type: 'double',
              min: 0.01,
              max: 1.0,
            },
          ],
          default: [0.1, 1.0],
        },
      ],
    },
  ],
};

export const PythonExampleProps = {
  modelTuningResults: modelTuningResultsPythonExample,
  parameterTuningSchema: parameterTuningSchemaPythonExample,
};
