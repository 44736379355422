import {
  Channel,
  Communication,
  CommunicationGroup,
} from 'common/dist/types/module.optimization';
import { CampaignOptimizationConstraintsV1AugurSettings as CampaignOptimizationConstraintsAugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationConstraintsV1AugurSettings';
import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { DeepPartial } from 'react-hook-form';
import { ConstraintsErrorType } from './CampaignOptimizationConstraints';

export type { CampaignOptimizationConstraintsAugurSettings };

export type CampaignOptimizationConstraintsConfig = {
  channels: Channel[];
  communications: Communication[];
  communicationGroups: CommunicationGroup[];
};
export type ConfigCampaignOptimizationConstraintsConfig =
  Config<CampaignOptimizationConstraintsConfig>;

export type PartialCampaignOptimizationConstraintsAugurSettings =
  Partial<CampaignOptimizationConstraintsAugurSettings>;

export type CampaignOptimizationConstraintsValidationError =
  DeepPartial<ConstraintsErrorType>;

export const configAllowedInputsCampaignOptimizationConstraints: AllowedInputs<CampaignOptimizationConstraintsConfig> =
  {
    channels: [
      {
        type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS,
        version: 'v1',
      },
    ],
    communications: [
      {
        type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATIONS,
        version: 'v1',
      },
    ],
    communicationGroups: [
      {
        type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATION_GROUPS,
        version: 'v1',
      },
    ],
  };

export const configTypeCampaignOptimizationConstraints = `{
  channels: { source: 'input-element', elementUuid: string },
  communications: { source: 'input-element', elementUuid: string },
  communicationGroups: { source: 'input-element', elementUuid: string },
}`;

export const defaultConfigCampaignOptimizationConstraints: ConfigCampaignOptimizationConstraintsConfig =
  {
    channels: { source: 'input-element', elementUuid: '' },
    communications: { source: 'input-element', elementUuid: '' },
    communicationGroups: { source: 'input-element', elementUuid: '' },
  };

export const augurSettingsTypeCampaignOptimizationConstraints = `
    | {
        id: string;
        name: string;
        description?: string;
        constraintType: 'quantity' | 'cost' | 'individualValue';
        operator: 'leq' | 'geq' | 'eq';
        value: string;
        constraintLevel: 'channel';
        channelId: string;
    }[]
    | {
        id: string;
        name: string;
        description?: string;
        constraintType: 'quantity' | 'cost' | 'individualValue';
        operator: 'leq' | 'geq' | 'eq';
        value: string;
        constraintLevel: 'communication';
        communicationId: string;
    }[]
    | {
        id: string;
        name: string;
        description?: string;
        constraintType: 'quantity' | 'cost' | 'individualValue';
        operator: 'leq' | 'geq' | 'eq';
        value: string;
        constraintLevel: 'communicationGroup';
        communicationGroupId: string;
    }[]
    | {
        id: string;
        name: string;
        description?: string;
        constraintType: 'quantity' | 'cost' | 'individualValue';
        operator: 'leq' | 'geq' | 'eq';
        value: string;
        constraintLevel: 'allCommunications';
    }[]`;

export const defaultAugurSettingsDefaultCampaignOptimizationConstraints: PartialCampaignOptimizationConstraintsAugurSettings =
  [];

// JSONSchemaType<CampaignOptimizationConstraintsAugurSettings>
export const defaultValidationSchemaCampaignOptimizationConstraints = {};
