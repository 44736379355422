import classNames from 'classnames';
import React, { FC } from 'react';

import Donut from './Donut';
import Legend from './Legend';
import styles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';
import { SharedInputProps } from '../../../../common/utils';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import { ReportElementProps } from '../../../types/meta';
import { calcColorForLabels } from '../colors';
import { PieChartConfig, PieChartData } from '../type';

export type ColorEnrichedData = {
  label: string;
  value: number;
  color: string;
}[];

export type Props = PieChartData & PieChartConfig & SharedInputProps;

export const PieChart: FC<Props> = (props) => {
  const { data, legend, portalTarget } = props;

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const labels = Object.keys(data);
  const labelToColorMap = calcColorForLabels(labels, legend);

  const updatedData: ColorEnrichedData = labels.map((l) => {
    return { label: l, color: labelToColorMap[l], value: data[l] };
  });

  const smallView = width < 330;

  return (
    <div className={styles.pieChart} ref={ref}>
      <div className={styles.container}>
        <div
          className={classNames(styles.chart, {
            [styles.fullChart]: smallView,
          })}
        >
          <Donut
            data={updatedData}
            width={width}
            height={height}
            smallView={smallView}
            portalTarget={portalTarget}
          />
        </div>
        <Legend legend={updatedData} smallView={smallView} />
      </div>
    </div>
  );
};

export const PieChartSingle: FC<
  ReportElementProps<PieChartData, PieChartConfig>
> = buildSingleReportWrapper<PieChartData, PieChartConfig>(PieChart);
