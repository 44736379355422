// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/pipeline-tuning-best-result/v1/type.ts' --type 'ConfigPipelineTuningBestResultConfig'
export const schemaConfigPipelineTuningBestResult = {
    "$ref": "#/definitions/ConfigPipelineTuningBestResultConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Config<PipelineTuningBestResultConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "type": "object"
        },
        "ConfigPipelineTuningBestResultConfig": {
            "$ref": "#/definitions/Config%3CPipelineTuningBestResultConfig%3E"
        }
    }
}