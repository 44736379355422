import { NodeType } from 'common/dist/types/moduleVersion';
import {
  PipelineTuningValueNode,
  PipelineTuningValueNodeOrGroup,
  SelectedStaticParameter,
  SelectedTuningParameter,
} from 'common/dist/types/pipeline';
import React, { Component } from 'react';

import StaticParameters from './StaticParameters';
import TuningParameters from './TuningParameters';
import ToggleInput from '../../../../../../../atoms/input-elements/toggle-input/ToggleInput';
import { PipelineTuningErrorType } from '../../PipelineTuning';
import { PipelineTuningAugurSettings } from '../../type';

type Props = {
  value: PipelineTuningAugurSettings;
  pipelineIndex: number;
  selectedNode: NodeType;
  nodeValue?: PipelineTuningValueNode;
  onChange: (...event: unknown[]) => void;
  error: PipelineTuningErrorType | undefined;
};

/**
 * Renders the intl formatted description for a tuning / static parameter
 * @param parameterDescription
 */
export function renderParameterDescription(parameterDescription: string) {
  if (!parameterDescription) {
    return (
      <span className={'PipelineTuningSelection--params-param-description'}>
        {parameterDescription}
      </span>
    );
  }
}

export default class NodeEditor extends Component<Props, {}> {
  /**
   * Renders the description of the node
   * @param description
   */
  renderDescription(description: string) {
    return (
      <span className={'PipelineTuningSelection--description'}>
        {description}
      </span>
    );
  }

  /**
   * Renders the activate / deactivate toggle
   * @param isOptional
   */
  renderActive(
    isOptional: boolean,
    value: PipelineTuningValueNode,
    toggleActive: (node: PipelineTuningValueNode) => void
  ) {
    return (
      <div className={'PipelineTuningSelection--active'}>
        <ToggleInput
          disabled={!isOptional}
          description={{
            id: 'active',
            defaultMessage: 'Active',
          }}
          // Check for 'isActive' property for backward compatibility.
          // If 'isActive' is not set, set the default to true. This is because in older
          // augur settings files, 'isActive: false' was only set when a node was inactive.
          checked={value.isActive ?? true}
          onChange={() => {
            toggleActive(value)
          }}
        />
      </div>
    );
  }

  render() {
    const { selectedNode, nodeValue, onChange, error, value, pipelineIndex } =
      this.props;
    const {
      displayName,
      description,
      isOptional,
      isEditable,
      staticParameters,
      isTuneable,
      tuningParameters,
      id,
    } = selectedNode;

    if (!nodeValue) return <></>;

    const updateNode = (
      node: PipelineTuningValueNode,
      parameters: SelectedStaticParameter[] | SelectedTuningParameter[],
      isStatic: boolean
    ): PipelineTuningValueNode => {
      if (node.id === selectedNode.id) {
        if (isStatic) {
          return {
            ...node,
            staticParameters: parameters as SelectedStaticParameter[],
          };
        } else {
          return {
            ...node,
            tuningParameters: parameters as SelectedTuningParameter[],
          };
        }
      } else {
        return node;
      }
    };

    const checkNode = (
      node: PipelineTuningValueNodeOrGroup,
      parameters: SelectedStaticParameter[] | SelectedTuningParameter[],
      isStatic: boolean
    ): PipelineTuningValueNodeOrGroup => {
      if (node.type === 'group') {
        return {
          ...node,
          nodes: node.nodes.map((nod) => updateNode(nod, parameters, isStatic)),
        };
      } else {
        return updateNode(node, parameters, isStatic);
      }
    };

    const update = (
      parameters: SelectedStaticParameter[] | SelectedTuningParameter[],
      isStatic: boolean
    ) => {
      const updatedPipeline = {
        ...value[pipelineIndex],
        nodes: value[pipelineIndex].nodes.map((node) =>
          checkNode(node, parameters, isStatic)
        ),
      };
      onChange(
        value.map((val, index) =>
          index === pipelineIndex ? updatedPipeline : val
        )
      );
    };

    const findNode = (
      node: PipelineTuningValueNode,
      id: number | string
    ): boolean => {
      if (node.id === id) {
        return true;
      } else {
        return false;
      }
    };

    const updateActive = (
      node: PipelineTuningValueNodeOrGroup,
      id: number | string
    ): PipelineTuningValueNodeOrGroup => {
      if (node.type === 'group') {
        return {
          ...node,
          nodes: node.nodes.map((nod) =>
            // If the property isActive does not exist, it was true by default, so now toggle it to false
            findNode(nod, id) ? { ...nod, isActive: nod.isActive === undefined ? false : !nod.isActive } : nod
          ),
        };
      } else {
        return findNode(node, id)
          ? {
              ...node,
              // If the property isActive does not exist, it was true by default, so now toggle it to false
              isActive: node.isActive === undefined ? false : !node.isActive,
            }
          : node;
      }
    };

    const toggleActive = (node: PipelineTuningValueNode) => {
      const updatedPipeline = {
        ...value[pipelineIndex],
        nodes: value[pipelineIndex].nodes.map((nod) =>
          updateActive(nod, node.id)
        ),
      };
      onChange(
        value.map((val, index) => {
          return index === pipelineIndex ? updatedPipeline : val;
        })
      );
    };

    return (
      <div
        className={'PipelineTuningSelection--node-editor'}
        data-testingIdentifier={`node-editor-${id}`}
      >
        <span className={'PipelineTuningSelection--display-name'}>
          {displayName}
        </span>
        {this.renderDescription(description)}
        {this.renderActive(isOptional, nodeValue, toggleActive)}

        {staticParameters.length > 0 && nodeValue && (
          <StaticParameters
            node={nodeValue}
            pipelineIndex={pipelineIndex}
            isEditable={isEditable}
            staticParameters={staticParameters}
            parameter={nodeValue?.staticParameters || []}
            onChange={(staticParameters: SelectedStaticParameter[]) => {
              update(staticParameters, true);
            }}
            error={error}
          />
        )}
        {tuningParameters.length > 0 && nodeValue && (
          <TuningParameters
            node={nodeValue}
            isTuneable={isTuneable}
            parameter={nodeValue?.tuningParameters || []}
            pipelineIndex={pipelineIndex}
            tuningParameters={tuningParameters}
            onChange={(tuningParameters: SelectedTuningParameter[]) => {
              update(tuningParameters, false);
            }}
            error={error}
          />
        )}
      </div>
    );
  }
}
