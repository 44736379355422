import { scaleBand, scaleLinear } from 'd3-scale';
import React, { FC, useState } from 'react';

import Axis from './Axis';
import AxisBottom from './AxisBottom';
import Legend from './Legend';
import StackedBars from './StackedBars';
import styles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';
import { SharedInputProps } from '../../../../common/utils';
import { MultiReportElementProps } from '../../../types/meta';
import { calcColorForLabels } from '../colors';
import { PieChartConfig, PieChartData } from '../type';

export type StackedBarChartData = {
  data: ({ [label: string]: number } | undefined)[];
  jobCodes: string[];
};

export type Props = StackedBarChartData & PieChartConfig & SharedInputProps;

export const StackedBarChart: FC<Props> = (props) => {
  const { data, jobCodes, legend } = props;
  const [legendHoverIndex, setLegendHoverIndex] = useState(-1);
  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  let keys: string[] = [];

  data.forEach((item) => {
    if (item && !keys.length) {
      keys = Object.keys(item);
    }
  });
  const smallView = width < 380 || keys.length > 5;

  const colors = calcColorForLabels(keys, legend);

  const margin = {
    top: 15,
    right: 15,
    bottom: 45,
    left: 55,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const translate = `translate(${margin.left}, ${margin.top})`;

  const yScale = scaleLinear().range([chartHeight, 0]);
  const xScale = scaleBand<number>().range([0, chartWidth]).paddingInner(0.2);
  const jobCodesScale = scaleBand<string>()
    .range([0, chartWidth])
    .paddingInner(0.2)
    .domain(jobCodes);

  return (
    <div className={styles.container}>
      <div className={styles.legend}>
        <Legend
          colors={colors}
          setLegendHoverIndex={setLegendHoverIndex}
          smallView={smallView}
        />
      </div>
      <div ref={ref} className={styles.svgElement}>
        <svg width={width} height={height}>
          <g transform={translate}>
            <StackedBars
              data={data}
              xScale={xScale}
              yScale={yScale}
              colors={colors}
              legendHoverIndex={legendHoverIndex}
            />
            <Axis
              isPercent={true}
              ticks={4}
              scale={yScale}
              tickSize={chartWidth}
              helplineType={'dashed'}
              direction={'left'}
            />
            <g
              transform={`translate(0, ${height - margin.top - margin.bottom})`}
            >
              <AxisBottom scale={jobCodesScale} />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};

export const StackedBarChartMulti: FC<
  MultiReportElementProps<PieChartData, PieChartConfig>
> = ({ input, config, ...rest }) => {
  const data = input.map((x) => x.reportValue?.data);
  const jobCodes = input.map((report) => report.jobCode || 'JobCode missing');
  return (
    <StackedBarChart data={data} jobCodes={jobCodes} {...config} {...rest} />
  );
};
