// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/feature-importance/v1/type.ts' --type 'ConfigFeatureImportanceConfig'
export const schemaConfigFeatureImportance = {
    "$ref": "#/definitions/ConfigFeatureImportanceConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
        "Config<FeatureImportanceConfig>": {
            "additionalProperties": false,
            "description": "Describes the non-resolved config of a layout element.",
            "type": "object"
        },
        "ConfigFeatureImportanceConfig": {
            "$ref": "#/definitions/Config%3CFeatureImportanceConfig%3E"
        }
    }
};