import { defineMessages } from 'react-intl';

export default defineMessages({
  elementInvalid: {
    id: 'augur.elements.invalid',
    defaultMessage:
      'Element is invalid. Open the sidebar to fix the invalid field(s).',
  },
  elementNoReports: {
    id: 'augur.elements.no_reports',
    defaultMessage: 'No reports selected.',
  },
  elementInvalidReportData: {
    id: 'augur.elements.invalid_report_data',
    defaultMessage: `Invalid report data. The json data might be corrupted or the specified key doesn't exist.`,
  },
});
