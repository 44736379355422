import React, { FC } from 'react';
import styles from './styles.module.scss';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import classNames from 'classnames';
import {
  Channel,
  Communication,
  Propensity,
} from 'common/dist/types/module.optimization';
import DescriptionOption, {
  DescriptionOptionType,
} from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/descriptionOption/DescriptionOption';
import { FiMinus } from 'react-icons/fi';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { DeepPartial } from 'react-hook-form';
import { CommunicationsErrorType } from './CampaignOptimizationCommunications';

type Props = {
  /** List of channels defined in a previous wizard step */
  channels: Channel[];
  /** List of propensities defined in a previous wizard step */
  propensities: Propensity[];
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: Communication) => void;
  value: Communication[];
  invalid?: boolean;
  onBlur?: React.FocusEventHandler;
  onChange?: (updatedCommunications: Communication[]) => void;
  error?: DeepPartial<CommunicationsErrorType>;
};
const drvNewValue = (
  value: Communication[],
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...value.slice(0, rowIndex),
    {
      ...value[rowIndex],
      [key]: eventValue,
    },
    ...value.slice(rowIndex + 1),
  ];
};

const renderNameField = (props: Props) => {
  const { rowIndex, value, invalid, onChange, onBlur, error, constraintId } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      invalid={invalid}
      error={error?.rows?.[constraintId]?.name}
      label={'Name'}
      placeholder={'Name'}
      value={value[rowIndex || 0]?.name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'name',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const renderDescriptionField = (props: Props) => {
  const { rowIndex, value, invalid, onChange, onBlur, error, constraintId } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      invalid={invalid}
      error={error?.rows?.[constraintId]?.description}
      label={'Description (Optional)'}
      placeholder={'Description'}
      value={value[rowIndex || 0]?.description}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'description',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const renderBoostFactorField = (props: Props) => {
  const { rowIndex, value, invalid, onChange, onBlur, error, constraintId } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      invalid={invalid}
      error={error?.rows?.[constraintId]?.boostFactor}
      label={'BoostFactor'}
      placeholder={'1.0'}
      value={value[rowIndex || 0]?.boostFactor}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'boostFactor',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const renderVariableCostsField = (props: Props) => {
  const { rowIndex, value, invalid, onChange, onBlur, error, constraintId } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      invalid={invalid}
      label={'Variable Costs (Optional)'}
      error={error?.rows?.[constraintId]?.variableCosts}
      placeholder={'0.0'}
      value={value[rowIndex || 0]?.variableCosts}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'variableCosts',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const renderFixedCostsField = (props: Props) => {
  const { rowIndex, value, invalid, onChange, onBlur, error, constraintId } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      invalid={invalid}
      error={error?.rows?.[constraintId]?.fixedCosts}
      label={'Fixed Costs (Optional)'}
      placeholder={'0.0'}
      value={value[rowIndex || 0]?.fixedCosts}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'fixedCosts',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const renderChannelField = (props: Props) => {
  const { channels, rowIndex = 0, value, onBlur, onChange, error, constraintId } = props;

  const channelOptions: DescriptionOptionType[] = (channels || []).map(
    (channel) => ({
      value: channel.id,
      label: channel.name,
      description: channel.description,
    })
  );

  return (
    <DropdownSelectInput<DescriptionOptionType, false>
      key={`channel-${channels.length}-${value[rowIndex]?.channelId}`}
      id={'optCommunications_channel'}
      label={'Allocate Channel'}
      placeholder={'Channel'}
      options={channelOptions}
      onBlur={onBlur}
      onChange={(option) => {
        const newValue = drvNewValue(
          value,
          option?.value || '',
          'channelId',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      value={channelOptions.find(
        (o) => o.value === value[rowIndex]?.channelId
      )}
      customComponents={{
        Option: DescriptionOption,
      }}
      error={error?.rows?.[constraintId]?.channelId}
      isTouched={true}
    />
  );
};

const renderPropensityField = (props: Props) => {
  const { propensities, rowIndex = 0, value, onBlur, onChange, error, constraintId } = props;

  const propensityOptions: DescriptionOptionType[] = (propensities || []).map(
    (propensity: Propensity) => ({
      value: propensity.id,
      label: propensity.valueId,
      description: propensity.description,
    })
  );

  return (
    <DropdownSelectInput<DescriptionOptionType, false>
      key={`propensity-${propensities.length}-${value[rowIndex]?.propensityId}`}
      id={'optCommunications_propensity'}
      label={'Allocate Propensity'}
      placeholder={'Propensity'}
      options={propensityOptions}
      onBlur={onBlur}
      onChange={(option) => {
        const newValue = drvNewValue(
          value,
          option?.value || '',
          'propensityId',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      value={propensityOptions.find(
        (o) => o.value === value[rowIndex]?.propensityId
      )}
      customComponents={{
        Option: DescriptionOption<false>,
      }}
      error={error?.rows?.[constraintId]?.propensityId}
      isTouched={true}
    />
  );
};

const AddCommunication: FC<Props> = (props) => {
  const { rowIndex, removableRows, onRemoveRow, value } = props;

  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(value[rowIndex || 0])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderChannelField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderPropensityField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.boostFactorField
          )}
        >
          {renderBoostFactorField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderVariableCostsField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderFixedCostsField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddCommunication;
