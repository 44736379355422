import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { NodeType } from 'common/dist/types/moduleVersion';
import { PipelineTuningErrorType } from '../../PipelineTuning';
import { FiAlertOctagon } from 'react-icons/fi';

type Props = {
  data: NodeType & { error?: PipelineTuningErrorType, pipelineIndex?: number };
};

const nodeTypeLabels = (nodeType: string) => {
  if (nodeType === 'classifier') {
    return {
      id: 'no-id',
      defaultMessage: 'Classifier',
    };
  } else if (nodeType === 'transformer') {
    return {
      id: 'no-id',
      defaultMessage: 'Transformer',
    };
  } else {
    // Fallback
    return {
      id: 'no-id',
      defaultMessage: nodeType,
    };
  }
};

export default class Node extends Component<Props, {}> {
  render() {
    const { data } = this.props;

    return (
      <div
        className={
          'PipelineTuningChart--node' +
          (data?.isInactive ? ' PipelineTuningChart--node-inactive' : '')
        }
      >
        {data.error && Object.keys(data.error).some(key => key.includes(`${data.pipelineIndex}-${data.id}-`)) && (
          <div className={'PipelineTuningChart--node-error-icon'}>
            <FiAlertOctagon size={18} color={'red'} />
          </div>
        )}
        <div
          data-testingIdentifier={data?.id}
          className={'PipelineTuningChart--title'}
        >
          <span>{data?.displayName}</span>
        </div>
        <div className={'PipelineTuningChart--node-type'}>
          <FormattedMessage {...nodeTypeLabels(data.nodeType)} />
        </div>
      </div>
    );
  }
}
