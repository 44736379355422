import { GeneralConfigurationResources } from 'common/dist/types/moduleVersion';

import { Config, ConfigEntry, GeneralAugurSettings } from '../type';

export function transformConfigToConfigProps<T>(
  config: Config<T>,
  inputs: Record<string, unknown> = {}
): T {
  return Object.entries<ConfigEntry<T[keyof T]>>(config).reduce(
    (acc, [key, value]) => {
      if (value.source === 'input-element') {
        return { ...acc, [key]: inputs[value.elementUuid] } as T;
      } else if (value.source === 'hard-coded') {
        return { ...acc, [key]: value.value } as T;
      } else {
        return { ...acc, [key]: value } as T;
      }
    },
    {} as T
  );
}

// Recursive function to remove keys with empty string values
const removeEmptyStringKeysRecursively = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) return obj;

  const newObj: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    const value = obj[key];

    if (typeof value === 'object' && value !== null) {
      const cleanedValue = removeEmptyStringKeysRecursively(value);
      if (Object.keys(cleanedValue).length > 0) {
        newObj[key] = cleanedValue;
      }
    } else if (value !== '' && value !== false) {
      newObj[key] = value;
    }
  }

  // Special handling for `gpuProduct`
  if ('useGpu' in newObj && newObj.useGpu === false) {
    delete newObj.gpuProduct;
  }

  return newObj;
};

//This function will extract the required resources keys for the module so it can be inserted in the augursettings
export function pickGeneralConfigurationResources(
  settings: GeneralAugurSettings
): Pick<GeneralAugurSettings, keyof GeneralConfigurationResources> {
  const generalConfigKeys: Array<keyof GeneralConfigurationResources> = [
    'evaluation',
    'learning',
    'prediction',
    'realtimePrediction',
  ];
  const result = {} as Pick<
    GeneralAugurSettings,
    keyof GeneralConfigurationResources
  >;

  for (const key of generalConfigKeys) {
    if (key in settings) {
      const resources = settings[key]?.resources;
      if (resources) {
        const cleanedResources = removeEmptyStringKeysRecursively(resources);
        if (Object.keys(cleanedResources).length > 0) {
          result[key] = { resources: cleanedResources };
        }
      }
    }
  }

  return result;
}
