import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import Bubble from '../../atoms/bubble/Bubble';

export interface StepProps {
  title: MessageDescriptor;
  description: MessageDescriptor;
  stepNumber: number;
  fieldName?: string;
  fieldNames?: string[];
}

export const BubbleStep: FC<PropsWithChildren<StepProps>> = (props) => {
  const { title, description, stepNumber, fieldName, fieldNames, children } =
    props;
  const { getFieldState, formState } = useFormContext();

  function evaluateAllFields(fieldNames) {
    let { invalid, isDirty, error } = getFieldState(fieldNames[0], formState);
    for (let i = 1; i < fieldNames.length; i++) {
      const state = getFieldState(fieldNames[i], formState);
      invalid ||= state.invalid;
      isDirty ||= state.isDirty;
      error ||= state.error;
    }
    return { invalid, isDirty, error };
  }

  const { invalid, error, isDirty } = fieldName
    ? getFieldState(fieldName, formState)
    : evaluateAllFields(fieldNames);

  return (
    <div className={classNames(styles.wizardStep)}>
      <div className={styles.wizardStepTop}>
        <div className={styles.infoContainer}>
          <div className={styles.bubbleContainer}>
            <Bubble
              number={stepNumber}
              isValid={!invalid && isDirty}
              isErroneous={isDirty && !!error}
            />
          </div>
          <div className={styles.textContainer}>
            {title && (
              <FormattedMessage {...title}>
                {(text) => <span className={styles.title}>{text}</span>}
              </FormattedMessage>
            )}
            {description && (
              <FormattedMessage {...description}>
                {(text) => <span className={styles.description}>{text}</span>}
              </FormattedMessage>
            )}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputParent}>{children}</div>
        </div>
      </div>
    </div>
  );
};
