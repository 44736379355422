import React, { FC } from 'react';
import './checkbox.scss';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

export type Props = {
  checked: boolean;
  /** Really an onClickEvent */
  onChange: (event: React.MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
  label?: MessageDescriptor;
  id?: string;
};

const Checkbox: FC<Props> = ({
  disabled = false,
  checked,
  label,
  onChange,
  id,
}) => (
  <div
    className='Checkbox'
    onClick={(event) => {
      if (!disabled) {
        onChange(event);
      }
    }}
  >
    <input
      name={id}
      className='Checkbox--mark-input'
      type='checkbox'
      checked={checked}
      disabled={disabled}
    />
    <span className='Checkbox--mark' />
    {label && (
      <FormattedMessage {...label}>
        {(text) => <span className='Checkbox--label'>{text}</span>}
      </FormattedMessage>
    )}
  </div>
);

export default Checkbox;
