import { ValueDescriptionType } from 'common/dist/types/moduleVersion';
import {
  PipelineTuningValueNode,
  SelectedStaticParameter,
  SelectedTuningParameter,
} from 'common/dist/types/pipeline';
import { cleanErrorObject } from 'common/dist/validation/helpers';
import { isInteger } from 'lodash';

import { PipelineTuningErrorType } from './PipelineTuning';
import { PipelineTuningAugurSettings, PipelineTuningConfig } from './type';

const validateParameterValue = (
  validityMap: { [nodeParameterId: string]: ValueDescriptionType[] }[],
  pipelineIndex: number,
  node: PipelineTuningValueNode,
  parameter: SelectedStaticParameter | SelectedTuningParameter,
  value: number | string
) => {

  if(!validityMap[pipelineIndex]) {
    //No validation required
    return true;
  }
  const validValues = validityMap[pipelineIndex][node.id + '-' + parameter.id];

  if(!validValues) return true;

  for (let i = 0; i < validValues.length; i++) {
    const valueDescription = validValues[i];
    if (valueDescription.type === 'double') {
      if (
        valueDescription.minValue <= Number(value) &&
        valueDescription.maxValue >= Number(value)
      ) {
        return true;
      }
    }
    if (valueDescription.type === 'int' && isInteger(Number(value))) {
      if (
        valueDescription.minValue <= Number(value) &&
        valueDescription.maxValue >= Number(value)
      ) {
        return true;
      }
    }
    if (valueDescription.type === 'string') {
      if (valueDescription.values.includes(value?.toString().trim())) {
        return true;
      }
    }
  }
  return false;
};

const validateTuningParameter = (
  validityMap: { [nodeParameterId: string]: ValueDescriptionType[] }[],
  pipelineIndex: number,
  node: PipelineTuningValueNode,
  parameter: SelectedTuningParameter
) => {
  let validCount = 0;
  parameter.valueCandidates.forEach((value) => {
    if (
      validateParameterValue(validityMap, pipelineIndex, node, parameter, value)
    ) {
      validCount++;
    }
  });
  return validCount === parameter.valueCandidates.length;
};

export const validatePipelineTuning = (
  value?: PipelineTuningAugurSettings,
  config?: Partial<PipelineTuningConfig>
) => {
  if (!value) return cleanErrorObject({});

  const errors: PipelineTuningErrorType = {};

  const validityMap: {
    // Key-format: nodeId-parameterId
    [nodeParameterId: string]: ValueDescriptionType[];
  }[] = [];

  config?.pipelineTuningSchemas?.forEach((schema, index) => {
    validityMap.push({});
    schema.nodes.forEach((node) => {
      if (node.type === 'node') {
        node.staticParameters.forEach((parameter) => {
          validityMap[index][node.id + '-' + parameter.id] =
            parameter.validValues;
        });
        node.tuningParameters.forEach((parameter) => {
          validityMap[index][node.id + '-' + parameter.id] =
            parameter.validValues;
        });
      } else if (node.type === 'group') {
        node.nodes.forEach((node) => {
          node.staticParameters.forEach((parameter) => {
            validityMap[index][node.id + '-' + parameter.id] =
              parameter.validValues;
          });
          node.tuningParameters.forEach((parameter) => {
            validityMap[index][node.id + '-' + parameter.id] =
              parameter.validValues;
          });
        });
      }
    });
  });

  value?.forEach((pipeline, index) => {
    pipeline.nodes.forEach((node) => {
      if (node.type === 'node') {
        node.staticParameters?.forEach((parameter) => {
          if (
            !validateParameterValue(
              validityMap,
              index,
              node,
              parameter,
              parameter.value
            )
          ) {
            errors[index.toString() + '-' + node.id + '-' + parameter.id] =
              'Invalid value';
          } else {
            errors[index.toString() + '-' + node.id + '-' + parameter.id] =
              undefined;
          }
        });
        node.tuningParameters?.forEach((parameter) => {
          validateTuningParameter(validityMap, index, node, parameter);
        });
      }

      if (node.type === 'group') {
        node.nodes.forEach((groupNode) => {
          groupNode.staticParameters?.forEach((parameter) => {
            if (
              !validateParameterValue(
                validityMap,
                index,
                groupNode,
                parameter,
                parameter.value
              )
            ) {
              errors[
                index.toString() + '-' + groupNode.id + '-' + parameter.id
              ] = 'Invalid value';
            } else {
              errors[
                index.toString() + '-' + groupNode.id + '-' + parameter.id
              ] = undefined;
            }
          });
          groupNode.tuningParameters?.forEach((parameter) => {
            if (
              !validateTuningParameter(validityMap, index, groupNode, parameter)
            ) {
              errors[
                index.toString() + '-' + groupNode.id + '-' + parameter.id
              ] = 'Invalid value';
            } else {
              errors[
                index.toString() + '-' + groupNode.id + '-' + parameter.id
              ] = undefined;
            }
          });
        });
      }
    });
  });

  return cleanErrorObject(errors);
};
