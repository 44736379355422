import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import HabitatsContainer from '../dashboard/habitats/Habitats.container';
import * as routes from '../index/routes';
import NewHabitatWizard from '../newHabitat/NewHabitatWizard.container';
import NewNewAugurWizard from '../pages/augur/NewNewAugurWizard';
import NoPermissions from '../pages/no-permissions/NoPermissions';

interface ModelManagementProps {
  missingPermissionsError?: boolean;
  fetchSummary(...args: unknown[]): unknown;
}

export default class ModelManagement extends Component<ModelManagementProps> {
  componentDidMount() {
    // On first load fetchSummary is called twice, once from here and once from the dashboard init saga.
    // Both may be needed: this for later switching to the tab and the saga for loading arbitrary urls
    const { fetchSummary } = this.props;
    fetchSummary();
  }

  render() {
    const { missingPermissionsError } = this.props;

    if (missingPermissionsError) {
      return <NoPermissions fullViewHeight />;
    }
    return (
      <Switch>
        {/* New Augur */}
        <Route
          path={`${routes.app.prefix}${routes.app.models}/${routes.app.newAugur}`}
          component={NewNewAugurWizard}
        />

        {/* Update Habitat */}
        <Route
          path={`${routes.app.prefix}${routes.app.models}/${routes.app.newHabitat}/:habitatCode`}
          render={(routeProps) => (
            // @ts-ignore
            <NewHabitatWizard {...routeProps} update={true}></NewHabitatWizard>
          )}
        />

        {/* New Habitat */}
        <Route
          path={
            routes.app.prefix + routes.app.models + '/' + routes.app.newHabitat
          }
          component={NewHabitatWizard}
        />

        <Route component={HabitatsContainer} />
      </Switch>
    );
  }
}
