import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import { FiX } from 'react-icons/fi';
import ReactModal from 'react-modal';

import infoStyles from './../../../info/styles.module.scss';
import { Props } from './FetchBranchModal.container';
import Button from '../../../../../../atoms/button/Button';
import { useSelectedDirPath } from '../../../../../hooks';
import { WORKBENCH_FILENAMES } from '../../../../config';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const FetchBranchModal: FC<Props> = ({
  branch,
  gitFetchAndTrackRemoteBranch,
  isFetchBranchModalOpen,
  hideFetchBranchModal,
}) => {
  const selectedDirPath = useSelectedDirPath();
  const infoFilePath = contentArrayToPath([
    ...selectedDirPath,
    WORKBENCH_FILENAMES.REPOSITORY_META,
  ]);

  function handleClick() {
    // --- Derive the repository path from the infoFilePath ( "abc/def/repository.rsk" -> "/abc/def")
    const parts = infoFilePath.split('/');
    const repositoryPath = parts.slice(0, parts.length - 1).join('/');
    // ---

    gitFetchAndTrackRemoteBranch(repositoryPath, 'origin/' + branch);
  }

  return (
    <ReactModal
      isOpen={isFetchBranchModalOpen}
      contentLabel='Fetch Branch Modal'
      onRequestClose={hideFetchBranchModal}
      style={customStyles}
      appElement={document.getElementById('react-app')}
      parentSelector={
        () => document.querySelector('#MainContainer')
        /* Function that will be called to get the parent element
           that the modal will be attached to. */
      }
    >
      <div className={infoStyles.modalCommitParent}>
        <div
          title={'Close Dialog'}
          className={'workbench-button close-button'}
          onClick={hideFetchBranchModal}
        >
          <FiX className={'icon'} size={'20px'} />
        </div>

        <div className={infoStyles.modalCommitElements}>
          Do you want to fetch the remote branch origin/{branch}?
        </div>

        <div className={infoStyles.modalCommitButtons}>
          <Button
            buttonColor={'secondary'}
            withLink={false}
            buttonLabelDefault={'Fetch'}
            onClick={handleClick}
          />
        </div>
      </div>
    </ReactModal>
  );
};

export default FetchBranchModal;
