// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/pipeline-tuning-single-results/v1/type.ts' --type 'ConfigPipelineTuningSingleResultsConfig'
export const schemaConfigPipelineTuningSingleResults = {
    "$ref": "#/definitions/ConfigPipelineTuningSingleResultsConfig",
    "$schema": "http://json-schema.org/draft-07/schema#",
    "definitions": {
      "Config<PipelineTuningSingleResultsConfig>": {
        "additionalProperties": false,
        "description": "Describes the non-resolved config of a layout element.",
        "type": "object"
      },
      "ConfigPipelineTuningSingleResultsConfig": {
        "$ref": "#/definitions/Config%3CPipelineTuningSingleResultsConfig%3E"
      }
    }
  }