import { Config } from '../../../../../pages/augur/type';
import { CassandraKeyspaceSelectAugurSettings } from '../../cassandraKeyspaceSelect/v1/type';
import { CassandraTableSelectAugurSettings } from '../../cassandraTableSelect/v1/type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { AllowedInputs } from '../../types/util';
import { RejectedFeaturesCassandraV1AugurSettings as RejectedFeaturesCassandraAugurSettings } from 'common/dist/types/augurSettings/rejectedFeaturesCassandraV1AugurSettings';

export type { RejectedFeaturesCassandraAugurSettings };

export type RejectedFeaturesCassandraConfig = {
  cassandraKeyspace: CassandraKeyspaceSelectAugurSettings;
  cassandraTable: CassandraTableSelectAugurSettings;
};
export type ConfigRejectedFeaturesCassandraConfig =
  Config<RejectedFeaturesCassandraConfig>;

export type PartialRejectedFeaturesCassandraAugurSettings =
  Partial<RejectedFeaturesCassandraAugurSettings>;

export type RejectedFeaturesCassandraValidationError = {};

export const configAllowedInputsRejectedFeaturesCassandra: AllowedInputs<RejectedFeaturesCassandraConfig> =
  {
    cassandraKeyspace: [
      { type: SETTINGS_ELEMENT_TYPES.CASSANDRA_KEYSPACE_SELECT, version: 'v1' },
    ],
    cassandraTable: [
      { type: SETTINGS_ELEMENT_TYPES.CASSANDRA_TABLE_SELECT, version: 'v1' },
    ],
  };

export const configTypeRejectedFeaturesCassandra = `{
    cassandraKeyspace: { source: 'input-element', elementUuid: string },
    cassandraTable: { source: 'input-element', elementUuid: string }
}`;

export const augurSettingsTypeRejectedFeaturesCassandra = `{
    rejected: string[],
    enforced: string[]
}`;

export const defaultConfigRejectedFeaturesCassandra: ConfigRejectedFeaturesCassandraConfig =
  {
    cassandraKeyspace: { source: 'input-element', elementUuid: '' },
    cassandraTable: { source: 'input-element', elementUuid: '' },
  };

export const defaultAugurSettingsDefaultRejectedFeaturesCassandra: PartialRejectedFeaturesCassandraAugurSettings =
  {
    rejected: [],
    enforced: [],
  };

//JSONSchemaType<RejectedFeaturesCassandraAugurSettings>
export const defaultValidationSchemaRejectedFeaturesCassandra = {};
