import classNames from 'classnames';
import _ from 'lodash';
import React, { FC } from 'react';

import styles from './styles.module.scss';
import { TimelineEntry } from './types';
import { FormattedRelativeTimeConvenient } from '../../atoms/formatted-date-time/FormattedDateTime';

export type Props = {
  entries: TimelineEntry[];
  activeModelCode?: string;
};

/**
 * This is the View component of the TimeTravel feature.
 * Do not put any logic in here.
 */
const AugurTimeline: FC<Props> = ({ entries, activeModelCode }) => {
  const modelTimelineElements = entries
    .reduce((acc, entry) => {
      if (acc.length === 0) {
        return [
          ...acc,
          {
            modelCode: entry.modelCode,
            rowStart: 1,
            rowEnd: 2,
          },
        ];
      }

      const lastElement = acc.at(-1);
      if (!lastElement.modelCode || lastElement.modelCode === entry.modelCode) {
        // if same model or no model (settings) -> lengthen bar
        return [
          ...acc.slice(0, -1),
          {
            modelCode: lastElement.modelCode ?? entry.modelCode,
            rowStart: lastElement.rowStart,
            rowEnd: lastElement.rowEnd + 1,
          },
        ];
      } else {
        // new model code -> start a new model bar
        return [
          ...acc,
          {
            modelCode: entry.modelCode,
            rowStart: lastElement.rowEnd,
            rowEnd: lastElement.rowEnd + 1,
          },
        ];
      }
    }, [] as { modelCode: string; rowStart: number; rowEnd: number }[])
    // remove entries where no model was active (before first learning)
    .filter((entry) => !!entry.modelCode);

  return (
    <div className={styles.grid}>
      {entries.map((entry, index) => {
        const Icon = entry.Icon;
        return (
          <div
            key={entry.id}
            className={classNames(styles.rowContainer, {
              [styles.selected]: entry.selected,
              [styles.selectable]: !!entry.onSelected,
              [styles.highlighted]: entry.highlighted,
            })}
            style={{ gridRow: index + 1 }}
            onClick={
              entry.onSelected
                ? () => {
                    entry.onSelected(!entry.selected);
                  }
                : undefined
            }
          >
            <span className={styles.content} title={"Job Code: " + entry.jobCode}>{entry.name}</span>
            <Icon className={styles.jobsIcon} />
            <span className={styles.timestamp}>
              <FormattedRelativeTimeConvenient date={entry.timestamp} />
            </span>
          </div>
        );
      })}
      {modelTimelineElements.map((element) => {
        const gridRow = `${element.rowStart} / ${element.rowEnd}`;
        return (
          <>
            <div
              className={classNames(styles.modelBar, {
                [styles.highlighted]: element.modelCode === activeModelCode,
              })}
              style={{ gridRow }}
              title={
                element.modelCode === activeModelCode
                  ? `Active Model: ${element.modelCode}`
                  : `Model: ${element.modelCode}`
              }
            >
              {element.modelCode}
            </div>
            {_.range(element.rowStart, element.rowEnd - 1).map((rowNumber) => {
              const gridRow = `${rowNumber} / ${rowNumber + 2}`;
              return (
                <div
                  key={rowNumber}
                  className={classNames(styles.jobConnection, {
                    [styles.highlighted]: element.modelCode === activeModelCode,
                  })}
                  style={{ gridRow }}
                />
              );
            })}
          </>
        );
      })}
    </div>
  );
};

export default AugurTimeline;
