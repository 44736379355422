import {
  DataSource as DataSourceType,
  typeToSpeaking,
} from 'common/dist/types/dataManagement/dataSource';
import { OptionProps } from 'react-select';
import React, { FC } from 'react';
import classNames from 'classnames';
import styles from '../s3BucketSelect/v1/styles.module.scss';

type DSOptionType = DataSourceType;

type DSOptionComponentProps = OptionProps<DSOptionType, false>;
export const OptionComponent: FC<DSOptionComponentProps> = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <div
      {...innerProps}
      className={classNames(styles.optionComponent, {
        [styles.isSelected]: props.isSelected,
      })}
      ref={innerRef}
      data-testingIdentifier={props.label}
    >
      <span className={styles.title}>{props.data.name}</span>
      <span className={styles.subtitle}>
        {typeToSpeaking(props.data.ds_type)}
      </span>
    </div>
  );
};
