// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/parameter-tuning-results/v1/type.ts' --type 'ConfigParameterTuningResultsConfig'
export const schemaConfigParameterTuningResults = {
  $ref: '#/definitions/ConfigParameterTuningResultsConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<ParameterTuningResultsConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigParameterTuningResultsConfig: {
      $ref: '#/definitions/Config%3CParameterTuningResultsConfig%3E',
    },
  },
};
