import classNames from 'classnames';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { FiArrowUp } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import RepositoryContentElement from './RepositoryContentElement';
import styles from './styles.module.scss';
import vars from '../../../../scss/base/var.module.scss';
import { fetchRepoContent } from '../../../redux/workbench/modules/collab.repository.module';
import { RootState } from '../../../store/store';
import Button from '../../atoms/button/Button';
import { repositoryCloneLink } from '../../collaborationSpace/routes';

export type RepoElementType = {
  type: string;
  encoding?: string; // file / dir / symlink / submodule;
  size?: number;
  name: string;
  path: string;
  sha: string;
};

type Props = {
  repoFullName?: string;
  activeBranch?: string;
  setSelectedFile?: (selectedFile: string) => void;
};

const RepositoryContent: FC<Props> = (props) => {
  const { activeBranch, repoFullName, setSelectedFile } = props;

  const { loading, data, error, selectedPath } = useSelector<
    RootState,
    {
      loading?: boolean;
      data?: RepoElementType[];
      error?: string;
      selectedPath: string;
    }
  >((state) => state.workbench.collab.repository.repoContent);

  const [selectedPathName, setSelectedPath] = useState<string>(selectedPath);
  const {
    params: { group, repositoryName },
  } = useRouteMatch<{ group: string; repositoryName: string }>();

  const dispatch = useDispatch();

  const checkFetchData = () => {
    if (repositoryName) {
      dispatch(
        fetchRepoContent(
          repositoryName,
          // @ts-ignore
          activeBranch || 'master',
          // @ts-ignore
          selectedPathName,
          // @ts-ignore
          group
        )
      );
    } else if (repoFullName) {
      const parts = (repoFullName || '').split('/');
      const group = parts[0];
      const nameShown = parts[1];
      dispatch(
        fetchRepoContent(
          nameShown,
          // @ts-ignore
          activeBranch || 'master',
          // @ts-ignore
          selectedPathName,
          // @ts-ignore
          group
        )
      );
    }
  };

  useEffect(() => {
    checkFetchData();
  }, [activeBranch, repoFullName, selectedPathName]);
  const renderLoading = () => (
    <div className={styles.repositoryContent}>
      <ReactLoading
        className={'starting-stopping-spinner'}
        type={'cylon'}
        color={vars.colorPrimary}
      />
    </div>
  );

  const renderError = () => (
    <div className={styles.repositoryContent}>
      <span className={'error'}>{JSON.stringify(error)}</span>
    </div>
  );

  const renderEmptyRepository = () => (
    <div className={styles.repositoryContent}>
      <i>
        <span className={styles.emptyRepoText}>
          This repository is currently empty.
        </span>
        <Button
          buttonColor={'secondary'}
          withLink
          buttonLabelDefault={'Clone the Repository'}
          linkTo={repositoryCloneLink(props.repoFullName)}
        />
        <span className={styles.emptyRepoText}>
          into your private notebook environment to add content.
        </span>
      </i>
    </div>
  );

  /*
   * Renders a row to navigate up in the current filebrowser
   * */
  function folderUp() {
    return (
      <div
        onClick={() => {
          const folderUp: string[] = selectedPathName
            ? selectedPathName.split('/')
            : undefined;
          if (folderUp) {
            folderUp.pop();
            setSelectedPath(
              _.isEmpty(folderUp[0]) && folderUp.length <= 1
                ? '/'
                : folderUp.join('/')
            );
          }

          setSelectedFile('');
        }}
        className={classNames('ct-headline ct-row', styles.rowDirectoryUp)}
      >
        <div className={styles.colIcon}></div>
        <div className={'ct-col ct-col-flex-grow'}>
          <FiArrowUp size={'16px'} />
          <span>..</span>
        </div>
      </div>
    );
  }

  const renderLoaded = () => (
    <div className={styles.repositoryContent}>
      <span>Selected Path: {selectedPathName}</span>

      <div className={'ct-list'}>
        <div className={'ct-headline ct-row'}>
          <div className={'ct-col ct-col-40px'}>Type</div>
          <div className={'ct-col ct-col-flex-grow'}>
            <span>Name</span>
          </div>
        </div>
        {selectedPathName &&
          selectedPathName !== '/' &&
          selectedPathName !== '' &&
          folderUp()}
        {data &&
          data.length > 0 &&
          data?.map((e, i) => (
            <RepositoryContentElement
              key={i}
              type={e.type}
              name={e.name}
              path={e.path}
              sha={e.sha}
              {...e}
              setSelectedFile={setSelectedFile}
              setSelectedPath={setSelectedPath}
            />
          ))}
      </div>
    </div>
  );

  if (loading) return renderLoading();
  if (error) return renderError();
  if (selectedPathName === '/' && (!data || data.length === 0))
    return renderEmptyRepository();

  return renderLoaded();
};

export default RepositoryContent;
