import React, { FC, useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  Controls,
  isNode,
  ReactFlowProvider,
  ReactFlowState,
  useStoreState,
  useZoomPanHelper,
} from 'react-flow-renderer';
import dagre from 'dagre';
import _ from 'lodash';
import './styles.scss';

import { ToBeRefined } from 'common/dist/types/todo_type';
import FlowElementNode from './chart-elements/FlowElementNode';
import FlowElementGroup from './chart-elements/FlowElementGroup';
import {
  NodeType,
  PipelineTuningSchemaType,
} from 'common/dist/types/moduleVersion';

type Props = {
  /** The pipeline schema */
  pipeline: PipelineTuningSchemaType;
  /** Callback for when a node is selected */
  onSelectingNode: (selectedNode: NodeType) => void;
  inactiveNodeIds: string[];
};

const getLayoutedElements = (elements, nodes) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodesMap = _.keyBy(nodes, 'id');

  const isHorizontal = true;
  dagreGraph.setGraph({ rankdir: 'LR' }); // rankdir: LR | HR

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: nodesMap[el.id].__rf.width,
        height: nodesMap[el.id].__rf.height,
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      // @ts-ignore
      el.targetPosition = isHorizontal ? 'left' : 'top';
      // @ts-ignore
      el.sourcePosition = isHorizontal ? 'right' : 'bottom';

      // unfortunately we need this little hack to pass a slightly different position
      // to notify react flow about the change.
      // --> Update: Hack removed.
      el.position = {
        x: nodeWithPosition.x - nodesMap[el.id].__rf.width / 2, // + Math.random() / 1000,
        y: nodeWithPosition.y - nodesMap[el.id].__rf.height / 2,
      };
    }

    return el;
  });
};

const nodeTypes = {
  node: FlowElementNode,
  group: FlowElementGroup,
};

/**
 * Takes the pipeline definition and converts it to the schema as it's required by react flow.
 * Simply converts the format, doesn't change anything in the logic how the graph is connected.
 * @param pipeline
 */
function pipelineTuningSchemaToReactFlow(
  pipeline: PipelineTuningSchemaType
): ToBeRefined[] {
  // --- Convert the nodes to the react flow schema
  const convertedNodes = pipeline.nodes.map((node) => ({
    id: node.id,
    type: node.type,
    position: {
      x: 0, // will be set by onLayout, this is just an initial value
      y: 0, // will be set by onLayout, this is just an initial value
    },
    data: node,
  }));

  // --- Convert the edges to the react flow schema
  const convertedEdges = pipeline.edges.map((edge) => ({
    id: `${edge.sourceID}-${edge.targetID}`,
    source: edge.sourceID,
    target: edge.targetID,
    animated: false,
    type: 'default', // default = bezier curve
    arrowHeadType: 'arrowclosed',
  }));

  // --- Simply append the converted nodes and edges, since this is how react flow treats them
  return [...convertedNodes, ...convertedEdges];
}

/**
 * Generic Component that renders a tuning pipeline. Can be used for both the tuning input (which parameters are
 * supposed to be tested against each other) and to display the structure of the actual model.
 *
 * The flow how elements lead to a graph goes like this:
 * 1. Add the elements to the graph (they have x/y = 0 and no width)
 * 2. React-flow renders them and sets their height and width
 * 3. Read that information (from the nodes), calculate a graph layout and set it via the elements
 * 4. (Measure the height and set the css for that)
 * 5. Fit view
 *
 * @param props
 * @constructor
 */
const PipelineTuningChart: FC<Props> = ({
  onSelectingNode,
  inactiveNodeIds,
  pipeline,
}) => {
  const inactiveNodeIdCount = inactiveNodeIds.length;

  // --- Generate the elements from the schema and attach the "setSelectedNode" callback for groups
  // Can only be calculated once, since it's only used as initial value in useState, which will only use it once anyway
  const reactFlowElements = useMemo(
    () =>
      pipelineTuningSchemaToReactFlow(pipeline).map((el) => {
        if (el.type === 'group') {
          return {
            ...el,
            data: {
              ...el.data,
              setSelectedNode: (n) => {
                setSelectedNode(n);
                onSelectingNode(n);
              },
            },
          };
        } else {
          return el;
        }
      }),
    []
  );
  const [elements, setElements] = useState(reactFlowElements);

  // --- Stuff for selection and node state / inactivity
  const [selectedNode, setSelectedNode] = useState(null);
  function onSelectionChange(elements) {
    if (!elements || elements.length === 0) {
      setSelectedNode(null);
      onSelectingNode(null);
    } else if (elements[elements.length - 1].type === 'group')
      return; // Groups are not selectable (but will track the node selection by themselves)
    else {
      const node = elements[elements.length - 1]?.data;
      setSelectedNode(node);
      onSelectingNode(node);
    }
  }
  // Set the 'selectedNodeId' and isInactive flag of all elements
  const updateSelectedAndInactiveElements = () => {
    const layoutedElements = elements.map((el) => ({
      ...el,
      data: {
        ...el.data,
        selectedNodeId: selectedNode?.id,
        isInactive: inactiveNodeIds.includes(el.data?.id),
      },
    }));

    layoutedElements
      .filter((el) => el.type === 'group')
      .forEach((el) => {
        el.data = {
          ...el.data,
          nodes: (el.data?.nodes || []).map((n) => ({
            ...n,
            isInactive: inactiveNodeIds.includes(n.id),
          })),
        };
      });

    setElements(layoutedElements);
  };

  // Keep updating the elements to show their selection or activity state
  useEffect(() => {
    updateSelectedAndInactiveElements();
  }, [selectedNode, inactiveNodeIdCount]);

  // --- Stuff for measuring and setting the viewport
  const [measure, setMeasure] = useState({ width: 100, height: 100 });
  const nodes = useStoreState((store: ReactFlowState) => store.nodes);
  const { fitView } = useZoomPanHelper();

  // Do the layout once after nodes have been rendered, and we know their width/height
  const hasRendered = nodes.find((n) => n.__rf.height !== null) !== undefined;
  useEffect(() => {
    if (!hasRendered) return; // Nothing to do yet
    setElements(getLayoutedElements(elements, nodes));
  }, [hasRendered]);

  // Measure the actually rendered nodes (get their location and their size)
  const measureNodes = () => {
    const width =
      Math.max(...nodes.map((node) => node.__rf.position.x + node.__rf.width)) +
      15; // "+ 15" to add some sort of margin on the right side to have some space after scrolling all to the right
    const height =
      Math.max(
        ...nodes.map((node) => node.__rf.position.y + node.__rf.height)
      ) + 25; // "+ 25" for the scroll bar that might appear at the bottom
    setMeasure({ width, height });
  };

  // Measure once, cut twice (Should only need to measure if the nodes (size or layout) changed. Never after initially rendering and layouting them?)
  useEffect(() => {
    if (nodes.length === 0) return; // Nothing to do yet
    measureNodes();
  }, [nodes]);

  // Fit view after measuring (measuring only used as a proxy for the fact that the size or layout changed)
  // (seems to work by setting the zoom level depending on available width & height)
  useEffect(() => {
    fitView();
  }, [fitView, measure.width]);

  return (
    <div className={'PipelineTuningChart'}>
      <div
        style={{
          height: '400px',
          flexGrow: 1,
        }}
      >
        <ReactFlow
          elements={elements}
          nodesDraggable={false}
          nodesConnectable={false}
          nodeTypes={nodeTypes}
          elementsSelectable={true}
          onSelectionChange={onSelectionChange}
          paneMoveable={true}
          zoomOnScroll={false}
          zoomOnPinch={true}
          zoomOnDoubleClick={false}
          preventScrolling={false} // Can't scroll inside the ReactFlow area, but may need to scroll parent/siblings
          minZoom={0.1} // Default is 0.5, which would limit the extent fitView can zoom out
        >
          <Controls showInteractive={false} showFitView={true} />
        </ReactFlow>
      </div>
    </div>
  );
};

const WrappingPipelineTuningChart: FC<Props> = (props) => {
  return (
    <ReactFlowProvider>
      <PipelineTuningChart {...props} />
    </ReactFlowProvider>
  );
};

export default WrappingPipelineTuningChart;
