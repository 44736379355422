import React, { FC } from 'react';
import { ITooltip, Tooltip as ReactTooltip } from 'react-tooltip';
import classNames from 'classnames';
import styles from './styles.module.scss';

const Tooltip: FC<ITooltip> = ({ className, ...props }) => {
  return (
    <ReactTooltip
      className={classNames(styles.tooltip, className)}
      classNameArrow={styles.arrow}
      {...props}
    />
  );
};

const DefaultTooltip: FC<ITooltip> = (props) => {
  return <Tooltip {...props} className={styles.defaultTooltip} />;
};

export { DefaultTooltip };
export default Tooltip;
