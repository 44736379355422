import classNames from 'classnames';
import React, { FC } from 'react';
import { FiArrowUp } from 'react-icons/fi';
import { RouteComponentProps, withRouter } from 'react-router';

import styles from './styles.module.scss';
import { dataManagementRoutes } from '../../../routes';

type Props = {
  bucketPath?: string;
  dataSourceCode: string;
  bucket: string;
};

/**
 * Calculates the path one directory higher than the current one
 */
function oneDirUpPath(bucketPath, dataSourceCode, bucket) {
  if (!bucketPath) return '';
  const parts = bucketPath.replace(/^(\/)/, '').split('/');
  const upDir = parts.slice(0, parts.length - 1).join('/');
  const upDirClean = upDir.replace(/^(\/)/, ''); // Make sure that there is no leading "/"

  return `${dataManagementRoutes.basePath}/s3/${dataSourceCode}/bucket/${bucket}/content/${upDirClean}`;
}

const showDirectoryUp = (bucketPath) => {
  return bucketPath && bucketPath !== '/' && bucketPath !== '';
};

const DirectoryUp: FC<Props & RouteComponentProps> = (props) => {
  const { bucketPath, dataSourceCode, bucket, history } = props;

  if (!showDirectoryUp(bucketPath)) return null;
  return (
    <tr
      onClick={() =>
        history.push(oneDirUpPath(bucketPath, dataSourceCode, bucket))
      }
      className={classNames(styles.row, styles.rowDirectoryUp)}
    >
      <td className={styles.colIcon}>
        <FiArrowUp size={'16px'} />
      </td>
      <td className={styles.colName}>..</td>
      <td className={styles.colCreatedAt}></td>
      <td className={styles.colSize}></td>
      <td className={styles.colActions}></td>
    </tr>
  );
};

export default withRouter(DirectoryUp);
