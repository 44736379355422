import classNames from 'classnames';
import { arc, pie } from 'd3-shape';
import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ColorEnrichedData } from './PieChart';
import styles from './styles.module.scss';
import { PieArcDatum } from 'd3';

type Props = {
  data: ColorEnrichedData;
  width: number;
  height: number;
  smallView: boolean;
  portalTarget?: HTMLElement;
};

const Donut: FC<Props> = (props) => {
  const { data, width, height, smallView, portalTarget } = props;
  const values = data.map((data) => data.value);

  const arcs = pie()(values);
  let start = 0;
  arcs.forEach((a) => {
    const dist = a.endAngle - a.startAngle;
    a.startAngle = start;
    start = dist + start;
    a.endAngle = start;
  });

  const widthCalc = smallView ? 2 : 3.5;
  const position = `translate(${width / widthCalc}, ${height / 2})`;

  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const handleMouseOver = (
    index: number,
    event: React.MouseEvent<SVGPathElement>
  ) => {
    setHoveredIndex(index);

    if (portalTarget) {
      // Get the correct mouse position for the tooltip
      const { left, top } = portalTarget.getBoundingClientRect();

      const relativeX = event.clientX - left + portalTarget.scrollLeft;
      const relativeY = event.clientY - top + portalTarget.scrollTop;

      setMousePosition({ x: relativeX, y: relativeY });
      return;
    }
  };

  const handleMouseOut = () => {
    setHoveredIndex(null);
  };
  const widthCalc2 = smallView ? 1.2 : 2.1;
  const radius = Math.min(width / widthCalc2, height / 1.1);

  const arcGenerator = arc()
    .innerRadius(radius / 4.5)
    .outerRadius(radius / 2)
    .cornerRadius(2)
    .padAngle(2)
    .padRadius(1);

  // @ts-ignore
  const paths = arcs.map((item) => arcGenerator(item));

  return (
    <>
      <svg className={styles.donut}>
        <g transform={position}>
          {paths.map((item, index) => (
            <path
              className={styles.arc}
              key={index}
              fill={data[index].color}
              d={item}
              onMouseMove={(event) => handleMouseOver(index, event)}
              onMouseOut={handleMouseOut}
              style={{ animationDelay: `${index * 0.003}s` }}
            ></path>
          ))}
        </g>
      </svg>
      {hoveredIndex !== null &&
        portalTarget &&
        createPortal(
          <div
            className={classNames(styles.tooltip, {
              [styles.opacityOne]: hoveredIndex !== null,
              [styles.visible]: hoveredIndex !== null,
            })}
            style={{
              top: mousePosition.y - 38,
              left: mousePosition.x + 10,
            }}
          >
            {data[hoveredIndex].label}
          </div>,
          portalTarget
        )}
    </>
  );
};

export default Donut;
