import { PostAugurRequestBody } from 'common/dist/types/requestBodies/augurs';
import React, { FC } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import styles from './styles.module.scss';
import { AugurSettings, ModuleConfiguration } from './type';
import {
  getFormPageErrors,
  useAugurSettingsForm,
} from './utils/augurSettings.form';
import { pickGeneralConfigurationResources } from './utils/transformation';
import { SettingsTransformationWrapper } from './ViewAugur';
import { useAddAugur } from '../../../core/api/augurs';
import { useModules } from '../../../core/api/modules';
import * as routes from '../../index/routes';
import { ID_GENERAL_SETTINGS } from '../../molecules/augur-menu/types';
import GridLayoutEditor from '../../molecules/grid-layout-editor/GridLayoutEditor';
import { GridLayoutElement } from '../../molecules/grid-layout-editor/type';
import NewMultiPageWizard, {
  WizardPageInfo,
} from '../../organisms/multi-page-wizard/NewMultiPageWizard';
import GeneralSettings from '../general-settings/GeneralSettings';
import MainContainer from '../main-container/MainContainer';

export const getAugurSubmitSummary = (
  augurSettings: AugurSettings,
  habitatCode: string
): PostAugurRequestBody => {
  // Filter out undefined values from resources
  const filteredResources = pickGeneralConfigurationResources(
    augurSettings.general
  );
  return {
    // Habitat Code
    habitatCode,

    // Module
    moduleCode: augurSettings.general.module.moduleCode,
    moduleVersionCode: augurSettings.general.module.moduleVersionCode,

    // Augur name
    name: augurSettings.general.augurName,

    attributes: augurSettings.general.attributes,
    resources: filteredResources,
    // --- Settings
    settings: augurSettings.settingsData,
  };
};

const emptyConfig: ModuleConfiguration = {
  apiVersion: 'v0', // FIXME-CM
  generalConfiguration: {
    supportsLearning: false,
    supportsEvaluation: false,
    supportsPrediction: false,
    supportsRealtimePrediction: false,
  },
  augurReportConfiguration: {
    learning: [],
    evaluation: [],
    prediction: [],
  },
  augurSettingsConfiguration: [],
};

const NewNewAugurWizard: FC = () => {
  const history = useHistory();
  const navigateToModelManagement = () =>
    history.push(routes.app.prefix + routes.app.models);

  const { habitatCode } = useParams<{ habitatCode: string }>();
  const { mutate, isLoading } = useAddAugur(habitatCode);

  const { data: modules } = useModules();

  const getModuleConfig = (moduleCode?: string, moduleVersionCode?: string) => {
    return (
      (modules
        ?.find((module) => module.code === moduleCode)
        ?.versions?.find((version) => version.code === moduleVersionCode)
        ?.config as unknown as ModuleConfiguration) ?? emptyConfig
    );
  };

  const {
    formMethods: augurSettingsFormMethods,
    visibilityFilteredModuleConfig,
  } = useAugurSettingsForm(false, emptyConfig, habitatCode, getModuleConfig);
  const {
    watch,
    handleSubmit,
    formState: { errors, isDirty, isValid, isValidating },
  } = augurSettingsFormMethods;

  const onSubmitAugurSettings = (augurSettings: AugurSettings) => {
    mutate(getAugurSubmitSummary(augurSettings, habitatCode), {
      onSuccess: navigateToModelManagement,
    });
  };

  const currentSettings = watch('settingsData');
  const renderContentPane = (id: string) => {
    const renderLayoutEditor = () => {
      const page =
        visibilityFilteredModuleConfig.augurSettingsConfiguration.find(
          (page) => page.uuid === id
        );
      const renderedElements = page.elementArrangement.reduce(
        (acc, layoutElement) => {
          const element = page.elements.find(
            (el) => el.uuid === layoutElement.i
          );

          return [
            {
              id: layoutElement.i,
              element: (
                <SettingsTransformationWrapper
                  element={element}
                  inputs={currentSettings}
                />
              ),
            },
            ...acc,
          ];
        },
        [] as GridLayoutElement[]
      );

      return (
        <GridLayoutEditor
          arrangement={page.elementArrangement}
          elements={renderedElements}
          viewOnly={true}
        />
      );
    };

    return (
      <>
        {/*<DevTool control={control} />*/}
        <FormProvider {...augurSettingsFormMethods}>
          <form className={styles.settingsContainer}>
            {id === ID_GENERAL_SETTINGS ? (
              <GeneralSettings
                moduleTypeSelectable={true}
                generalConfiguration={
                  visibilityFilteredModuleConfig.generalConfiguration
                }
                form={augurSettingsFormMethods}
              />
            ) : (
              renderLayoutEditor()
            )}
          </form>
        </FormProvider>
      </>
    );
  };

  const onSubmit: SubmitHandler<AugurSettings> = (filteredData) => {
    return onSubmitAugurSettings(filteredData);
  };

  const wizardPages: WizardPageInfo[] =
    visibilityFilteredModuleConfig.augurSettingsConfiguration.map((page) => {
      const pageErrors = getFormPageErrors(page, errors);
      return {
        id: page.uuid,
        title: page.title,
        error:
          pageErrors.length > 0
            ? `${pageErrors.length} error(s) on this page.`
            : undefined,
      };
    });
  const nErrorsGeneralSettings = Object.keys(
    errors[ID_GENERAL_SETTINGS] || {}
  ).length;
  wizardPages.unshift({
    id: ID_GENERAL_SETTINGS,
    title: 'General Settings',
    error:
      nErrorsGeneralSettings > 0
        ? `${nErrorsGeneralSettings} error(s) on this page.`
        : undefined,
  });

  return (
    <MainContainer fullWidth scrollableY={false}>
      <NewMultiPageWizard
        pages={wizardPages}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={navigateToModelManagement}
        // kind of a dirty fix, but isDirty is included here to disable the submit button on initial render
        isValid={isValid && isDirty}
        isValidating={isValidating}
        renderContent={renderContentPane}
        wizardHeadline={`Create a new Augur in Habitat ${habitatCode}`}
        isSubmitting={isLoading}
      />
    </MainContainer>
  );
};

// FIXME-CM: rename after removing old NewAugurWizard
export default NewNewAugurWizard;
