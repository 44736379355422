import React, { FC } from 'react';
import styles from './styles.module.scss';
import IconButton from '../../../../atoms/icon-button/IconButton';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { FiDownload } from 'react-icons/fi';
import keycloak, { updateToken } from '../../../../../../keycloak';
import { S3Object } from '../../../../../store/dataManagement/state.types';

type Props = {
  element: S3Object;
  dataSourceCode: string;
  bucket: string;
  showRenameModal: (renameFile: string, renamePath: string) => void;
  showDeleteModal: (deletePath: string) => void;
};

const FileActions: FC<Props> = (props) => {
  const {
    element,
    dataSourceCode,
    bucket,
    showRenameModal,
    showDeleteModal,
  } = props;

  return (
    <div className={styles.fileActions}>
      <IconButton
        Icon={() => <FiEdit size={16}></FiEdit>}
        onClick={(e) => {
          showRenameModal(element.name, element.path);
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      />
      <IconButton
        Icon={() => <FiDownload size={16} />}
        onClick={(e) => {
          // sorry for what is about to happen here.
          // there were no good options. trust me.
          updateToken().then((_) => {
            fetch(
              `/dataman/s3/${dataSourceCode}/download/bucket/${bucket}${element.path}`,
              {
                headers: {
                  Authorization: `Bearer ${keycloak.token}`,
                },
              }
            )
              .then((res) => res.blob())
              .then((blob) => {
                const el = document.createElement('a');
                el.setAttribute('href', URL.createObjectURL(blob));
                el.setAttribute('download', element.name);
                el.style.display = 'none';
                document.body.appendChild(el);
                el.click();
                document.body.removeChild(el);
              });
          });
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
        // For a discussion about the download problem, see:
        // https://gitlab.sigmalto.com/altasigma-platform/ticket-system/-/issues/1233
      />
      <IconButton
        Icon={() => <FiTrash size={16} />}
        onClick={(e) => {
          showDeleteModal(element.path);
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      />
    </div>
  );
};

export default FileActions;
