import { scaleBand, scaleLinear } from 'd3-scale';
import React, { FC, useState } from 'react';

import Axis from './Axis';
import AxisBottom from './AxisBottom';
import Bars from './Bars';
import styles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';
import { DropdownSelectInput } from '../../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { SharedInputProps } from '../../../../common/utils';
import { OptionType } from '../../../../settings-elements/common/type';
import commonStyles from '../../../styles.module.scss';
import { MultiReportElementProps } from '../../../types/meta';
import {
  PerformanceDriftConfig,
  PerformanceDriftData,
  PerformanceDriftReportData,
  PerformanceDriftValueFormat,
} from '../type';

export interface MultiPerformanceDriftData {
  data: (PerformanceDriftData | undefined)[];
  dates: number[];
}

export type Props = MultiPerformanceDriftData &
  PerformanceDriftConfig &
  SharedInputProps;

export const MultiPerformanceDrift: FC<Props> = (props) => {
  const { data, dates, portalTarget } = props;

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const keySet = new Set<string>();
  data
    .filter((da) => !!da)
    .forEach((d) => {
      Object.keys(d!).forEach((k) => {
        keySet.add(k);
      });
    });
  const keys = Array.from(keySet);

  const noUndefinedData: PerformanceDriftData[] = data.filter(
    (d) => !!d
  ) as PerformanceDriftData[];

  const firstKey = keys.length > 0 ? keys[0] : '';

  const [selectedOption, setSelectedOption] = useState<string>(firstKey);

  const allData: PerformanceDriftData = {};
  noUndefinedData.forEach((data) => {
    Object.assign(allData, data);
  });

  const [
    performanceDriftValueFormat,
    setPerformanceDriftValueFormat,
  ] = useState<PerformanceDriftValueFormat>(allData[firstKey]?.format);

  const options: OptionType[] = [];
  const optionKeys = new Set();
  noUndefinedData.forEach((e) => {
    const keys = Object.keys(e);
    keys.forEach((key) => {
      if (!optionKeys.has(key)) {
        options.push({
          label: key,
          value: key,
        });
      }
      optionKeys.add(key);
    });
  });

  const dataPoints: [number, number?][] = data.map((i, index) => {
    return [
      index + 1,
      !i || !Object.keys(i).includes(selectedOption)
        ? undefined
        : Number(i[selectedOption].value),
    ];
  });

  const margin = {
    top: 20,
    right: 15,
    bottom: 120,
    left: 45,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const translate = `translate(${margin.left}, ${margin.top})`;

  const yScale = scaleLinear().range([chartHeight, 0]);
  const xScale = scaleBand<number>().range([0, chartWidth]).paddingInner(0.2);
  const scaleCount = 0;
  const dateScale = scaleBand<number>()
    .range([0, chartWidth])
    .paddingInner(0.2)
    .domain(dates);

  return (
    <div ref={ref} className={commonStyles.container}>
      <DropdownSelectInput
        className={styles.dropdown}
        options={options}
        placeholder={firstKey ? selectedOption : ''}
        onChange={(option: OptionType | null) => {
          if (!option) return;
          setPerformanceDriftValueFormat(allData[option.value].format);
          setSelectedOption(option.label);
        }}
        menuPortalTarget={portalTarget}
      />
      <svg style={{ width: '100%', height: height - 70 }}>
        <g transform={translate}>
          <Bars
            performanceDriftValueFormat={performanceDriftValueFormat}
            data={dataPoints}
            xScale={xScale}
            yScale={yScale}
          />
          <Axis
            // Key to force rerender, because apparently d3 and shouldComponentUpdate is a good idea???
            key={performanceDriftValueFormat}
            performanceDriftValueFormat={performanceDriftValueFormat}
            ticks={4}
            scale={yScale}
            tickSize={chartWidth}
            helplineType={'dashed'}
            direction={'left'}
          />
          <g transform={`translate(0, ${height - margin.top - margin.bottom})`}>
            <AxisBottom scale={dateScale} scaleCount={scaleCount} />
          </g>
        </g>
      </svg>
    </div>
  );
};

export const PerformanceDriftMulti: FC<
  MultiReportElementProps<PerformanceDriftReportData, PerformanceDriftConfig> &
    SharedInputProps
> = ({ input, config, ...rest }) => {
  const data = input.map((report) => report.reportValue);
  const dates = input.map((report) => report.timestamp.getTime());
  return (
    <MultiPerformanceDrift
      data={data}
      {...config}
      {...rest}
      dates={dates}
      portalTarget={rest.portalTarget}
    />
  );
};
