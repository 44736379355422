import { defineMessages } from 'react-intl';

export default defineMessages({
  msgCodeCapsulesCount: {
    id: 'dashboard.header.code_capsules_count',
    defaultMessage:
      '{codeCapsulesCount, plural, =0 {No Code Capsule} one {# Code Capsule} other {# Code Capsules}}',
  },

  msgCcRunModalVersionHeader: {
    id: 'codeCapsule.runModal.selectVersionHeader',
    defaultMessage: 'Version',
  },
  msgCcRunModalVersionSubheader: {
    id: 'codeCapsule.runModal.selectVersionSubheader',
    defaultMessage: 'Select the version of the Code Capsule.',
  },

  msgCcRunModalJobNameHeader: {
    id: 'codeCapsule.runModal.jobNameHeader',
    defaultMessage: 'Job Name (optional)',
  },
  msgCcRunModalJobNameSubheader: {
    id: 'codeCapsule.runModal.jobNameSubheader',
    defaultMessage: 'Enter a name to identify the Job.',
  },

  msgCcRunModalResourcesHeader: {
    id: 'codeCapsule.runModal.resourcesHeader',
    defaultMessage: 'Resources (optional)',
  },
  msgCcRunModalResourcesSubheader: {
    id: 'codeCapsule.runModal.resourcesSubheader',
    defaultMessage: 'Enter the compute resources for the Code Capsule Job.',
  },

  msgCcRunModalParametersHeader: {
    id: 'codeCapsule.runModal.parametersHeader',
    defaultMessage: 'Parameters',
  },
  msgCcRunModalParametersSubheader: {
    id: 'codeCapsule.runModal.parametersSubheader',
    defaultMessage: 'Enter the parameters for the Code Capsule Job.',
  },

  msgCcRunModalNotebooksHeader: {
    id: 'codeCapsule.runModal.notebooksHeader',
    defaultMessage: 'Notebooks',
  },
  msgCcRunModalNotebooksSubheader: {
    id: 'codeCapsule.runModal.notebooksSubheader',
    defaultMessage:
      'Select the notebooks to execute and define their execution order.',
  },
  msgCcRunModalNotebooksAvailable: {
    id: 'codeCapsule.runModal.notebooksAvailable',
    defaultMessage: 'Available Notebooks',
  },
  msgCcRunModalNotebooksSelected: {
    id: 'codeCapsule.runModal.notebooksSelected',
    defaultMessage: 'Selected Notebooks',
  },
  msgCcRunModalNotebooksFilter: {
    id: 'codeCapsule.runModal.notebooksFilter',
    defaultMessage: 'Filter the notebook lists',
  },
});
