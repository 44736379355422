import { Config } from '../../../../../pages/augur/type';
import { CassandraKeyspaceSelectAugurSettings } from '../../cassandraKeyspaceSelect/v1/type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { AllowedInputs } from '../../types/util';
import { CassandraTableSelectV1AugurSettings as CassandraTableSelectAugurSettings } from 'common/dist/types/augurSettings/cassandraTableSelectV1AugurSettings';

export type { CassandraTableSelectAugurSettings };

export type CassandraTableSelectConfig = {
  keyspace: CassandraKeyspaceSelectAugurSettings;
  showTable?: boolean;
};
export type ConfigCassandraTableSelectConfig =
  Config<CassandraTableSelectConfig>;

export type PartialCassandraTableSelectAugurSettings =
  CassandraTableSelectAugurSettings; // Partial<CassandraTableSelectAugurSettings>;

export const configAllowedInputsCassandraTableSelect: AllowedInputs<CassandraTableSelectConfig> =
  {
    keyspace: [
      { type: SETTINGS_ELEMENT_TYPES.CASSANDRA_KEYSPACE_SELECT, version: 'v1' },
    ],
  };

export const configTypeCassandraTableSelect = `{ 
  keyspace: { source: 'input-element', elementUuid: string },
  showTable?: {source: 'hard-coded', value: boolean },
}`;

export const augurSettingsTypeCassandraTableSelect = `string`;

export const defaultConfigCassandraTableSelect: ConfigCassandraTableSelectConfig =
  {
    keyspace: { source: 'input-element', elementUuid: '' },
    showTable: { source: 'hard-coded', value: true },
  };

export const defaultAugurSettingsDefaultCassandraTableSelect: PartialCassandraTableSelectAugurSettings =
  '';

// JSONSchemaType<CassandraTableSelectAugurSettings>
export const defaultValidationSchemaCassandraTableSelect = {
  type: 'string',
};
