import React, { FC } from 'react';
import { Link, LinkProps, useLocation } from 'react-router-dom';
import qs from 'qs';

export const LinkWithQuery: FC<LinkProps> = ({
  children,
  to: rawTo,
  ...props
}) => {
  let { search } = useLocation();
  let to = rawTo;
  // Avoid duplicate query parameters from search and to by combining them
  if (typeof to === 'string') {
    const split = to.split('?');
    const query = split[1];
    if (query) {
      to = split[0];
      const existingParams = qs.parse(query);
      if (existingParams) {
        search = qs.stringify(
          {
            ...qs.parse(search, { ignoreQueryPrefix: true }),
            ...existingParams,
          },
          { addQueryPrefix: true }
        );
      }
    }
  }

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};
