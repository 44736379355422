import { Config } from '../../../../../pages/augur/type';
import { ConstraintExhaustionV1ReportData as ConstraintExhaustionReportData } from 'common/dist/types/reportData/constraintExhaustionV1ReportData';

export type { ConstraintExhaustionReportData };

export type ConstraintExhaustionConfig = {};

export type ConfigConstraintExhaustionConfig =
  Config<ConstraintExhaustionConfig>;

export const configTypeConstraintExhaustion = `{}`;

export const reportDataTypeConstraintExhaustion = `{
    constraintExhaustion: [{
        constraint: {
            id: string;
            name: string;
            description?: string;
            constraintType: 'quantity' | 'cost' | 'individualValue';
            operator: 'leq' | 'geq' | 'eq';
            value: string;
            constraintLevel: 'channel' | 'communication' | 'communicationGroup' | 'allCommunications';
            channelId?: string;              // Only for constraintLevel 'channel'
            communicationId?: string;        // Only for constraintLevel 'communication'
            communicationGroupId?: string;   // Only for constraintLevel 'communicationGroup'
        };
        exhaustion: number;
    }],
    augurSettings: {
        channels: {
            id: string;
            name: string;
            description?: string | undefined;
        }[];
        propensities: {
            id: string;
            description?: string | undefined;
            valueId: string;
        }[];
        communications: {
            id: string;
            name: string;
            description?: string | undefined;
            channelId: string;
            propensityId: string;
            boostFactor: string;
            variableCosts?: string | undefined;
            fixedCosts?: string | undefined;
        }[];
        communicationGroups?: {
            id: string;
            name: string;
            description?: string | undefined;
            communicationIds: string[];
        }[];
        customerTable?: {
            dataSourceCode: string;
            bucketName?: string;
            keyspaceName?: string;
            tableName: string;
            customerIdColumn?: string | undefined;
            customerId2Column?: string | undefined;
            valueIdColumn?: string | undefined;
            valueColumn?: string | undefined;
        };
        constraints?: {
            id: string;
            name: string;
            description?: string;
            constraintType: 'quantity' | 'cost' | 'individualValue';
            operator: 'leq' | 'geq' | 'eq';
            value: string;
            constraintLevel: 'channel' | 'communication' | 'communicationGroup' | 'allCommunications';
            channelId?: string;              // Only for constraintLevel 'channel'
            communicationId?: string;        // Only for constraintLevel 'communication'
            communicationGroupId?: string;   // Only for constraintLevel 'communicationGroup'
        }[];
        contactPolicies?: {
            id: string;
            name: string;
            description?: string;
            contactPolicyType: 'count';
            operator: 'leq' | 'geq' | 'eq';
            value: string;
        }[];
        targetFunction?: {
            summandGroups: {
                id: string;
                name: string;
                description?: string;
                sumOver: 'allCommunications';
                sumOf: 'costs' | 'values';
                factor: string;
            }[];
            minimizeOrMaximize: 'minimize' | 'maximize';
        };
    }
}`;

export const defaultConfigConstraintExhaustion: ConfigConstraintExhaustionConfig =
  {};

export const defaultReportDataDefaultConstraintExhaustion: ConstraintExhaustionReportData =
  {
    constraintExhaustion: [
      {
        constraint: {
          constraintLevel: 'channel',
          channelId: 'ch01',
          id: 'e78e9b74-ed39-4a51-884c-c5549e27616c',
          name: 'constraint01',
          description: 'Description',
          constraintType: 'cost',
          operator: 'leq',
          value: '2',
        },
        exhaustion: 1,
      },
      {
        constraint: {
          constraintLevel: 'channel',
          channelId: 'ch02',
          id: 'a73efz69-bz35-2b93-641g-c4285h14662z',
          name: 'constraint02',
          description: 'Description',
          constraintType: 'cost',
          operator: 'geq',
          value: '2',
        },
        exhaustion: 1,
      },
    ],
    augurSettings: {
      channels: [
        { id: 'ch01', name: 'channel01' },
        { id: 'ch02', name: 'channel02' },
      ],
      propensities: [{ id: 'pr1', valueId: 'propensity01' }],
      communications: [
        {
          id: 'com1',
          name: 'communication01',
          channelId: 'ch01',
          propensityId: 'pr01',
          boostFactor: '1',
        },
      ],
      learningKpi: '',
    },
  };
