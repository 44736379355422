import msgsNotifications from 'common/dist/messages/notifications';
import {
  contentArrayToPath,
  contentPathToArray,
} from 'common/dist/utils/workbench/content';
import qs from 'qs';
import React, { FC, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { contentKeys } from '../../../../../core/api/workbench/content';
import NotebookApi from '../../../../../core/api/workbench/git.notebook';
import {
  error as ERROR_NOTIFICATION,
  event as EVENT_NOTIFICATION,
} from '../../../../../core/notifications';
import { sendNotification } from '../../../../../redux/modules/notifications.module';
import { invalidateQueries } from '../../../../../redux/modules/reactQuery.module';
import { notebookUser } from '../../../../../redux/workbench/selectors/notebookUser.selector';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';

const RemoveModuleArtifactsWizard: FC = () => {
  const dispatch = useDispatch();
  const jupyterUser = useSelector((state) => notebookUser(state));
  const [clicked, setClicked] = useState(false);
  const location = useLocation();

  const queryParameter = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { path: string };

  const fileBrowserRoute = {
    pathname: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`,
    search: location.search,
  };

  const selectedDirPath = contentPathToArray(queryParameter.path || '/');
  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  const relativeModulePath = contentArrayToPath(
    selectedDirPath.slice(0, index + 1),
    false
  );

  async function handleRemoveModuleArtifacts() {
    const notebookApi = new NotebookApi(jupyterUser);
    notebookApi
      .removeModuleArtifacts(relativeModulePath)
      .then(() => {
        dispatch(
          sendNotification(
            msgsNotifications.titleRemoveAllArtifactsSuccess.id,
            // @ts-ignore
            msgsNotifications.titleRemoveAllArtifactsSuccess.id,
            EVENT_NOTIFICATION
          )
        );
        dispatch(
          invalidateQueries(
            contentKeys.content(jupyterUser, relativeModulePath)
          )
        );
      })
      .catch((e) =>
        dispatch(
          sendNotification(
            msgsNotifications.titleRemoveAllArtifactsFailed.id,
            // @ts-ignore
            msgsNotifications.titleRemoveAllArtifactsFailed.id,
            ERROR_NOTIFICATION
          )
        )
      );
  }

  return (
    <div
      className='dialog-container'
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className='dialog-inner-container'>
        <p className='dialog-headline'>Clear all artifacts in the module</p>
        <div className='step-container'>
          <div className='description-container'>
            <div className='bubble bubble-delete'>
              <FiX className='icon-x' size='20px' />
            </div>
            <div className='description'>
              <div className='title'>
                <p>
                  Are you sure you want to clear all artifacts? This includes
                  the venv, all old reports and all augurdata files.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='dialog-button-container'>
          <Link to={fileBrowserRoute}>
            <button className='dialog-button dialog-button-neutral'>
              Cancel
            </button>
          </Link>
          <Link
            to={fileBrowserRoute}
            onClick={handleRemoveModuleArtifacts}
            style={{ textDecoration: 'none' }}
          >
            <button
              className={`button dialog-button dialog-button-delete${
                clicked ? ' dialog-button-disabled' : ''
              }`}
            >
              <span>Clear Artifacts</span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RemoveModuleArtifactsWizard;
