import classNames from 'classnames';
import React, { FC, useState } from 'react';

import styles from './styles.module.scss';
import pieStyles from '../single/styles.module.scss';

type Props = {
  colors: { [label: string]: string };
  setLegendHoverIndex: (index: number) => void;
  smallView: boolean;
};

const Legend: FC<Props> = ({ colors, setLegendHoverIndex, smallView }) => {
  // For small width
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };
  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleMouseEnter = (index: number) => {
    setLegendHoverIndex(index);
  };

  const handleMouseLeave = () => {
    setLegendHoverIndex(-1);
  };

  const labels = Object.keys(colors);

  if (smallView)
    return (
      <>
        <ul className={styles.legendSymbol}>
          <div
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            className={pieStyles.restLegend}
          >
            <p className={pieStyles.expandLegendText}>{' legend '}</p>
          </div>
        </ul>
        <div className={styles.legendContainer}>
          <ul
            className={classNames(pieStyles.restLegendContainer, {
              [pieStyles.opacityOne]: isHovered,
              [pieStyles.visible]: isHovered,
            })}
            style={{
              top: 0,
              right: 0,
            }}
          >
            {labels.map((l, index) => (
              <li key={index}>
                <span
                  className={pieStyles.legendDot}
                  style={{ backgroundColor: colors[l] }}
                />
                {l}
              </li>
            ))}
          </ul>
        </div>
      </>
    );

  return (
    <div className={classNames(styles.overflowX)}>
      <ul className={styles.legend}>
        {labels.map((l, index) => (
          <li
            className={styles.pointer}
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <span
              className={styles.legendDot}
              style={{ backgroundColor: colors[l] }}
            />
            {l}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Legend;
