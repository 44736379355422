import classNames from 'classnames';
import React, { FC } from 'react';
import { FiHome } from 'react-icons/fi';
import './styles.scss';

export type Props = {
  /** Selected directory path */
  dirs: string[];
  /** Visually highlight the last entry? */
  highlightLastEntry?: boolean;
  /** Change the cursor and the background of the elements when hovered? */
  highlightOnHover?: boolean;
  /** Optional click listener that is invoked when clicking on a directory in the path. For example to jump to a given directory path */
  onDirectoryClick?: (dirs: string[]) => void;
  /** Is the trailing slash after the last directory supposed to be shown? */
  showTrailingSlash?: boolean;
  /** Show the bottom border under the component? */
  showTopBorder?: boolean;
};

/**
 * Component that shows the path of the currently opened directory.
 * The path is passed as an array of parent directories starting with `["root", ...]`.
 * Clicking one of the paths triggers the `onDirectoryClick` callback.
 * @param dirs
 * @param onDirectoryClick
 * @param highlightLastEntry
 * @param highlightOnHover
 * @param showTrailingSlash
 * @param showTopBorder
 * @constructor
 */
const SelectedDirs: FC<Props> = ({
  dirs,
  onDirectoryClick,
  highlightLastEntry,
  highlightOnHover,
  showTrailingSlash,
  showTopBorder,
}) => {
  const home = () => (
    <div key={0} className={'SelectedDirs--single-dir'}>
      <div
        className={'SelectedDirs--home-icon-container'}
        onClick={() => onDirectoryClick && onDirectoryClick([])}
      >
        <FiHome size={'14px'} />
      </div>
      <p className={'SelectedDirs--dir-separator'}>/</p>
    </div>
  );

  const directory = (
    dirName: string,
    i: number,
    doHighlight: boolean,
    showSlash: boolean
  ) => (
    <div
      key={i}
      className={
        'SelectedDirs--single-dir' +
        (doHighlight ? ' SelectedDirs--highlight' : '')
      }
    >
      <p
        className={'SelectedDirs--dir-name'}
        onClick={() =>
          onDirectoryClick && onDirectoryClick(dirs.slice(0, i + 1))
        }
      >
        {dirName}
      </p>
      {showSlash && <p className={'SelectedDirs--dir-separator'}>/</p>}
    </div>
  );

  return (
    <div
      className={classNames(
        'SelectedDirs',
        { 'SelectedDirs--highlight-on-hover': highlightOnHover },
        { 'SelectedDirs--top-border': showTopBorder }
      )}
    >
      {[
        home(),
        dirs.map((dirName, i) => {
          const doHighlight = highlightLastEntry && i === dirs.length - 1;
          const showSlash = i < dirs.length - 1 || showTrailingSlash;
          return directory(dirName, i, doHighlight, showSlash);
        }),
      ]}
    </div>
  );
};

export default SelectedDirs;
