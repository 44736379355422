import { ParameterTuningV1AugurSettings as ParameterTuningAugurSettings } from 'common/dist/types/augurSettings/parameterTuningV1AugurSettings';
import { ParameterTuningSchema } from 'common/dist/types/moduleVersion';
import { DeepPartial } from 'react-hook-form';

import { ParameterTuningErrorType } from './ParameterTuningSelection';
import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';

export type { ParameterTuningAugurSettings };

export type ParameterTuningConfig = {
  parameterTuningSchema: ParameterTuningSchema;
};

export type ConfigParameterTuningConfig = Config<ParameterTuningConfig>;

export type PartialParameterTuningAugurSettings =
  Partial<ParameterTuningAugurSettings>;

export type ParameterTuningValidationError =
  DeepPartial<ParameterTuningErrorType>;

export const configAllowedInputsParameterTuning: AllowedInputs<ParameterTuningConfig> =
  {};

export const configTypeParameterTuning = `{
  availableSearchStrategies: { 
    source: 'hard-coded', 
    value: {
      name: string,
      speakingName: string,
      description: string
    }[],
    availableAlgorithms: {
      name: string,
      speakingName: string,
      description: string,
      parameters: {
        name: string,
        speakingName: string,
        description: string,
        validValues: ({
            type: 'double' | 'int',
            min: number,
            max: number
          })[],
        default: number[]
      }
    }
  }`;

export const augurSettingsTypeParameterTuning = `{
  searchStrategy: {
    name: string;
  };
  algorithms: {
    name: string;
    parameters: {
      name: string;
      values: number[];
    }[];
  }[];
}`;

export const defaultConfigParameterTuning: ConfigParameterTuningConfig = {
  parameterTuningSchema: {
    source: 'hard-coded',
    value: {
      availableAlgorithms: [
        {
          name: 'random-forest',
          speakingName: 'Random Forest',
          description: 'This is a Random Forest',
          parameters: [
            {
              name: 'n_estimators',
              speakingName: 'Amount of Trees',
              description: '',
              validValues: [
                {
                  type: 'int',
                  min: 1,
                  max: 1000,
                },
              ],
              default: [100, 200],
            },
            {
              name: 'ccp_alpha',
              speakingName: 'CCP Alpha',
              description: 'Short description of what this parameter does',
              validValues: [
                {
                  type: 'double',
                  min: 0.0,
                  max: 100.0,
                },
              ],
              default: [1],
            },
            {
              name: 'max_features',
              speakingName: 'Max Features',
              description:
                'The number of features to consider when looking for the best split',
              validValues: [
                {
                  type: 'double',
                  min: 0.0,
                  max: 100.0,
                },
                {
                  type: 'int',
                  min: 0,
                  max: 1000,
                },
              ],
              default: [100],
            },
          ],
        },
      ],
      availableSearchStrategies: [
        {
          name: 'gridsearch',
          speakingName: 'Grid-Search',
          description: 'Description of the strategy',
        },
      ],
    },
  },
};

export const defaultAugurSettingsDefaultParameterTuning: PartialParameterTuningAugurSettings =
  {
    searchStrategy: {
      name: 'gridsearch',
    },
    algorithms: [
      {
        name: 'random-forest',
        parameters: [
          {
            name: 'n_estimators',
            values: [100, 200],
          },
          {
            name: 'ccp_alpha',
            values: [4, 6, 8, 10, 12, 14, 16],
          },
          {
            name: 'max_features',
            values: [100],
          },
        ],
      },
    ],
  };

export const defaultValidationSchemaParameterTuning = {
  type: 'object',
  required: ['searchStrategy', 'algorithms'],
};
