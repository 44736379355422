import React, { FC } from 'react';
import { ToBeRefined } from 'common/dist/types/todo_type';

type Props = {
  /** ID of the TextInputLinePassword component, will be adjusted and used for the input elements */
  id: string;
  /** class name for the parent component of the input elements */
  className?: string;
  /** Is the password shown in plain text? */
  isPasswordRevealed: boolean;
  /** Value of the input field */
  value: string;
  /** onChange callback */
  onChange: (e: ToBeRefined) => void;
  /** Intl formatted placeholder message */
  placeholderStr?: string;
  /** Is the Password Inptu disabled? */
  disabled?: boolean;
  /**Disable the autocomplete **/
  autoComplete?: string;
  testingIdentifier?: string;
  /** onBlur callback */
  onBlur?: (e: ToBeRefined) => void;
  /** onFocus callback */
  onFocus?: (e: ToBeRefined) => void;
};

const PasswordInput: FC<Props> = ({
  isPasswordRevealed,
  value,
  onChange,
  placeholderStr,
  id,
  className,
  disabled,
  autoComplete,
  testingIdentifier,
  onFocus,
  onBlur,
}) => {
  if (isPasswordRevealed) {
    return (
      <input
        id={`${id}-revealed`}
        data-testingIdentifier={testingIdentifier}
        placeholder={placeholderStr}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  } else {
    return (
      <input
        type='password'
        id={`${id}-password`}
        data-testingIdentifier={testingIdentifier}
        placeholder={placeholderStr}
        value={value}
        onChange={onChange}
        disabled={disabled}
        autoComplete={autoComplete}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  }
};
/** @deprecated use react hook form variant instead*/
export default PasswordInput;
