import React, { FC } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { workbenchRoutes } from '../../../../../workbench/common/workbenchRoutes';
import { openFileNoCheck, WarningSize } from '../../../utils';
import { useAppDispatch } from '../../../../../store/store';
import { useSelector } from 'react-redux';
import { DeprecatedRootState } from '../../../../../store/state.type';
import { FiAlertTriangle } from 'react-icons/fi';
import SelectedDirs from '../../../../molecules/selected-dirs/SelectedDirs';
import filesize from 'filesize';
import { useShowWarningOpenFile } from '../../../../../store/workbench/state.types';
import { LinkWithQuery } from '../../../../atoms/link-with-query/LinkWithQuery';
import { contentPathToArray } from 'common/dist/utils/workbench/content';

export interface Props {}

const WarningOpenFileWizard: FC<Props> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const state = useSelector<DeprecatedRootState, DeprecatedRootState>(
    (state) => state
  );
  const showWarningOpenFile = useShowWarningOpenFile();
  if (showWarningOpenFile.path === undefined) {
    return <Redirect to={workbenchRoutes.basePath} />;
  }

  return (
    <div
      className={'dialog-container'}
      style={{
        paddingTop: '40px', // This wizard requires additional space in the top to look good
      }}
    >
      <div className={'dialog-inner-container'}>
        <p className={'dialog-headline'}>Open File</p>
        <div className={'step-container'}>
          <div className={'description-container'}>
            <div className={'bubble bubble-warn'}>
              <FiAlertTriangle className={'icon-x'} size={'20px'} />
            </div>
            <div className={'description'}>
              <div className={'title'}>
                Are you sure you want to open this large file (&gt;
                {filesize(WarningSize)})? It may lead to instability.
              </div>
              <SelectedDirs
                dirs={contentPathToArray(showWarningOpenFile.path)}
                highlightLastEntry
              />
            </div>
          </div>
        </div>
        <div className={'dialog-button-container'}>
          <LinkWithQuery
            to={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`}
          >
            <button
              type={'button'}
              className={'dialog-button dialog-button-neutral'}
            >
              Cancel
            </button>
          </LinkWithQuery>
          <LinkWithQuery
            to={`${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`}
            onClick={() => {
              openFileNoCheck(
                state,
                dispatch,
                history,
                showWarningOpenFile.path
              );
            }}
          >
            <button
              type={'button'}
              className={'dialog-button dialog-button-warn'}
            >
              Open
            </button>
          </LinkWithQuery>
        </div>
      </div>
    </div>
  );
};

export default WarningOpenFileWizard;
