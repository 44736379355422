import React, { FC } from 'react';

import { Handle, Position } from 'react-flow-renderer';
import Group from './Group';
import classNames from 'classnames';

import { GroupType } from 'common/dist/types/moduleVersion';

type Props = {
  data: GroupType;
};

const FlowElementGroup: FC<Props> = (props) => {
  const { data } = props;
  return (
    <div className={classNames('PipelineTuningChart--flow-element-group')}>
      <Handle type={'target'} position={Position.Left} />
      <Group data={data} />
      <Handle type={'source'} position={Position.Right} />
    </div>
  );
};

export default FlowElementGroup;
