import React, { Component, ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';

import './styles.scss';
import { RouteComponentProps } from 'react-router';
import classNames from 'classnames';

export type Button = {
  /** ID of the button */
  id: string;
  /** URL this button link refers to */
  to?: string | { pathname: string; search: string };
  /** Optional on click listener */
  onClick?: () => void;
  /** Optional icon for the button */
  icon?: ComponentType;
  /** ID of the intl button text */
  intlId: string;
  /** Default text for the button if the intl id can't be found */
  intlDefault: string;
  title?: string;
  disabled?: boolean;
};

export type Props = {
  /** List of buttons to show */
  buttons: Button[];
  /** Which of the buttons is active? */
  activeButton?: string;
  /** Component that will be shown in the same line on the right side of the buttons */
  RightComponent?: ComponentType;
  /** Center the component vertically? Otherwise it will be aligned top with the buttons */
  rightCenterY?: boolean;
  /** Move the component all to the right? */
  rightAlignRight?: boolean;
  /** Default: bubbles */
  design?: 'bubbles' | 'tabs';
  preserveSearchParamsOnLink?: boolean;
};

class TabLineBubbles extends Component<Props & RouteComponentProps> {
  static defaultProps = { design: 'bubbles' } as const;

  renderButton(button: Button) {
    const { activeButton, preserveSearchParamsOnLink, location } = this.props;
    const inner = (
      <div
        className={classNames('TabLineBubbles--button', {
          active: button.id === activeButton,
          inactive: button.id !== activeButton,
          disabled: button.disabled,
        })}
        title={button.title}
      >
        {button.icon && (
          <div className={'TabLineBubbles--icon'}>
            <button.icon />
          </div>
        )}
        <div
          className={'TabLineBubbles--text'}
          data-testingIdentifier={button.intlDefault}
        >
          <FormattedMessage
            id={button.intlId}
            defaultMessage={button.intlDefault}
          />
        </div>
      </div>
    );
    // Wrap in Link?
    if (button.to === undefined) {
      return (
        <div
          onClick={
            !button.disabled && button.onClick ? button.onClick : undefined
          }
          className={classNames('TabLineBubbles--cursor', {
            disabled: button.disabled,
          })}
          key={button.id}
        >
          {inner}
        </div>
      );
    } else {
      return (
        <Link
          to={
            // TODO decide what to to with preserveSearchParamsOnLink=true and button.to=object
            preserveSearchParamsOnLink && typeof button.to === 'string'
              ? { pathname: button.to, search: location.search }
              : button.to
          }
          onClick={
            !button.disabled && button.onClick ? button.onClick : undefined
          }
          style={{ textDecoration: 'none' }}
          key={button.id}
        >
          {inner}
        </Link>
      );
    }
  }

  render() {
    const { buttons, RightComponent, rightCenterY, rightAlignRight, design } =
      this.props;
    return (
      <div className={`TabLineBubbles design-${design}`}>
        <div className={'TabLineBubbles--buttons'}>
          {buttons && buttons.map((btn) => this.renderButton(btn))}
        </div>

        {RightComponent && (
          <div
            className={
              'TabLineBubbles--right' +
              (rightAlignRight ? ' align-right' : '') +
              (rightCenterY ? ' center-y' : '')
            }
          >
            <RightComponent />
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(TabLineBubbles);
