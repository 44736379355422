import React, { FC, useEffect } from 'react';
import { flattenUserResponses } from '../running-workbench/RunningWorkbench';
import Busy from '../../../atoms/busy/Busy';
import IndicatorEmpty from '../../../molecules/indicator-empty/IndicatorEmpty';
import { formatDate } from 'common/dist/utils/dates';
import { CProps } from './RunningApps.container';
import styles from '../styles.module.scss';
import Button from '../../../atoms/button/Button';
import { useUserSummaries } from '../../../../core/api/users';
import _ from 'lodash';

type Props = {};

type CombinedProps = Props & CProps;
const Loading: FC = () => {
  return (
    <div className={'resources-parent'}>
      <Busy isBusy />
    </div>
  );
};
const Empty: FC = () => {
  return (
    <IndicatorEmpty
      classNameImage={'resources-no-apps-pic'}
      headlineId={'no-id'}
      headlineDefault={'There are no running Apps.'}
    />
  );
};

const RunningApps: FC<CombinedProps> = ({
  fetchRunningApps,
  data,
  loading,
  error,
  stopApp,
  stoppingApps,
}) => {
  useEffect(() => {
    fetchRunningApps();
  }, []);

  const flattened = flattenUserResponses(data);
  const users = useUserSummaries(
    flattened.map((serverListEntry) => serverListEntry.userId)
  );

  if (loading) return <Loading />;
  else if (error)
    return (
      <div className={'resources-parent'}>
        <span className={'error'}>{JSON.stringify(error)}</span>
      </div>
    );
  else if (!data || flattenUserResponses(data).length === 0) return <Empty />;

  return (
    <div className={'resources-parent'}>
      <div className={'ct-list'}>
        <div className={'ct-headline ct-row'}>
          <div className={'ct-col ct-col-140px'}>
            <span>First Name</span>
          </div>
          <div className={'ct-col ct-col-140px'}>
            <span>Last Name</span>
          </div>
          <div className={'ct-col ct-col-280px'}>
            <span>User</span>
          </div>
          <div className={'ct-col ct-col-80px'}>
            <span>AppVersionCode</span>
          </div>
          <div className={'ct-col ct-col-160px'}>
            <span>Started</span>
          </div>
          <div className={'ct-col ct-col-160px'}>
            <span>Last Activity</span>
          </div>
          <div className={'ct-col ct-col-80px'}>
            <span>Ready</span>
          </div>
          <div className={'ct-col ct-col-flex-grow'}></div>
        </div>
        {_.zip(flattened, users).map(([e, user], i) => {
          const userSummary = user?.data;
          return (
            <div key={i} className={'ct-row'}>
              <div className={'ct-col ct-col-140px'}>
                <span>{userSummary?.firstName}</span>
              </div>
              <div className={'ct-col ct-col-140px'}>
                <span>{userSummary?.lastName}</span>
              </div>
              <div className={'ct-col ct-col-280px'}>
                <span>{e.userId}</span>
              </div>
              <div className={'ct-col ct-col-80px'}>
                <span>{e.appVersionCode}</span>
              </div>
              <div className={'ct-col ct-col-160px'}>
                <span>
                  {formatDate(new Date(e.created), 'dd.MM.yyyy HH:mm:ss')}
                </span>
              </div>
              <div className={'ct-col ct-col-160px'}>
                <span>
                  {formatDate(new Date(e.lastActivity), 'dd.MM.yyyy HH:mm:ss')}
                </span>
              </div>
              <div className={'ct-col ct-col-80px'}>
                <span>{e.ready + ''}</span>
              </div>
              <div className={'ct-col ct-col-flex-grow'}>
                {e.ready &&
                  (stoppingApps.findIndex(
                    (ra) =>
                      ra.userCode === e.userId &&
                      ra.appVersionCode === e.appVersionCode
                  ) >= 0 ? (
                    <Button
                      buttonColor={'orange'}
                      withLink={false}
                      buttonLabelDefault={'Stopping ...'}
                      disabled
                      additionalClassNames={[styles.stopWorkbenchButton]}
                    />
                  ) : (
                    <Button
                      buttonColor={'orange'}
                      withLink={false}
                      buttonLabelDefault={'Shutdown'}
                      additionalClassNames={[styles.stopWorkbenchButton]}
                      onClick={() => {
                        const appVersionCodeLower = e.appVersionCode
                          ? e.appVersionCode.toLowerCase()
                          : '';
                        stopApp(e.userId, appVersionCodeLower);
                      }}
                    />
                  ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RunningApps;
