import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { useContent } from '../../../../core/api/workbench/content';
import { defaultSidebarSize } from '../../../../core/constants/layout';
import { DeprecatedRootState } from '../../../../store/state.type';
import { JupyterContentElement } from '../../../../store/workbench/state.types';
import { err, ok, Result } from '../../../../utils';
import {
  ContentElement,
  ContentElementType,
} from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { computeFileTypes } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import RecycleBinFileBrowser from '../../../organisms/workbench-browser/recycle-bin-file-browser/RecycleBinFileBrowser';
import { RECYCLE_BIN_PATH } from '../../part-right/config';

const RecycleBin: FC = () => {
  const width =
    useSelector<DeprecatedRootState, number>(
      (state) => state.workbench.browserWidth
    ) || defaultSidebarSize;
  const { data, error, isError } = useContent(RECYCLE_BIN_PATH);
  const content = (data?.content as JupyterContentElement[]) ?? [];

  const selectedContent: Result<ContentElement[], any> = isError
    ? err(error)
    : ok(computeFileTypes(content));

  if (selectedContent.isOk()) {
    // Always add the "Directory Up" for the Recycle Bin
    selectedContent.value.unshift({
      name: '..',
      path: '',
      type: ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN,
    });
  }
  return <RecycleBinFileBrowser content={selectedContent} width={width} />;
};

export default RecycleBin;
