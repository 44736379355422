import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { DeepPartial } from 'react-hook-form';
import { ContactPoliciesErrorType } from './CampaignOptimizationContactPolicies';
import { CampaignOptimizationContactPoliciesV1AugurSettings as CampaignOptimizationContactPoliciesAugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationContactPoliciesV1AugurSettings';

export type { CampaignOptimizationContactPoliciesAugurSettings };

export type CampaignOptimizationContactPoliciesConfig = {};
export type ConfigCampaignOptimizationContactPoliciesConfig =
  Config<CampaignOptimizationContactPoliciesConfig>;

export type PartialCampaignOptimizationContactPoliciesAugurSettings =
  Partial<CampaignOptimizationContactPoliciesAugurSettings>;

export type CampaignOptimizationContactPoliciesValidationError =
  DeepPartial<ContactPoliciesErrorType>;

export const configAllowedInputsCampaignOptimizationContactPolicies: AllowedInputs<CampaignOptimizationContactPoliciesConfig> =
  {};

export const configTypeCampaignOptimizationContactPolicies = `{
}`;

export const defaultConfigCampaignOptimizationContactPolicies: ConfigCampaignOptimizationContactPoliciesConfig =
  {};

export const augurSettingsTypeCampaignOptimizationContactPolicies = `{
    id: string;
    name: string;
    description?: string | undefined;
    contactPolicyType: 'count';
    operator: Operator;
    value: string;
}[]`;

export const defaultAugurSettingsDefaultCampaignOptimizationContactPolicies: PartialCampaignOptimizationContactPoliciesAugurSettings =
  [];

// JSONSchemaType<CampaignOptimizationContactPoliciesAugurSettings>
export const defaultValidationSchemaCampaignOptimizationContactPolicies = {};
