import React, { FC } from 'react';
import JobGroupTopologyChart from '../job-group-topology-chart/JobGroupTopologyChart';
import IconButton from '../../../atoms/icon-button/IconButton';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { JobGroupType } from 'common/dist/types/job';
import { ToBeRefined } from 'common/dist/types/todo_type';
import { FiClock, FiPlay } from 'react-icons/fi';
import styles from './styles.module.scss';
import AvatarIconContainer from '../../../atoms/avatar-icon/AvatarIcon';
import { useHistory } from 'react-router-dom';
import { orchestrationRoutes } from '../../../orchestration/routes';
import { JOB_GROUP_VARIANTS } from '../../../orchestration/details/job-group-details/JobGroupDetails';
import classNames from 'classnames';
import orchestrationMessages from 'common/dist/messages/orchestration';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setDetailsOrigin } from '../../../../redux/modules/orchestration.jobdetails.module';

export interface Props {
  jobGroup: JobGroupType;
  jobGroupCode: string;
  names: ToBeRefined;
  runAction: () => void;
  editLink: string;
  createScheduleFromJobGroupLink: string;
  deleteAction: () => void;
}

const JobGroup: FC<Props> = (props) => {
  const {
    jobGroup,
    names: { augurNames, codeCapsuleNames },
    editLink,
    createScheduleFromJobGroupLink,
    deleteAction,
    runAction,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const intl = useIntl();

  return (
    <div
      className={classNames(styles.item, styles.jobGroup)}
      data-testingIdentifier={`JobGroupRow-${jobGroup.name}`}
      onClick={() => {
        dispatch(setDetailsOrigin(history.location.pathname));
        history.push(
          `${orchestrationRoutes.jobGroupDetails.path}/${JOB_GROUP_VARIANTS.jobTemplate}/${jobGroup.code}`
        );
      }}
    >
      <div
        className={styles.topology}
        style={{
          gridRow: '1',
          gridColumn: '1',
        }}
      >
        <JobGroupTopologyChart
          jobs={jobGroup.jobs}
          jobGroupTopology={jobGroup.jobGroupTopology}
          showJobStatus={false}
          augurNames={augurNames}
          codeCapsuleNames={codeCapsuleNames}
          slim
        />
      </div>
      <div
        className={styles.info}
        style={{
          gridRow: '1',
          gridColumn: '2',
        }}
      >
        <strong className={styles.field}>{jobGroup.name}</strong>
        <div className={styles.description}>
          <div className={styles.box} title={jobGroup.description || ''}>
            {jobGroup.description || ''}
          </div>
        </div>
      </div>
      <div
        className={styles.userIcon}
        style={{
          gridRow: '1',
          gridColumn: '3',
        }}
      >
        {jobGroup.createdBy && (
          <AvatarIconContainer userId={jobGroup.createdBy} />
        )}
      </div>
      <div
        className={styles.buttons}
        style={{
          gridRow: '1',
          gridColumn: '4',
        }}
      >
        <div
          className={styles.buttonsWrapper}
          onClick={(evt) => evt.stopPropagation()}
        >
          <IconButton
            data-testingIdentifier={'Run now'}
            Icon={FiPlay}
            size={18}
            onClick={runAction}
            title={intl.formatMessage(orchestrationMessages.jobGroupRun)}
          />
          <IconButton
            data-testingIdentifier={'Create Job Schedule'}
            Icon={FiClock}
            size={18}
            linkTo={createScheduleFromJobGroupLink}
            title={intl.formatMessage(
              orchestrationMessages.jobGroupCreateSchedule
            )}
          />
          <IconButton
            data-testingIdentifier={'Edit Job Group'}
            Icon={FiEdit}
            size={18}
            linkTo={editLink}
            title={intl.formatMessage(orchestrationMessages.jobGroupEdit)}
          />
          <IconButton
            data-testingIdentifier={'Delete Job Group'}
            Icon={FiTrash2}
            size={18}
            onClick={deleteAction}
            title={intl.formatMessage(orchestrationMessages.jobGroupDelete)}
          />
        </div>
      </div>
    </div>
  );
};

export default JobGroup;
