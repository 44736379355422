import { Point } from 'common/dist/types/reports';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import React, { FC, useState } from 'react';

import styles from './styles.module.scss';

type Props = {
  item: Point;
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
  position: number;
  colorScale: ScaleLinear<string, string>;
};

const Bar: FC<Props> = (props) => {
  const { xScale, yScale, item, position, colorScale } = props;

  const x = item[0];
  const y = item[1];

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const barPadding = 4;
  const x1 = xScale(x) || 0;

  const indexOfDot = y.toString().indexOf('.');
  const digitsAfterDot =
    indexOfDot === -1 ? 0 : y.toString().length - indexOfDot - 1;
  const toFixed =
    indexOfDot === -1 ? 0 : digitsAfterDot >= 2 ? 2 : digitsAfterDot;

  return (
    <>
      <rect
        cursor='pointer'
        rx={2}
        ry={2}
        className={styles.bar}
        fill={colorScale(y)}
        width={xScale.bandwidth()}
        height={yScale.range()[1] / yScale.domain()[0] - barPadding}
        x={xScale(x)}
        y={
          yScale(position) - yScale.range()[1] / yScale.domain()[0] + barPadding
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />

      {showTooltip && (
        <>
          <rect
            rx={2}
            ry={2}
            fill='#444'
            width={
              String(y.toFixed(toFixed)).length * 9 +
              (digitsAfterDot > 0 ? -2 : 4)
            }
            height={20}
            x={x1 + 2}
            y={yScale(position) + 2}
          ></rect>
          <text x={x1 + 4} y={yScale(position) + 16} fill='#eee'>
            {y.toFixed(toFixed)}
          </text>
        </>
      )}
    </>
  );
};

export default Bar;
