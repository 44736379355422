import { Point } from 'common/dist/types/reports';
import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import React, { FC } from 'react';

import Axis from './Axis';
import Bars from './Bars';
import styles from './styles.module.scss';
import { useDimensions } from '../../../../../../../utils';
import { buildSingleReportWrapper } from '../../../common/SingleReportWrapper';
import commonStyles from '../../../styles.module.scss';
import { ReportElementProps } from '../../../types/meta';
import { BarChartConfig, BarChartReportData } from '../type';

function getX(item: Point): number {
  return +item[0];
}

export type Props = BarChartReportData & BarChartConfig;

export const BarChart: FC<Props> = (props) => {
  const { data, xLabel, yLabel } = props;

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const margin = {
    top: 20,
    right: 15,
    bottom: xLabel ? 35 : 20,
    left: yLabel ? 50 : 35,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const yLabelPadding = 40;
  const xLabelPadding = 30;

  const translate = `translate(${margin.left}, ${margin.top})`;

  const yScale = scaleLinear().range([chartHeight, 0]);
  const xScale = scaleBand<number>().range([0, chartWidth]).paddingInner(0.2);
  const xTickScale = data
    ? scaleLinear()
        .range([0, chartWidth])
        .domain([min(data, getX), max(data, getX)])
    : undefined;

  // --- data available
  return (
    <div ref={ref} className={commonStyles.container}>
      <svg className={commonStyles.chart}>
        <g transform={translate}>
          <Bars data={data} xScale={xScale} yScale={yScale} />
          <Axis
            ticks={3}
            scale={yScale}
            tickSize={chartWidth}
            helplineType={'dashed'}
            direction={'left'}
          />
          <g transform={`translate(0,${chartHeight})`}>
            <Axis
              scale={xTickScale}
              ticks={6}
              tickSize={chartHeight}
              direction={'bottom'}
            />
          </g>
          {xLabel && (
            <text
              className={styles.axisLabel}
              x={chartWidth / 2}
              y={chartHeight + xLabelPadding}
              textAnchor={'middle'}
            >
              {xLabel}
            </text>
          )}
          {yLabel && (
            <g transform={`translate(${-yLabelPadding},${chartHeight / 2})`}>
              <text
                className={styles.axisLabel}
                textAnchor={'middle'}
                transform={`rotate(-90)`}
              >
                {yLabel}
              </text>
            </g>
          )}
        </g>
      </svg>
    </div>
  );
};

export const BarChartSingle: FC<
  ReportElementProps<BarChartReportData, BarChartConfig>
> = buildSingleReportWrapper<BarChartReportData, BarChartConfig>(BarChart);
