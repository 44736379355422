import {
  Communication,
  CommunicationGroup,
} from 'common/dist/types/module.optimization';
import { CampaignOptimizationCommunicationGroupsV1AugurSettings as CampaignOptimizationCommunicationGroupsV1AugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationCommunicationGroupsV1AugurSettings';
import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { DeepPartial } from 'react-hook-form';
import { CommunicationGroupsErrorType } from './CampaignOptimizationCommunicationGroups';

export type { CampaignOptimizationCommunicationGroupsV1AugurSettings };

export type CampaignOptimizationCommunicationGroupsConfig = {
  communications: Communication[];
};
export type ConfigCampaignOptimizationCommunicationGroupsConfig =
  Config<CampaignOptimizationCommunicationGroupsConfig>;

export type CampaignOptimizationCommunicationGroupsAugurSettings =
  CommunicationGroup[];
export type PartialCampaignOptimizationCommunicationGroupsAugurSettings =
  Partial<CampaignOptimizationCommunicationGroupsAugurSettings>;

export type CampaignOptimizationCommunicationGroupsValidationError =
  DeepPartial<CommunicationGroupsErrorType>;

export const configAllowedInputsCampaignOptimizationCommunicationGroups: AllowedInputs<CampaignOptimizationCommunicationGroupsConfig> =
  {
    communications: [
      {
        type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_COMMUNICATIONS,
        version: 'v1',
      },
    ],
  };

export const configTypeCampaignOptimizationCommunicationGroups = `{
  communications: { source: 'input-element', elementUuid: string },
}`;

export const defaultConfigCampaignOptimizationCommunicationGroups: ConfigCampaignOptimizationCommunicationGroupsConfig =
  {
    communications: { source: 'input-element', elementUuid: '' },
  };

export const augurSettingsTypeCampaignOptimizationCommunicationGroups = `{
  id: string;
  name: string;
  description?: string;
  communicationIds: string[];
}[]`;

export const defaultAugurSettingsDefaultCampaignOptimizationCommunicationGroups: PartialCampaignOptimizationCommunicationGroupsAugurSettings =
  [];

// JSONSchemaType<CampaignOptimizationCommunicationGroupsAugurSettings>
export const defaultValidationSchemaCampaignOptimizationCommunicationGroups = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: {
        type: 'string',
      },
      name: {
        type: 'string',
      },
      description: {
        type: 'string',
      },
      communicationIds: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
    },
    required: ['id', 'name', 'communicationIds'],
  },
};
