import React, { FC, useEffect } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { FiCheck } from 'react-icons/fi';
import { fieldPath, validatePath } from './cloneRepository.form';
import { err, ok, Result } from '../../../../utils';
import { prepareContent } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser.utils';
import CloneRepositoryFileBrowser from '../../../organisms/workbench-browser/clone-repository-file-browser/CloneRepositoryFileBrowser';
import { useContent } from '../../../../core/api/workbench/content';
import { ContentElement } from '../../../organisms/workbench-browser/generic-file-browser/GenericFileBrowser';
import { useSelector } from 'react-redux';
import { defaultSidebarSize } from '../../../../core/constants/layout';
import { useSelectedDirPath } from '../../../workbench/hooks';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import { JupyterContentElement } from '../../../../store/workbench/state.types';
import { DeprecatedRootState } from '../../../../store/state.type';

export const StepPath: FC = () => (
  <Field
    name={fieldPath}
    label={fieldPath}
    component={PathSelectFieldComponent}
    validate={[validatePath]}
  />
);

const PathSelectFieldComponent: FC<WrappedFieldProps> = ({
  meta,
  input: { onChange },
}) => {
  const selectedDirPath = useSelectedDirPath();
  const width =
    useSelector<DeprecatedRootState, number>(
      (state) => state.workbench.browserWidth
    ) || defaultSidebarSize;

  const { data, error, isError } = useContent(
    contentArrayToPath(selectedDirPath, false)
  );
  const content = (data?.content ?? []) as JupyterContentElement[];

  useEffect(() => {
    onChange(selectedDirPath);
  }, [onChange, selectedDirPath]);

  const selectedContent: Result<ContentElement[], any> = isError
    ? err(error)
    : ok(prepareContent(content, selectedDirPath));

  return (
    <div className={'step-container step-1-container'}>
      <div className={'description-container'}>
        {!meta.valid ? (
          <div className={'bubble bubble-info'}>
            <p>1</p>
          </div>
        ) : (
          <div className={'bubble bubble-ok'}>
            <FiCheck className={'checked-icon'} size={'20px'} />
          </div>
        )}
        <div className={'description'}>
          <div className={'title'}>
            <p>Select Path</p>
          </div>
          <div className={'text'}>
            <p>Select a path to clone the repository into.</p>
          </div>
        </div>
      </div>
      <div
        className={`input-container${
          meta.error ? ' input-container-error' : ''
        }`}
        style={{
          minHeight: '300px',
          maxHeight: '600px',
        }}
      >
        <div className={'error-container'}>
          {meta.error && <p className={'error'}>{meta.error}</p>}
        </div>

        <CloneRepositoryFileBrowser
          content={selectedContent}
          width={width}
          path={selectedDirPath}
        />
      </div>
    </div>
  );
};

export default StepPath;
