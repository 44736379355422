import { UseMutationResult } from '@tanstack/react-query';
import msgsForm from 'common/dist/messages/form';
import moduleMsgs from 'common/dist/messages/modules';
import notificationMsgs from 'common/dist/messages/notifications';
import { ModuleWithAllRelations } from 'common/dist/types/module';
import { User } from 'common/dist/types/users';
import React, { FC, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BubbleStep } from './BubbleStep';
import { ModuleIcon } from './ModuleIcon';
import styles from './styles.module.scss';
import { validateModuleName, validateSlug } from './utils';
import {
  useAddModule,
  useEditModule,
  useModuleByCode,
  useModules,
} from '../../../core/api/modules';
import * as NOTIFICATION_TYPES from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { RootState, useAppDispatch } from '../../../store/store';
import { DropdownSelectInput } from '../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlTextInputLine } from '../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import {
  moduleDetailsLink,
  overviewModulesLink,
} from '../../collaborationSpace/routes';
import Wizard from '../wizard/Wizard';

export type ModuleFormData = ModuleWithAllRelations & {
  templateId?: OptionType;
};

type OptionType = {
  label: string;
  value: number;
};

const NewModuleWizard: FC<Partial<ModuleFormData>> = (props) => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { code, templateId } = props;
  const isEdit = Boolean(code);
  const module: ModuleWithAllRelations = useModuleByCode(code, isEdit).data;
  const templates = useModules(
    {
      has_repository: 'true',
    },
    !isEdit
  );

  const options: OptionType[] = (templates.data || []).map((template) => ({
    label: `${template.name} (${template.code})`,
    value: template.repository.id,
  }));
  //First we need to get the unique identifier for the base template
  const templateRepoId = (templates?.data || []).find(
    (x) => x.name === 'Base Template'
  )?.repository?.id;
  //Find the correct option
  const defaultTemplateId =
    templateId ?? options.find((x) => x.value === templateRepoId);

  const methods = useForm<ModuleFormData>({
    mode: 'all',
    defaultValues: { templateId: defaultTemplateId, ...module },
    values: { templateId: defaultTemplateId, ...module }
  });
  
  const {
    formState: { isValid, isSubmitSuccessful, isSubmitting, dirtyFields },
    control,
  } = methods;
  // https://github.com/react-hook-form/react-hook-form/issues/4740
  const altIsDirty = Object.keys(dirtyFields).length > 0;

  if (isSubmitSuccessful && !code) {
    history.push(overviewModulesLink());
  } else if (isSubmitSuccessful && code) {
    history.push(moduleDetailsLink(module.code));
  }
  const displayToSlug = (display: string) =>
    display
      .trim()
      .toLowerCase()
      .replaceAll(/[^a-z0-9-]/g, '-')
      .replace(/-+/g, '-')
      .replace(/(^-)|(-$)/, '');
  const [isSlugUnmodified, setIsSlugUnmodified] = useState(true);

  const user = useSelector<RootState, User>((state) => state.currentUser);

  const editHook = useEditModule(code);
  const addHook = useAddModule();
  const moduleMutation: UseMutationResult<
    unknown,
    unknown,
    ModuleFormData | ModuleWithAllRelations
  > = isEdit ? editHook : addHook;

  const onSubmit = async (data: ModuleFormData) => {
    if (!data?.moduleAvatar?.imageData) data.moduleAvatar = undefined;
    await moduleMutation
      .mutateAsync(data)
      .then(() => {
        if (isEdit) {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleUpdateSuccess.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleUpdateSuccess.defaultMessage,
              NOTIFICATION_TYPES.event
            )
          );
        } else {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleAddSuccess.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleAddSuccess.defaultMessage,
              NOTIFICATION_TYPES.event
            )
          );
        }
      })
      .catch((e) => {
        if (isEdit) {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleUpdateFailure.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleUpdateFailure.defaultMessage,
              NOTIFICATION_TYPES.error
            )
          );
        } else {
          dispatch(
            sendNotification(
              notificationMsgs.msgTitleModuleAddFailure.defaultMessage,
              // @ts-ignore
              notificationMsgs.msgDescriptionModuleAddFailure.defaultMessage,
              NOTIFICATION_TYPES.error
            )
          );
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        style={{ height: '100%' }}
      >
        <Wizard
          withHeadline={true}
          headlineId={
            isEdit
              ? moduleMsgs.moduleWizardHeadlineEdit.id
              : moduleMsgs.moduleWizardHeadline.id
          }
          headlineDefault={
            isEdit
              ? moduleMsgs.moduleWizardHeadlineEdit.defaultMessage
              : moduleMsgs.moduleWizardHeadline.defaultMessage
          }
          buttons={[
            {
              withLink: false,
              buttonColor: 'white',
              label: msgsForm.cancel,
              onClick: () => {
                history.goBack();
              },
            },
            {
              withLink: false,
              buttonColor: 'secondary',
              isSubmitButton: true,
              label: msgsForm.submit,
              disabled: !altIsDirty || !isValid,
              isBusy: isSubmitting,
            },
          ]}
        >
          <BubbleStep
            title={moduleMsgs.moduleWizardModuleNameStepTitle}
            description={moduleMsgs.moduleWizardModuleNameStepDescription}
            stepNumber={1}
            fieldNames={['name', !code ? 'repository.repoName' : '']}
          >
            <Controller
              name={'name'}
              rules={{
                required: 'Please enter a name for the module.',
                minLength: {
                  value: 3,
                  message: 'The name must be at least 3 characters long.',
                },
                maxLength: {
                  value: 64,
                  message: '64 characters are the maximum length.',
                },
                validate: (name: string) => {
                  // name unchanged in edit mode
                  if (isEdit && name === module.name) return true;

                  return validateModuleName(name);
                },
              }}
              control={control}
              render={({ field, fieldState }) => {
                const { ref, onChange, ...rest } = field; // extract ref to pass as inputRef
                return (
                  <IntlTextInputLine
                    label={'Enter Module Name'}
                    placeholder={'Enter Module Name'}
                    isCollapsible={false}
                    initialCollapsed={false}
                    {...rest}
                    {...fieldState}
                    // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                    isTouched={true}
                    inputRef={ref}
                    error={fieldState.error?.message}
                    // custom onChange to update slug
                    onChange={(e) => {
                      onChange(e);
                      methods.setValue(
                        'repository.repoName',
                        isSlugUnmodified
                          ? displayToSlug(e.target.value)
                          : methods.getValues('repository.repoName'),
                        { shouldDirty: true, shouldValidate: true }
                      );
                    }}
                  />
                );
              }}
            />
            {!code && (
              <div className={styles.container}>
                <div className={styles.group}>
                  <span>
                    {user?.firstName}&nbsp;{user?.lastName}
                  </span>
                  <span>/</span>
                </div>

                <Controller
                  name={'repository.repoName'}
                  rules={{
                    required: 'Please enter a slug for the module.',
                    minLength: {
                      value: 3,
                      message: 'The slug must be at least 3 characters long.',
                    },
                    maxLength: {
                      value: 64,
                      message: '64 characters are the maximum length.',
                    },
                    validate: validateSlug,
                  }}
                  control={control}
                  render={({ field, fieldState }) => {
                    const { ref, onChange, ...rest } = field; // extract ref to pass as inputRef
                    return (
                      <IntlTextInputLine
                        placeholder={'Enter Repository Slug'}
                        isCollapsible={false}
                        initialCollapsed={false}
                        {...rest}
                        {...fieldState}
                        // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                        isTouched={true}
                        inputRef={ref}
                        error={fieldState.error?.message}
                        // custom onChange to update slug
                        onChange={(e) => {
                          setIsSlugUnmodified(
                            (e.target.value ?? '').length === 0
                          );
                          onChange(e);
                        }}
                      />
                    );
                  }}
                />
              </div>
            )}
          </BubbleStep>
          <BubbleStep
            title={moduleMsgs.moduleWizardModuleDescriptionStepTitle}
            description={
              moduleMsgs.moduleWizardModuleDescriptionStepDescription
            }
            stepNumber={2}
            fieldName={'description'}
          >
            <Controller
              name={'description'}
              control={control}
              rules={{
                maxLength: {
                  value: 128,
                  message: '128 characters are the maximum length.',
                },
              }}
              render={({ field, fieldState }) => {
                const { ref, ...rest } = field; // extract ref to pass as inputRef
                return (
                  <IntlTextInputLine
                    label={'Enter a short Description'}
                    placeholder={'Enter a short description for the module'}
                    isCollapsible={false}
                    initialCollapsed={false}
                    {...rest}
                    {...fieldState}
                    // we want to always show the errors (especially after clicking on existing elements, and it is acceptable for new elements)
                    isTouched={true}
                    inputRef={ref}
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          </BubbleStep>
          <BubbleStep
            title={moduleMsgs.moduleWizardModuleUploadIconStepTitle}
            description={moduleMsgs.moduleWizardModuleUploadIconStepDescription}
            stepNumber={3}
            fieldName={'moduleAvatar'}
          >
            {/*Show Icon Upload*/}
            {module ? <ModuleIcon {...module.moduleAvatar} /> : <ModuleIcon />}
          </BubbleStep>
          {!code && (
            <BubbleStep
              title={moduleMsgs.moduleWizardModuleTemplateStepTitle}
              description={moduleMsgs.moduleWizardModuleTemplateStepDescription}
              stepNumber={4}
              fieldName={'templateId'}
            >
              {/*Show Template Dropdown*/}
              <Controller
                name={'templateId'}
                control={control}
                render={({ field, fieldState, formState }) => {
                  const { ref, ...rest } = field; // extract ref to pass as inputRef
                  return (
                    <DropdownSelectInput<OptionType>
                      label={'Select a template'}
                      placeholder={'Template'}
                      isClearable={true}
                      options={options}
                      {...rest}
                      {...fieldState}
                      {...formState}
                    />
                  );
                }}
              />
            </BubbleStep>
          )}
        </Wizard>
      </form>
    </FormProvider>
  );
};

export default NewModuleWizard;
