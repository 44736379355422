import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { Component, RefObject } from 'react';
import AceEditor from 'react-ace';
import ReactAce from 'react-ace';
import { HotKeys } from 'react-hotkeys';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'remark-rehype';

import ButtonBar from './ButtonBar.container';
import styles from './styles.module.scss';
import CloseConfirm from '../../../part-right/editor/close-confirm/CloseConfirm.container';

export type Props = {
  path: string;
  content: ToBeRefined;
  unsavedChanges?: boolean;
  saveNotebook: ToBeRefined;
  changeCodeContent: ToBeRefined;
  showCloseConfirm?: boolean;
  paneId: string;
};
export type State = {
  tab: string;
};

export default class MarkdownContent extends Component<Props, State> {
  // Filled in a callback of the <AceEditor/>
  editorRef: RefObject<ReactAce>;
  keyMap = {
    save: 'command+s',
  };
  keyHandlers = {
    save: (e) => {
      this.props.saveNotebook(this.props.path, this.props.content, 'file');
      e.preventDefault();
    },
  };

  constructor(props) {
    super(props);
    this.state = { tab: 'edit' }; // Switch between 'rendered' and 'edit'
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    if (
      this.editorRef &&
      document.activeElement !== this.editorRef.current.editor
    ) {
      this.editorRef.current.editor.focus();
    }
  }

  /**
   * Render the "edit" mode for the Markdown editor
   * @returns {JSX.Element}
   */
  renderEdit() {
    const { path, content, changeCodeContent } = this.props;
    return (
      <div className={'editor-con'}>
        <AceEditor
          ref={this.editorRef}
          width={'100%'}
          className={'code-cell-editor'}
          mode=''
          theme='tomorrow'
          onChange={(content) => {
            changeCodeContent({
              path,
              content,
            });
          }}
          showGutter
          highlightActiveLine
          value={content}
          setOptions={{
            maxLines: Infinity,
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
            autoScrollEditorIntoView: true,
          }}
          editorProps={{ $blockScrolling: Infinity }}
          readOnly={false}
        />
      </div>
    );
  }

  renderRendered() {
    const { content } = this.props;

    const sourceSafe = Array.isArray(content) ? content.join('') : content;
    let sourceWithDefault: string;
    if (typeof sourceSafe === 'string') {
      sourceWithDefault = sourceSafe && sourceSafe.trim() ? sourceSafe : '';
    }

    return (
      <div className={'markdown-con'}>
        <div className={'main-container'}>
          <ReactMarkdown
            skipHtml={false}
            rehypePlugins={[rehypeRaw]}
            className={styles.MarkdownContainer}
          >
            {sourceWithDefault}
          </ReactMarkdown>
        </div>
      </div>
    );
  }

  render() {
    const { path, content, unsavedChanges, showCloseConfirm, paneId } =
      this.props;

    // Is the close confirm supposed to be shown?
    if (showCloseConfirm) {
      return <CloseConfirm path={path} content={content} paneId={paneId} />;
    }

    return (
      <div className={'code-content'}>
        <HotKeys
          className={'hotkeys'}
          keyMap={this.keyMap}
          handlers={this.keyHandlers}
        >
          <ButtonBar
            path={path}
            content={content}
            unsavedChanges={unsavedChanges}
            selectedTab={this.state.tab}
            onTabSelect={(tab) => {
              this.setState({ tab });
            }}
            paneId={paneId}
          />
          <div className={'editor-parent-container'}>
            {this.state.tab === 'rendered'
              ? this.renderRendered()
              : this.renderEdit()}
          </div>
        </HotKeys>
      </div>
    );
  }
}
