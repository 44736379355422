import { CodeCapsuleVersion } from 'common/dist/types/codeCapsule';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

import Busy from '../../../atoms/busy/Busy';
import {
  FormattedDateTime,
  FormattedRelativeTimeConvenient,
} from '../../../atoms/formatted-date-time/FormattedDateTime';
import { AugurDetailsRouteParams } from '../../../modelManagement/modules/common/augur-details-tabs/types';
import { orchestrationRoutes } from '../../../orchestration/routes';

interface Props {
  /** repositoryCode = identifier for the code capsule */
  codeCapsuleCode: string;
  /** code of the habitat the code capsule is affiliated to */
  habitatCode: string;
  loading?: boolean;
  loaded?: boolean;
  error?: string;
  data?: {
    jobCode?: string;
    createdAt?: string;
    executedNotebooks?: string[];
    status?: string;
    repoCode?: string; // "success" / "failure"
    codeCapsuleVersion?: CodeCapsuleVersion;
  }[];
  /** injected by react-router-dom withRouter() */
  history?: object;

  fetchCodeCapsuleExecutions(...args: unknown[]): unknown;
}

export default class Executions extends Component<
  Props & RouteComponentProps<AugurDetailsRouteParams>
> {
  static defaultProps = {
    loading: false,
    loaded: false,
  };
  constructor(props) {
    super(props);
    this.renderError = this.renderError.bind(this);
    this.renderLoaded = this.renderLoaded.bind(this);
  }

  componentDidMount() {
    const { fetchCodeCapsuleExecutions, habitatCode, codeCapsuleCode } =
      this.props;

    fetchCodeCapsuleExecutions(codeCapsuleCode, habitatCode);
  }

  renderLoading() {
    return (
      <div className={'code-capsule-container executions-container'}>
        <Busy isBusy />
      </div>
    );
  }

  renderEmpty() {
    return (
      <div className={'code-capsule-container executions-container'}>
        <div className={'executions-empty'}>
          <div className={'executions-empty-elements'}>
            <div className={'executions-empty-pic'}>&nbsp;</div>
            <span className={'headline'}>
              There are no Executions for this Code Capsule yet
            </span>
            <span className={'description'}>
              Once this Code Capsule was executed, the results will be listed
              here
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderLoaded() {
    const { data, history } = this.props;
    return (
      <div className={'code-capsule-container executions-container'}>
        <div className={'code-capsules custom-table'}>
          <div className={'ct-row ct-headline'}>
            <div
              className={'ct-col ct-col-200px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Version Number</span>
            </div>
            <div
              className={'ct-col ct-col-130px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Status</span>
            </div>
            <div
              className={'ct-col ct-col-200px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Finished At</span>
            </div>
            <div
              className={'ct-col ct-col-130px'}
              style={{
                justifyContent: 'start',
                display: 'flex',
                alignItems: 'center',
                height: '100%',
                margin: 'auto',
              }}
            >
              <span>Job Details</span>
            </div>
          </div>

          {data &&
            data.map((execution) => (
              <div className={'ct-row'}>
                <div
                  className={'ct-col ct-col-200px'}
                  style={{
                    justifyContent: 'start',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <div className={'ct-value'}>
                    <span>{execution.codeCapsuleVersion.number}</span>
                  </div>
                </div>

                <div
                  className={'ct-col ct-col-130px'}
                  style={{
                    justifyContent: 'start',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <div className={'ct-value'}>
                    <span>{execution.status}</span>
                  </div>
                </div>
                <div
                  className={'ct-col ct-col-200px'}
                  style={{
                    justifyContent: 'start',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <div
                    className={'ct-value'}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <>
                      <strong
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <FormattedRelativeTimeConvenient
                          date={new Date(execution.createdAt)}
                          tooltip={false}
                        />
                      </strong>
                      <span
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <FormattedDateTime
                          date={new Date(execution.createdAt)}
                        />
                      </span>
                    </>
                  </div>
                </div>

                <div
                  className={'ct-col ct-col-130px'}
                  style={{
                    justifyContent: 'start',
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    margin: 'auto',
                  }}
                >
                  <div className={'ct-value executions-link'}>
                    <span
                      onClick={() =>
                        history.push(
                          `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobDetails.path}/${execution.jobCode}`
                        )
                      }
                    >
                      Open Details
                    </span>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  renderError() {
    const { error } = this.props;
    return (
      <div className={'code-capsule-container executions-container'}>
        <span className={'error'}>{error}</span>
      </div>
    );
  }

  render() {
    const { loaded, loading, error, data } = this.props;
    if (loaded) {
      if (!data || data.length === 0) {
        return this.renderEmpty();
      } else {
        return this.renderLoaded();
      }
    } else if (error) return this.renderError();
    else if (loading) return this.renderLoading();
    else return <div />;
  }
}
