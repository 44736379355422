import { NodeType } from 'common/dist/types/moduleVersion';
import {
  PipelineTuningValue,
  PipelineTuningValueNodeOrGroup,
} from 'common/dist/types/pipeline';
import _ from 'lodash';
import React, { FC, useState } from 'react';

import NodeEditor from './node-editor/NodeEditor';
import PipelineTuningChart from '../pipeline-tuning-chart/PipelineTuningChart';
import { PipelineTuningErrorType } from '../PipelineTuning';
import { PipelineTuningAugurSettings, PipelineTuningSchemaType } from '../type';

type Props = {
  /** The pipeline schema, that defines the structure, which values are valid, and so on. This object does not contain
   * the actual values entered into the input fields. This information comes in the "value" prop. */
  pipeline: PipelineTuningSchemaType;
  /** The default values of the pipeline (used for the "reset to defaults" button) */
  defaultValues: PipelineTuningValue;
  value: PipelineTuningAugurSettings;
  pipelineIndex: number;
  pipelineValue: PipelineTuningValue;
  onChange: (...event: unknown[]) => void;
  error?: PipelineTuningErrorType;
};

const getNodesValuesMap = (nodes: PipelineTuningValueNodeOrGroup[]) => {
  return _.keyBy(
    (nodes || []).flatMap((n) => {
      if (n.type === 'node') return [n];
      else if (n.type === 'group') return n.nodes;
      else return undefined;
    }),
    'id'
  );
};

const Pipeline: FC<Props> = (props: Props) => {
  const { pipeline, value, onChange, error, pipelineValue, pipelineIndex } =
    props;

  const [selectedNode, onSelectingNode] = useState<NodeType | undefined>(
    undefined
  );
  const nodesValuesMap = getNodesValuesMap(pipelineValue?.nodes);

  const inactiveNodeIds = (pipelineValue?.nodes || []).flatMap((node) => {
    if (
      node.type === 'node' &&
      Object.keys(node).includes('isActive') &&
      !node.isActive
    ) {
      return [node.id];
    } else if (node.type === 'group') {
      return node.nodes
        .filter((n) => Object.keys(n).includes('isActive') && !n.isActive)
        .map((n) => n.id);
    } else {
      return [] as string[];
    }
  });

  return (
    <div className={'PipelineTuningSelection--pipeline-container'}>
      <PipelineTuningChart
        pipeline={pipeline}
        pipelineIndex={pipelineIndex}
        onSelectingNode={onSelectingNode}
        inactiveNodeIds={inactiveNodeIds}
        error={error}
      />
      {selectedNode && (
        <NodeEditor
          value={value}
          pipelineIndex={pipelineIndex}
          selectedNode={selectedNode}
          nodeValue={nodesValuesMap[selectedNode?.id]}
          onChange={onChange}
          error={error}
        />
      )}
    </div>
  );
};

export default Pipeline;
