import { REPO_TYPE, RepoType } from 'common/dist/types/repository';
import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import styles from './styles.module.scss';
import Button from '../../atoms/button/Button';
import {
  repositoryBuildAppLink,
  repositoryBuildCodeCapsuleLink,
  repositoryCloneLink2,
} from '../../collaborationSpace/routes';

type Props = {
  repositoryType?: RepoType;
};

const RepositoryButtonBar: FC<Props> = ({ repositoryType }) => {
  const {
    params: { group, repositoryName },
  } = useRouteMatch<{ group: string; repositoryName: string }>();

  if (repositoryType === REPO_TYPE.MODULE) return null; // No buttons for Module repositories in this screen

  return (
    <div className={styles.buttonContainer}>
      {repositoryType === REPO_TYPE.CODE_CAPSULE && (
        <Button
          buttonColor={'primary'}
          withLink
          buttonLabelDefault={'Build Code Capsule'}
          linkTo={repositoryBuildCodeCapsuleLink(group, repositoryName)}
        />
      )}
      {repositoryType === REPO_TYPE.APP && (
        <Button
          buttonColor={'primary'}
          withLink
          buttonLabelDefault={'Build App'}
          linkTo={repositoryBuildAppLink(group, repositoryName)}
        />
      )}

      <Button
        buttonColor={'secondary'}
        withLink
        buttonLabelDefault={'Clone Repository'}
        linkTo={repositoryCloneLink2(group, repositoryName)}
      />
    </div>
  );
};

export default RepositoryButtonBar;
