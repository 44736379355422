// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/heatmap/v1/type.ts' --type 'ConfigHeatmapConfig'
export const configSchemaHeatmap = {
  $ref: '#/definitions/ConfigHeatmapConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<HeatmapConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigHeatmapConfig: {
      $ref: '#/definitions/Config%3CHeatmapConfig%3E',
    },
  },
};
