import classNames from 'classnames';
import {
  Communication,
  CommunicationGroup,
} from 'common/dist/types/module.optimization';
import React, { FC } from 'react';
import { FiMinus } from 'react-icons/fi';

import styles from './styles.module.scss';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import DescriptionOption, {
  DescriptionOptionType,
} from '../../../../../organisms/modelManagement/newAugurWizard/commonComponents/descriptionOption/DescriptionOption';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';
import { CommunicationGroupsErrorType } from './CampaignOptimizationCommunicationGroups';
import { DeepPartial } from 'react-hook-form';

type Props = {
  /** List of communications defined in a previous wizard step */
  communications: Communication[];
  rowIndex?: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: CommunicationGroup) => void;
  value: CommunicationGroup[];
  invalid?: boolean;
  onBlur?: React.FocusEventHandler;
  onChange?: (updatedCommunicationGroups: CommunicationGroup[]) => void;
  error?: DeepPartial<CommunicationGroupsErrorType>;
};
const drvNewValue = (
  value: CommunicationGroup[],
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...value.slice(0, rowIndex),
    {
      ...value[rowIndex],
      [key]: eventValue,
    },
    ...value.slice(rowIndex + 1),
  ];
};
const renderNameField = (props: Props) => {
  const { rowIndex, value, invalid, onChange, onBlur, error, constraintId } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      invalid={invalid}
      error={error?.rows?.[constraintId]?.name}
      label={'Name'}
      placeholder={'Name'}
      value={value[rowIndex || 0]?.name}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'name',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const renderDescriptionField = (props: Props) => {
  const { rowIndex, value, invalid, onChange, onBlur, error, constraintId } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      invalid={invalid}
      error={error?.rows?.[constraintId]?.description}
      label={'Description (Optional)'}
      placeholder={'Description'}
      value={value[rowIndex || 0]?.description}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'description',
          rowIndex || 0
        );
        if (onChange) {
          onChange(newValue);
        }
      }}
      onBlur={onBlur}
    />
  );
};

const renderCommunicationsField = (props: Props) => {
  const { communications, rowIndex, value, onBlur, onChange, error, constraintId } = props;

  const communicationsOptions: DescriptionOptionType[] = (
    communications || []
  ).map((communication) => ({
    value: communication.id,
    label: communication.name,
    description: communication.description,
  }));

  return (
    <DropdownSelectInput<DescriptionOptionType, true>
      id={'optCommunicationGroups_channel'}
      label={'Communications'}
      placeholder={'Communications'}
      options={communicationsOptions}
      onBlur={onBlur}
      onChange={(options) => {
        const newValue: CommunicationGroup[] = [
          ...value.slice(0, rowIndex),
          {
            ...value[rowIndex || 0],
            communicationIds: options.map((o) => o.value),
          },
          ...value.slice((rowIndex || 0) + 1),
        ];
        if (onChange) {
          onChange(newValue);
        }
      }}
      value={communicationsOptions.filter((o) =>
        (value[rowIndex || 0].communicationIds || []).includes(o.value)
      )}
      customComponents={{
        Option: DescriptionOption<true>,
      }}
      isMulti={true}
      appearance={'multiLines'}
      error={error?.rows?.[constraintId]?.communicationIds}
      isTouched={true}
    />
  );
};

const AddCommunicationGroup: FC<Props> = (props) => {
  const { rowIndex, removableRows, onRemoveRow, value } = props;

  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(value[rowIndex || 0])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div
          className={classNames(
            commonStyles.addElementField,
            styles.communicationsField
          )}
        >
          {renderCommunicationsField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddCommunicationGroup;
