import React, { FC } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { FiMinus, FiPlus } from 'react-icons/fi';

import styles from './styles.module.scss';
import { AugurSettingsForm } from '../../../pages/augur/utils/augurSettings.form';
import IconButton from '../../icon-button/IconButton';
import { IntlTextInputLine } from '../text-input-line/TextInputLine';

export const AttributesInput: FC = () => {
  const { control } = useFormContext<AugurSettingsForm>();
  const { fields, remove, append } = useFieldArray({
    control: control,
    name: 'general.attributes',
  });

  return (
    <div className={styles.container}>
      {fields.map((field, index) => {
        return (
          <div className={styles.row} key={field.id}>
            <IconButton
              Icon={FiMinus}
              size={20}
              rounded={true}
              onClick={() => remove(index)}
            />
            <div className={styles.field}>
              <Controller
                name={`general.attributes.${index}.key`}
                control={control}
                render={({ field, fieldState }) => {
                  const { ref, ...rest } = field; // extract ref to pass as inputRef
                  return (
                    <IntlTextInputLine
                      placeholder={'key'}
                      {...rest}
                      {...fieldState}
                      inputRef={ref}
                      error={fieldState.error?.message}
                    />
                  );
                }}
              />
            </div>
            <div className={styles.field}>
              <Controller
                name={`general.attributes.${index}.value`}
                control={control}
                render={({ field, fieldState }) => {
                  const { ref, ...rest } = field; // extract ref to pass as inputRef
                  return (
                    <IntlTextInputLine
                      placeholder={'value'}
                      {...rest}
                      {...fieldState}
                      inputRef={ref}
                      error={fieldState.error?.message}
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}

      <div className={styles.addButton}>
        <IconButton
          size={20}
          rounded={true}
          Icon={FiPlus}
          onClick={() =>
            append({
              key: '',
              value: '',
            })
          }
        />
        <span className={styles.addButtonText}>Add parameter</span>
      </div>
    </div>
  );
};

export default AttributesInput;
