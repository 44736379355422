import { useQueryClient } from '@tanstack/react-query';
import msgsNotifications from 'common/dist/messages/notifications';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC, useState } from 'react';
import { MemoryRouter, useLocation, useRouteMatch } from 'react-router-dom';

import { baseConfig, baseSettings } from './config.template';
import EditAugur from './EditAugur';
import styles from './styles.module.scss';
import { ModuleConfiguration } from './type';
import {
  fromAugurSettingsFormState,
  toAugurSettingsFormState,
} from './utils/augurSettings.form';
import {
  filterConfigForElementVisibility,
  getVisibilityDependencyArray,
} from './utils/cfe';
import { reportAndSettingsSchemas } from './utils/reportAndSettingsSchemas';
import ViewAugur from './ViewAugur';
import {
  modulesKeys,
  useModuleFiles,
  useModules,
} from '../../../core/api/modules';
import { saveConfig, saveSettings } from '../../../core/api/workbench/content';
import NotebookApi from '../../../core/api/workbench/git.notebook';
import {
  error as ERROR_NOTIFICATION,
  event as EVENT_NOTIFICATION,
} from '../../../core/notifications';
import { sendNotification } from '../../../redux/modules/notifications.module';
import { useAppDispatch } from '../../../store/store';
import Busy from '../../atoms/busy/Busy';
import { AugurCategory } from '../../molecules/augur-menu/types';
import OverlayDevAugurDetails from '../../organisms/sub-header/overlay-augur-details/OverlayDevAugurDetails';
import {
  getFirstActiveJobCodeOfCurrentCategory,
  getTimeTravelEntryFromReport,
} from '../../organisms/time-travel/helpers';
import { useTimeTravelHelpers } from '../../organisms/time-travel/hooks';
import { useSelectedDirPath } from '../../workbench/hooks';
import ErrorBoundary from '../error-boundary/ErrorBoundary';

type Props = {
  notebookUser: string;
};

// FIXME-CM create stories component
export const DevAugur: FC<
  Props & {
    selectedDirPath: string[];
  }
> = (props) => {
  const { notebookUser, selectedDirPath } = props;
  const [editMode, setEditMode] = useState(false);
  const queryClient = useQueryClient();

  const dispatch = useAppDispatch();
  const notebookApi = new NotebookApi(notebookUser);

  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  const relativeModulePath = contentArrayToPath(
    selectedDirPath.slice(0, index + 1),
    false
  );
  const { isInitialLoading: isModuleFilesDataLoading, data: moduleFilesData } =
    useModuleFiles(relativeModulePath, index !== -1);

  const pageMatch = useRouteMatch<{
    pageCategory?: AugurCategory;
    pageId?: string;
  }>(`/:pageCategory?/:pageId?`);
  const { pageCategory: selectedPageCategory } = pageMatch?.params || {};

  const { isHighlighted } = useTimeTravelHelpers(selectedPageCategory);

  const { data: modules, isInitialLoading: isModulesLoading } = useModules();

  if (isModuleFilesDataLoading || isModulesLoading) {
    return <Busy isBusy={true} />;
  }

  // calculate the reports to be displayed from the TimeTravel selection
  // this uses the same logic that is used for calculating the highlighted entries of the TimeTravel component
  const activeModelCode = moduleFilesData.latestReports?.learning?.modelCode;
  const firstActiveJobCodeOfCurrentCategory =
    getFirstActiveJobCodeOfCurrentCategory(
      moduleFilesData.reports.map(getTimeTravelEntryFromReport),
      selectedPageCategory,
      activeModelCode
    )?.code;
  const displayedReports = moduleFilesData.reports.filter((report) =>
    isHighlighted(
      report.jobCode,
      report.type,
      firstActiveJobCodeOfCurrentCategory
    )
  );

  // Local variables to simplify the tree
  const config = moduleFilesData.config ? moduleFilesData.config : baseConfig;
  const latestAugurReports = moduleFilesData.latestReports
    ? Object.values(moduleFilesData.latestReports)
    : [];
  const augurSettings = moduleFilesData.settings
    ? { ...baseSettings, settingsData: moduleFilesData.settings }
    : baseSettings;

  async function invalidateModuleFilesData() {
    await queryClient.invalidateQueries(
      modulesKeys.files(
        contentArrayToPath(selectedDirPath.slice(0, index + 1), false)
      )
    );
  }

  const emptyConfig: ModuleConfiguration = {
    apiVersion: 'v0', // FIXME-CM
    generalConfiguration: {
      supportsLearning: false,
      supportsEvaluation: false,
      supportsPrediction: false,
      supportsRealtimePrediction: false,
    },
    augurReportConfiguration: {
      learning: [],
      evaluation: [],
      prediction: [],
    },
    augurSettingsConfiguration: [],
  };

  const getModuleConfig = (moduleCode?: string, moduleVersionCode?: string) => {
    return (
      (modules
        ?.find((module) => module.code === moduleCode)
        ?.versions?.find((version) => version.code === moduleVersionCode)
        ?.config as unknown as ModuleConfiguration) ?? emptyConfig
    );
  };

  const onSubmitConfig = async (moduleConfig: ModuleConfiguration) => {
    await saveConfig(notebookUser, moduleConfig, selectedDirPath, index);

    notebookApi
      .generateModuleTypes(
        relativeModulePath,
        reportAndSettingsSchemas(moduleConfig)
      )
      .then(() =>
        dispatch(
          sendNotification(
            msgsNotifications.titleWorkbenchTerminalPythonGenerated.id,
            // @ts-ignore
            msgsNotifications.titleWorkbenchTerminalPythonGenerated.id,
            EVENT_NOTIFICATION
          )
        )
      )
      .catch((e) =>
        dispatch(
          sendNotification(
            msgsNotifications.titleWorkbenchTerminalPythonGenerationFailed.id,
            // @ts-ignore
            msgsNotifications.titleWorkbenchTerminalPythonGenerationFailed.id,
            ERROR_NOTIFICATION
          )
        )
      );

    // There are two issues when changing the config in the EditAugur
    // - values of deleted elements are not cleared from the AugurSettings file
    // - in the ViewAugur the settings element fields are automatically filled with the default values
    //    this is inconsistent with the actual AugurSettings file but Dirk cannot recognize this or even submit because the form is not dirty
    // --> because of this we update the AugurSettings on EditAugur submission

    // convert the new config and the old settings to the AugurSettings form state to add new default values
    const settingsWithDefaults = toAugurSettingsFormState(
      moduleConfig,
      augurSettings
    );
    // filter new config of invisible elements
    const visibilityDependenciesLookupMap =
      getVisibilityDependencyArray(moduleConfig);
    const filteredModuleConfig = filterConfigForElementVisibility(
      moduleConfig,
      visibilityDependenciesLookupMap,
      augurSettings.settingsData
    );
    // convert it back with filtered config (omitting values of invisible elements)
    const newAugurSettings = fromAugurSettingsFormState(
      settingsWithDefaults,
      filteredModuleConfig
    );

    await saveSettings(
      notebookUser,
      newAugurSettings.settingsData,
      selectedDirPath,
      index
    );
    await invalidateModuleFilesData();
  };

  if (editMode) {
    return (
      <EditAugur
        moduleConfig={config}
        augurReports={latestAugurReports}
        augurSettings={augurSettings}
        onLeaveEditMode={() => setEditMode(false)}
        onSubmitConfig={onSubmitConfig}
      />
    );
  } else {
    return (
      <ViewAugur
        moduleConfig={config}
        getModuleConfig={getModuleConfig}
        augurReports={displayedReports}
        augurSettings={augurSettings}
        pathPrefix={''}
        isDevMode={true}
        onSubmitAugurSettings={async (settings) => {
          await saveSettings(
            notebookUser,
            settings.settingsData,
            selectedDirPath,
            index
          ).then(invalidateModuleFilesData);
        }}
        onEnterEditMode={() => setEditMode(true)}
        hasNoModel={!activeModelCode}
      />
    );
  }
};

/**
 * MemoryRouter wrapper for hiding the Augur paths in the URL for the DevAugur.
 * Necessary because the ViewAugur functions with routing.
 * @param props DevAugur props
 */
const MemoryRouterWrapper: FC<Props> = (props) => {
  const selectedDirPath = useSelectedDirPath();
  const location = useLocation();

  return (
    <ErrorBoundary fullScreenHeight>
      <MemoryRouter
        initialEntries={[
          {
            pathname: '/',
            // this ensures that components can still access the selected dir path inside the DevAugur
            search: location.search,
          },
        ]}
      >
        <div className={styles.devAugurDetailsInner}>
          <OverlayDevAugurDetails />
          <DevAugur {...props} selectedDirPath={selectedDirPath} />
        </div>
      </MemoryRouter>
    </ErrorBoundary>
  );
};

export default MemoryRouterWrapper;
