import { ToBeRefined } from '../types/todo_type';
import { PostHabitatRequestBody } from '../types/requestBodies/habitats';

const isEmpty = (value: unknown): boolean =>
  value === undefined || value === null || value === '';

/**
 * Backend
 * @param body
 */
export const validateAddHabitat = (
  body: PostHabitatRequestBody
): ToBeRefined => {
  const errors: ToBeRefined = {};

  const nameError = validateHabitatName(body.name);
  if (nameError) {
    errors.habitatName = nameError;
  }

  return errors;
};

const habitatNameRegExp = /^[\w\s\d[\]äöüßÄÖÜ()-]+$/;

const validateHabitatName = (
  habitatName?: string
): { id: string } | undefined => {
  if (!habitatName || isEmpty(habitatName)) {
    return { id: 'newHabitat.stepOne.error.missing_habitat_name' };
  } else if (habitatName.length < 3 || habitatName.length > 65) {
    return { id: 'newHabitat.stepOne.error.habitat_name_length' };
  } else if (!habitatNameRegExp.test(habitatName)) {
    return {
      id: 'newHabitat.stepOne.error.it_can_contain_only_alphanumeric_symbols_and_spaces',
    };
  }
};

/**
 * Frontend
 * @param values
 */
export function validateAddHabitatWizard(values: ToBeRefined): ToBeRefined {
  const errors: ToBeRefined = {};

  errors.habitatName = validateHabitatName(values.habitatName);

  return errors;
}
