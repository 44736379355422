import {
  PathType,
  PipelineTuningValue,
  PipelineTuningValueNode,
  PipelineTuningValueNodeOrGroup,
  SelectedStaticParameter,
} from 'common/dist/types/pipeline';
import { ParameterType, SinglePathResult } from 'common/dist/types/module.nbc';
import { GroupType, NodeType, PipelineTuningSchemaType } from 'common/dist/types/moduleVersion';



interface ParameterOutputMap {
  [nodeId: string]: { [parameterId: string]: string | number };
}

interface SingleResultsMap {
  [pipelineId: string]: { [pathId: string]: SinglePathResult[] };
}


/**
 * Takes an original pipeline schema and a path that was taken through the pipeline.
 * Based on this, the new pipeline schema is derived to display the path in the pipeline chart
 * @param pipeline
 * @param pipelinePath
 */
export function derivePathPipeline(
  pipeline: PipelineTuningValue,
  pipelinePath: PathType
): PipelineTuningSchemaType {
  // --- Derive the edges
  const newEdges = [];
  if (pipelinePath.path.length > 0) {
    for (let i = 0; i < pipelinePath.path.length - 1; i++) {
      const sourceID = pipelinePath.path[i];
      const targetID = pipelinePath.path[i + 1];
      newEdges.push({ sourceID, targetID });
    }
  }

  // --- Derive the nodes
  const nodes = pipeline.nodes;
  const newNodes: PipelineTuningValueNode[] = [];
  nodes.forEach((node) => {
    if (node.type === 'node' && pipelinePath.path.includes(node.id)) {
      newNodes.push(node);
    } else if (node.type === 'group') {
      node.nodes.forEach((innerNode) => {
        if (pipelinePath.path.includes(innerNode.id)) {
          newNodes.push(innerNode);
        }
      });
    }
  });

  return {
    ...pipeline,
    // @ts-ignore 
    nodes: newNodes,
    edges: newEdges,
  };
}

/**
 * Takes a list of parameters as returned from the python binary job and converts it to a properly usable map nodeId -> parameterId -> value
 * @param parameters Parameter names are in the form: "clf|preprocessing__<node_id>__<parameter_id>"
 *   For example: "clf__decision_tree_classifier__min_samples_leaf"
 */
export function convertTuningParameterOutputs(parameters: ParameterType[]): {
  [nodeId: string]: { [parameterId: string]: string | number };
} {
  const dict: ParameterOutputMap = {};

  parameters.forEach((paramObj) => {
    const parts = paramObj.name.split('__');
    const nodeId = parts[1];
    const paramId = parts[2];
    // Create the dict for the nodeId if necessary
    if (!Object.keys(dict).includes(nodeId)) {
      dict[nodeId] = {};
    }
    dict[nodeId][paramId] = paramObj.value;
  });

  return dict;
}

/**
 * Takes a list of nodes and returns a map nodeId -> parameterId -> value for the static values
 * @param nodes
 */
export function getStaticParamsMap(nodes: PipelineTuningValueNodeOrGroup[]): {
  [nodeId: string]: { [parameterId: string]: string | number };
} {
  const dict: ParameterOutputMap = {};
  const addParams = (node: PipelineTuningValueNode) => {
    if (!Object.keys(dict).includes(node.id)) {
      dict[node.id] = {};
    }
    node.staticParameters?.forEach((param: SelectedStaticParameter) => {
      dict[node.id][param.id] = param.value;
    });
  };

  nodes.forEach((node) => {
    if (node.type === 'node') {
      addParams(node);
    } else if (node.type === 'group') {
      node.nodes.forEach((innerNode) => addParams(innerNode));
    }
  });

  return dict;
}

/**
 * Takes a list of singlePathResults and maps them to pipelineId -> pathId -> List of Single Paths results
 * @param singlePathResults
 */
export function mapSingleResultsToPipelineAndPaths(
  singlePathResults: SinglePathResult[]
): { [pipelineId: string]: { [pathId: string]: SinglePathResult[] } } {
  const dict: SingleResultsMap = {};
  singlePathResults.forEach((spr) => {
    const pipelineId = spr.pipelineId;
    const pathId = spr.pathId;
    // Create the dict for the pipelineId if necessary
    if (!Object.keys(dict).includes(pipelineId)) {
      dict[pipelineId] = {};
    }
    dict[pipelineId][pathId] = [...(dict[pipelineId][pathId] || []), spr];
  });
  return dict;
}
