import { contentArrayToPath } from 'common/dist/utils/workbench/content';
import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useTimeTravel } from './hooks';
import TimeTravel from './TimeTravel';
import { useModuleFiles } from '../../../core/api/modules';
import Busy from '../../atoms/busy/Busy';
import { AugurCategory } from '../../molecules/augur-menu/types';
import { AugurReport } from '../../pages/augur/type';
import { useSelectedDirPath } from '../../workbench/hooks';

export type Props = {
  selectedPageCategory?: AugurCategory;
};

const DevTimeTravel: FC<Props> = ({ selectedPageCategory }) => {
  const location = useLocation();
  const selectedDirPath = useSelectedDirPath();
  const index = selectedDirPath.findIndex((x) => x.endsWith('.asr'));
  const {
    isInitialLoading,
    error,
    data: moduleFilesData,
  } = useModuleFiles(
    contentArrayToPath(selectedDirPath.slice(0, index + 1), false),
    index !== -1
  );

  const { singleSelection } = useTimeTravel();

  if (isInitialLoading) return <Busy isBusy={true} />;
  if (!moduleFilesData) return <div>{error}</div>;

  // when in selection mode, check whether the selected page category fits the selected job
  // redirect if this is not the case
  const explicitlySelectedJob = moduleFilesData.reports.find(
    (report) => report.jobCode === singleSelection
  );
  if (singleSelection && selectedPageCategory !== explicitlySelectedJob.type) {
    return <Redirect to={`/${explicitlySelectedJob.type}${location.search}`} />;
  }

  const activeModelCode = moduleFilesData.latestReports?.learning?.modelCode;
  const reports: AugurReport[] = Object.values(moduleFilesData.reports);

  return (
    <TimeTravel
      entries={reports.map((report) => ({
        code: report.jobCode,
        title: report.jobCode,
        timestamp: report.timestamp,
        type: report.type,
        modelCode: report.modelCode,
        jobCode: report.jobCode
      }))}
      activeModelCode={activeModelCode}
      selectedPageCategory={selectedPageCategory}
    />
  );
};

export default DevTimeTravel;
