import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../../_components/TextInput';
import { validateName } from '../../../../../../workbench/common/notebookName';
import GenericFormStep from '../../../../../molecules/generic-form-step/GenericFormStep';
import { fieldName, formName } from '../notebook.form';
import SelectedDirs from '../../../../../molecules/selected-dirs/SelectedDirs';
import { useContent } from '../../../../../../core/api/workbench/content';
import { useSelectedDirPath } from '../../../../hooks';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';

function notebookDisplayName(raw) {
  if (!raw) return '';
  if (raw.endsWith('.ipynb') || raw.endsWith('.asapp')) return raw;
  return `${raw}.ipynb`;
}

function errorMessage(notebookNameTouched, notebookNameError) {
  if (notebookNameTouched && notebookNameError) {
    return <p className={'error'}>{notebookNameError}</p>;
  }
}

function NotebookNameComponent({
  selectedDirPath,
  notebookName,
  notebookNameTouched,
  notebookNameError,
  ...props
}) {
  return (
    <div className={`input-container`}>
      <div className={'error-container'}>
        {errorMessage(notebookNameTouched, notebookNameError)}
      </div>
      <div className={'name-input'}>
        <TextInput {...props} />
        <SelectedDirs
          dirs={selectedDirPath}
          notebookName={notebookDisplayName(notebookName)}
        />
      </div>
    </div>
  );
}

export const Step1 = (props) => {
  const selectedDirPath = useSelectedDirPath();

  const { data } = useContent(contentArrayToPath(selectedDirPath, false));
  const content = useMemo(() => data?.content ?? [], [data]);

  // If this wasn't used but is done inline in the 'validate' prop of Field it would cause a permanent call of
  // UNREGISTER_FIELD and REGISTER_FIELD with every character entered
  const validate = useMemo(() => {
    return (value) =>
      validateName(value, content, props.isEditing, props.initialName);
  }, [content, props.isEditing, props.initialName]);

  return (
    <GenericFormStep
      fieldName={fieldName}
      formName={formName}
      component={NotebookNameComponent}
      title={{
        id: 'workbench.notebook.name.kernel.title',
        defaultMessage: 'Select Name',
      }}
      description={{
        id: 'workbench.notebook.step.name.description',
        defaultMessage:
          'Select a name for the notebook. Browse on the left to change the path where the notebook will be created.',
      }}
      num={1}
      validate={validate}
      renderError={false}
      fieldProps={{
        selectedDirPath: selectedDirPath,
        notebookName: props.notebookName,
        notebookNameTouched: props.notebookNameTouched,
        notebookNameError: props.notebookNameError,
      }}
    />
  );
};

Step1.propTypes = {
  notebookName: PropTypes.string,
  notebookNameTouched: PropTypes.bool,
  notebookNameError: PropTypes.string,
  /** Is the notebook being edited: Needed for validation as in that case the name can be the same */
  isEditing: PropTypes.bool,
  /** The initial name of the notebook: Needed for correct validation in the case of isEditing */
  initialName: PropTypes.string,
};

export default Step1;
