import {
  apiRequest,
  deleteApiRequest,
  postApiRequest,
  putApiRequest,
} from './_apiRequests';

export const API_PORT = 3040; // TODO How can this be made dynamic?

export enum CommitFilter {
  None = 'none',
  NotPulled = 'not_pulled',
  NotPushed = 'not_pushed',
}

export default class NotebookApi {
  jupyterUser: string;

  constructor(jupyterUser: string) {
    this.jupyterUser = jupyterUser;
  }

  baseUrl() {
    // --> The commented line below won't work if the protocol is switched to https
    // return `http://${location.hostname + (location.port ? ':' + location.port : '')}/jupyter/user/${this.jupyterUser}/proxy/${API_PORT}`
    return `/jupyter/user/${this.jupyterUser}/proxy/${API_PORT}`;
  }

  getBranches(repoPath: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(repoPath)}/branches`;
    return apiRequest(url);
  }

  getCurrentBranch(repoPath: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(repoPath)}/branch`;
    return apiRequest(url);
  }

  createBranch(repoPath: string, branch: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(
      repoPath
    )}/branch/${encodeURIComponent(branch)}`;
    return postApiRequest(url, {});
  }

  switchBranch(repoPath: string, branch: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(
      repoPath
    )}/branch/${encodeURIComponent(branch)}`;
    return putApiRequest(url, {});
  }

  deleteBranch(
    repoPath: string,
    branch: string,
    force = false,
    remote = false
  ) {
    const url = `${this.baseUrl()}/${encodeURIComponent(
      repoPath
    )}/branch/${encodeURIComponent(branch)}?force=${force}&remote=${remote}`;
    // @ts-ignore
    return deleteApiRequest(url, {});
  }

  addAllAndCommit(repoPath: string, msg: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(repoPath)}/commit`;
    return postApiRequest(url, { commitMessage: msg });
  }

  listCommits(
    repoPath: string,
    branch: string,
    maxCount = 25,
    skip = 0,
    filter: CommitFilter = CommitFilter.None
  ) {
    const url = `${this.baseUrl()}/${encodeURIComponent(
      repoPath
    )}/branch/${encodeURIComponent(
      branch
    )}/commits?maxCount=${maxCount}&skip=${skip}&filter=${filter}`;
    return apiRequest(url);
  }

  pull(repoPath: string, branch: string, force = false) {
    const url = `${this.baseUrl()}/${encodeURIComponent(
      repoPath
    )}/branch/${encodeURIComponent(branch)}/pull?force=${force}`;
    return postApiRequest(url, {});
  }

  push(repoPath: string, branch: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(
      repoPath
    )}/branch/${encodeURIComponent(branch)}/push`;
    return postApiRequest(url, {});
  }

  clone(
    notebookPath: string,
    repoName: string,
    repoFullPath: string,
    gitDisplayName: string,
    gitMail: string,
    metaFileContent: string,
    branch = 'master'
  ) {
    const url = `${this.baseUrl()}/clone`;
    return postApiRequest(url, {
      notebookPath,
      repoName,
      repoFullPath,
      gitDisplayName,
      gitMail,
      metaFileContent,
      branch,
    });
  }

  fork(repoPath: string, repoFullNameNew: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(repoPath)}/fork`;
    return postApiRequest(url, { repoFullNameNew });
  }

  status(repoPath: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(repoPath)}/status`;
    return apiRequest(url, {});
  }

  ensureDir(path: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(path)}`;
    return putApiRequest(url, {});
  }

  deleteContent(path: string, permanently: boolean) {
    const url = `${this.baseUrl()}/${encodeURIComponent(path)}${
      permanently ? '?noRecycle=true' : ''
    }`;
    // @ts-ignore
    return deleteApiRequest(url, {});
  }

  config(gitDisplayName: string, gitMail: string) {
    const url = `${this.baseUrl()}/config`;
    return putApiRequest(url, { gitDisplayName, gitMail });
  }

  checkRefFormat(ref: string) {
    const url = `${this.baseUrl()}/check-ref-format?ref=${encodeURIComponent(
      ref
    )}`;
    return apiRequest(url, {});
  }

  getRemote(repoPath: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(repoPath)}/remote`;
    return apiRequest(url);
  }

  fetchAndTrackRemoteBranch(repoPath: string, branch: string) {
    const url = `${this.baseUrl()}/${encodeURIComponent(
      repoPath
    )}/track/${encodeURIComponent(branch)}`;
    return postApiRequest(url, {});
  }

  // --- Doesn't belong here, but served by the git notebook api ...
  getDiskUsage() {
    const url = `${this.baseUrl()}/diskUsage`;
    return apiRequest(url);
  }

  getModuleFiles(path: string) {
    const url = `${this.baseUrl()}/modules/files/${encodeURIComponent(path)}`;
    return apiRequest(url, {});
  }

  async generateModuleTypes(path: string, body: Record<string, unknown>) {
    const url = `${this.baseUrl()}/modules/types/${encodeURIComponent(path)}`;
    return putApiRequest(url, body);
  }

  /*This endpoint will send a delete request to the notebook-git-api and remove the folder venv, asruns, augur_data but will keep the augur_data/augur_settings.json*/
  removeModuleArtifacts(path: string) {
    const url = `${this.baseUrl()}/modules/removeArtifacts/${encodeURIComponent(
      path
    )}`;
    return deleteApiRequest(url);
  }
}
