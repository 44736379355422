import Ajv from 'ajv';
import devAugurMessages from 'common/dist/messages/augurs.devAugur';
import commonMessages from 'common/dist/messages/common';
import React, { FC, useState } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Redirect, useHistory, useParams } from 'react-router-dom';

import { useSelectedAugurPage } from './hooks';
import EmptyAugur from './placeholderPages/EmptyAugur';
import styles from './styles.module.scss';
import {
  AugurReport,
  AugurReportElement,
  AugurSettings,
  AugurSettingsElement,
  isAugurReportsElement,
  isAugurSettingsElement,
  ModuleConfiguration,
} from './type';
import {
  getFormPageErrors,
  toAugurSettingsFormState,
  useAugurSettingsForm,
} from './utils/augurSettings.form';
import { getPage } from './utils/config';
import { configToFormState } from './utils/config.form';
import {
  configFormToMenuCategories,
  getMenuCategoryByPageId,
  getValidPage,
  isSelectablePageId,
  isValidPageCategory,
} from './utils/menuCategories';
import { transformConfigToConfigProps } from './utils/transformation';
import { capitalize, reportDescriptions } from './utils/util';
import Button from '../../atoms/button/Button';
import AugurBiography from '../../modelManagement/modules/common/augur-details-tabs/augur-biography/AugurBiography.container';
import ModelArchive from '../../modelManagement/modules/common/augur-details-tabs/modelhistory/ModelArchive';
import { findElementMeta } from '../../molecules/augur-layout-elements/common/utils';
import ReportParentElement from '../../molecules/augur-layout-elements/report-elements/reportParentElement/ReportParentElement';
import SettingsParentElement from '../../molecules/augur-layout-elements/settings-elements/settingsParentElement/SettingsParentElement';
import AugurMenu from '../../molecules/augur-menu/AugurMenu';
import {
  ID_AUGUR_BIOGRAPHY,
  ID_GENERAL_SETTINGS,
  ID_MODEL_ARCHIVE,
} from '../../molecules/augur-menu/types';
import GridLayoutEditor from '../../molecules/grid-layout-editor/GridLayoutEditor';
import { GridLayoutElement } from '../../molecules/grid-layout-editor/type';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import DevTimeTravel from '../../organisms/time-travel/DevTimeTravel';
import { useTimeTravel } from '../../organisms/time-travel/hooks';
import LiveTimeTravel from '../../organisms/time-travel/LiveTimeTravel';
import GeneralSettings from '../general-settings/GeneralSettings';

export const ajv = new Ajv({
  allErrors: true,
});

export type ReportTransformationWrapperProps = {
  element: AugurReportElement;
  reports: AugurReport[];
  setFullPage: (uuid: string) => void;
  fullPageElement?: string;
};

export const ReportTransformationWrapper: FC<
  ReportTransformationWrapperProps
> = ({ element, reports, setFullPage, fullPageElement }) => {
  const elementMeta = findElementMeta(element.type, element.version);

  // TODO-CM https://gitlab.sigmalto.com/altasigma-platform/concept-custom-archetypes/-/issues/329
  //  either check validity or just try to read it and crash. Important to show information
  //  to fix the problem: broken config, broken data
  //  If we want to guard against changes to the files
  //  we would have to check everything, even the title and things that could never be broken in the EditAugur

  const config = transformConfigToConfigProps(element.config);
  const reportEntries = reports.map(({ reportData, ...rest }) => ({
    // FIXME-CM: Was necessary to show the report in the liveAugur
    reportValue: reportData?.[element.reportKey],
    ...rest,
  }));

  const elementProps = {
    ...element,
    config,
    reportEntries,
  };

  return (
    <ReportParentElement
      setFullPage={setFullPage}
      fullPageElement={fullPageElement}
      elementProps={elementProps}
      elementMeta={elementMeta}
    />
  );
};

export type SettingsTransformationWrapperProps = {
  element: AugurSettingsElement;
  inputs: Record<string, unknown>;
};

export const SettingsTransformationWrapper: FC<
  SettingsTransformationWrapperProps
> = ({ element, inputs }) => {
  const elementMeta = findElementMeta(element.type, element.version);

  // TODO-CM: https://gitlab.sigmalto.com/altasigma-platform/concept-custom-archetypes/-/issues/329
  //  either check validity or just try to read it and crash. Important to show information
  //  to fix the problem: broken config, broken data
  //  If we want to guard against changes to the files
  //  we would have to check everything, even the title and things that could never be broken in the EditAugur

  const config = transformConfigToConfigProps(element.config, inputs);
  const elementProps = {
    ...element,
    config,
  };

  return (
    <SettingsParentElement
      elementProps={elementProps}
      elementMeta={elementMeta}
    />
  );
};

export type Props = {
  moduleConfig: ModuleConfiguration;
  getModuleConfig: () => ModuleConfiguration;
  augurReports: AugurReport[];
  augurSettings: AugurSettings;
  onSubmitAugurSettings: (augurSettings: AugurSettings) => void;
  pathPrefix: string;
  isDevMode?: boolean;
  onEnterEditMode?: () => void;
  onArchiveAugur?: (archive: boolean) => void;
  augurArchived?: boolean;
  isArchiveButtonLoading?: boolean;
  hasNoModel: boolean;
};

const ViewAugur: FC<Props> = ({
  moduleConfig,
  getModuleConfig,
  augurReports,
  augurSettings,
  pathPrefix,
  onSubmitAugurSettings,
  isDevMode = false,
  onEnterEditMode,
  onArchiveAugur,
  augurArchived,
  isArchiveButtonLoading,
  hasNoModel,
}) => {
  const history = useHistory();
  const intl = useIntl();
  const { habitatCode } = useParams<{ habitatCode: string }>();
  const [fullPageElement, setFullPage] = useState<string | null>(null);

  const { selectedPageCategory, selectedPageId } =
    useSelectedAugurPage(pathPrefix);

  const { singleSelection, updateSingleSelection, compareSelection } =
    useTimeTravel();

  const {
    formMethods: augurSettingsFormMethods,
    visibilityFilteredModuleConfig,
  } = useAugurSettingsForm(
    isDevMode,
    moduleConfig,
    habitatCode,
    getModuleConfig,
    augurSettings
  );
  const {
    watch,
    reset,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = augurSettingsFormMethods;

  const currentSettings = watch('settingsData');

  const menuCategories = configFormToMenuCategories(
    configToFormState(visibilityFilteredModuleConfig).pages,
    isDevMode ? 'view' : 'live'
  );

  const renderContentPane = () => {
    // we first have to check whether the provided url params are valid
    const firstValidPage = getValidPage(menuCategories);
    if (!firstValidPage) {
      // no pages exist for the given config: nothing can be shown
      if (isDevMode) {
        return (
          <div className={styles.contentContainer}>
            <EmptyAugur
              headline={'No Reports and Settings Pages'}
              description={
                'Your Augur currently has no pages. Enter Edit Mode to add report and settings pages.'
              }
            />
          </div>
        );
      } else {
        // should not happen as modelManagement pages are always available for the LiveAugur
        return (
          <div className={styles.contentContainer}>
            <EmptyAugur
              headline={'No Reports and Settings Pages'}
              description={'This Augur does not have any pages configured.'}
            />
          </div>
        );
      }
    } else if (isValidPageCategory(selectedPageCategory)) {
      // page category param is valid
      // IMPORTANT: we cannot redirect to a different category here because TimeTravel uses the page category for redirects and this could result in a redirect loop
      const validPageForSelectedCategory = getValidPage(
        menuCategories,
        selectedPageCategory
      );
      if (!validPageForSelectedCategory) {
        return (
          <div className={styles.contentContainer}>
            <EmptyAugur
              headline={'Invalid category selected'}
              description={
                'There are no pages for the currently selected category for this Augur. Please select a valid page from the side menu.'
              }
            />
          </div>
        );
      } else if (
        !isSelectablePageId(
          menuCategories,
          selectedPageId,
          selectedPageCategory
        )
      ) {
        // page id param was invalid -> redirect to the first valid page of the selected category
        return (
          <Redirect
            to={`${pathPrefix}/${selectedPageCategory}/${validPageForSelectedCategory.id}${history.location.search}`}
          />
        );
      }
      // page for given page id param exists: success case
    } else {
      // page category param was invalid -> redirect to the first valid page
      const [, pageCategory] = getMenuCategoryByPageId(
        menuCategories,
        firstValidPage.id
      );
      return (
        <Redirect
          to={`${pathPrefix}/${pageCategory}/${firstValidPage.id}${history.location.search}`}
        />
      );
    }

    const page = getPage(visibilityFilteredModuleConfig, selectedPageId);
    if (fullPageElement) {
      const element = page.elements.find(
        (el) => el.uuid === fullPageElement
      ) as AugurReportElement;
      return (
        <ReportTransformationWrapper
          element={element}
          reports={augurReports}
          fullPageElement={fullPageElement}
          setFullPage={setFullPage}
        />
      );
    }
    const renderLayoutEditor = () => {
      const renderedElements = page.elementArrangement.reduce(
        (acc, layoutElement) => {
          const element = page.elements.find(
            (el) => el.uuid === layoutElement.i
          );

          return [
            {
              id: layoutElement.i,
              element: isAugurReportsElement(element) ? (
                <ReportTransformationWrapper
                  element={element}
                  reports={augurReports}
                  fullPageElement={fullPageElement}
                  setFullPage={setFullPage}
                />
              ) : isAugurSettingsElement(element) ? (
                <SettingsTransformationWrapper
                  element={element}
                  inputs={currentSettings}
                />
              ) : null,
            },
            ...acc,
          ];
        },
        [] as GridLayoutElement[]
      );

      return (
        <GridLayoutEditor
          arrangement={page.elementArrangement}
          elements={renderedElements}
          viewOnly={true}
        />
      );
    };

    switch (selectedPageCategory) {
      case 'settings': {
        const onSubmit: SubmitHandler<AugurSettings> = (filteredData) => {
          return onSubmitAugurSettings(filteredData);
        };

        return (
          <>
            {/*<DevTool control={augurSettingsFormMethods.control} />*/}
            <FormProvider {...augurSettingsFormMethods}>
              <form
                className={styles.contentContainer}
                onSubmit={handleSubmit(onSubmit)}
              >
                <>
                  <div className={styles.settingsContainer}>
                    {selectedPageId === ID_GENERAL_SETTINGS ? (
                      <GeneralSettings
                        generalConfiguration={
                          visibilityFilteredModuleConfig.generalConfiguration
                        }
                        moduleTypeSelectable={false}
                        form={augurSettingsFormMethods}
                        onArchiveAugur={onArchiveAugur}
                        archived={augurArchived}
                        isArchiveButtonLoading={isArchiveButtonLoading}
                      />
                    ) : (
                      renderLayoutEditor()
                    )}
                  </div>
                  <div className={styles.settingsButtonsContainer}>
                    <Button
                      buttonColor={'primary'}
                      withLink={false}
                      label={commonMessages.submit}
                      disabled={!isDirty || !isValid}
                      title={
                        !isValid
                          ? intl.formatMessage(devAugurMessages.invalidSettings)
                          : undefined
                      }
                      isSubmitButton
                    />
                    <Button
                      buttonColor={'white'}
                      withLink={false}
                      label={commonMessages.cancel}
                      disabled={!isDirty}
                      onClick={() => {
                        reset(
                          toAugurSettingsFormState(moduleConfig, augurSettings)
                        );
                      }}
                    />
                  </div>
                </>
              </form>
            </FormProvider>
          </>
        );
      }
      case 'learning':
      case 'evaluation':
      case 'prediction':
        if (augurReports.length === 0) {
          if (compareSelection) {
            return (
              <div className={styles.contentContainer}>
                <EmptyAugur
                  headline={`No ${capitalize(
                    selectedPageCategory
                  )} Report for current selection`}
                  description={`Please select ${reportDescriptions[selectedPageCategory]} Report from the Augur Time Travel.`}
                />
              </div>
            );
          } else {
            if (hasNoModel) {
              return (
                <div className={styles.contentContainer}>
                  <EmptyAugur
                    headline={`No active Model for this Augur`}
                    description={`Trigger a Learning Job to generate the first Model of this Augur.`}
                  />
                </div>
              );
            } else {
              return (
                <div className={styles.contentContainer}>
                  <EmptyAugur
                    headline={`No ${capitalize(
                      selectedPageCategory
                    )} Report for the active Model`}
                    description={`This Augur doesn't have ${reportDescriptions[selectedPageCategory]} Report for the active Model yet. Please trigger ${reportDescriptions[selectedPageCategory]} Job to generate a Report for the current Model or select a different Report from the Augur Time Travel.`}
                  />
                </div>
              );
            }
          }
        } else {
          return (
            <div className={styles.contentContainer}>
              {renderLayoutEditor()}
            </div>
          );
        }
      case 'modelManagement':
        // TODO-CM
        return (
          <div className={styles.contentContainer}>
            {selectedPageId === ID_AUGUR_BIOGRAPHY ? (
              /* @ts-ignore */
              <AugurBiography />
            ) : selectedPageId === ID_MODEL_ARCHIVE ? (
              <ModelArchive />
            ) : (
              ''
            )}
          </div>
        );
      default:
        return (
          <div className={styles.contentContainer}>
            <EmptyAugur
              headline={'Invalid category selected'}
              description={
                'There are no pages for the currently selected category for this Augur. Please select a valid page from the side menu.'
              }
            />
          </div>
        );
    }
  };

  const settingsPageErrors =
    visibilityFilteredModuleConfig.augurSettingsConfiguration.reduce(
      (acc, page) => {
        const pageErrors = getFormPageErrors(page, errors);
        return {
          ...acc,
          [page.uuid]: pageErrors,
        };
      },
      {}
    );

  const categoriesWithErrors = menuCategories.map((category) => {
    return {
      ...category,
      entries: category.entries.map((entry) => {
        let errorMessage: string;
        if (entry.id === ID_GENERAL_SETTINGS) {
          const isGeneralSettingsInvalid =
            Object.keys(errors).filter((key) => key !== 'settingsData').length >
            0;
          if (isGeneralSettingsInvalid) {
            errorMessage = intl.formatMessage(
              devAugurMessages.invalidSettingsElements
            );
          }
        } else {
          if (settingsPageErrors[entry.id]?.length > 0) {
            errorMessage = intl.formatMessage(
              devAugurMessages.invalidSettingsElements
            );
          }
        }

        return {
          ...entry,
          error: errorMessage || undefined,
        };
      }),
    };
  });

  const renderSidebar = () => {
    return (
      <div className={styles.sidebarContainer}>
        {isDevMode ? (
          <DevTimeTravel selectedPageCategory={selectedPageCategory} />
        ) : (
          <LiveTimeTravel selectedPageCategory={selectedPageCategory} />
        )}
      </div>
    );
  };

  return (
    <div className={styles.devAugurContainer}>
      <div className={styles.sideNavContainer}>
        <AugurMenu
          selectedTab={selectedPageId}
          onSelectEntry={(value, category) => {
            setFullPage(null);

            if (selectedPageCategory !== category && singleSelection) {
              // if there is a category switch in selection mode we return to implicit mode (clear TimeTravel selection)
              // IMPORTANT: we cannot omit this as otherwise the Augur will just
              //   switch back the category to the singleSelection on the next render
              updateSingleSelection(undefined);
            }

            history.push(
              `${pathPrefix}/${category}/${value}${history.location.search}`
            );
          }}
          categories={categoriesWithErrors}
        />
        {isDevMode && onEnterEditMode && (
          <div className={styles.buttonContainer}>
            <Button
              buttonColor={'primary'}
              label={devAugurMessages.editMode}
              withLink={false}
              onClick={onEnterEditMode}
            />
          </div>
        )}
      </div>
      <div className={styles.devAugurParent}>
        {renderContentPane()}
        {renderSidebar()}
      </div>
    </div>
  );
};

export default ViewAugur;
