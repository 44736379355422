import * as React from 'react';
import { FC, PropsWithChildren } from 'react';

import {
  buttonBarButtons,
  contextMenuEntries,
  dnd,
  onClickListeners,
} from './RecycleBinFileBrowser.defaults';
import { Result } from '../../../../utils';
import GenericFileBrowser, {
  ContentElement,
} from '../generic-file-browser/GenericFileBrowser';
import { icons } from '../workbench-file-browser/WorkbenchFileBrowser.defaults';

export type Props = {
  content: Result<ContentElement[], string>;
  width: number;
};

const RecycleBinFileBrowser: FC<Props> = (props: PropsWithChildren<Props>) => {
  return (
    <GenericFileBrowser
      content={props.content}
      buttons={buttonBarButtons}
      onClickListeners={onClickListeners}
      contextMenuEntries={contextMenuEntries}
      dnd={dnd}
      icons={icons}
      width={props.width}
      showFileSizes
    />
  );
};

export default RecycleBinFileBrowser;
