import { Channel, Propensity } from 'common/dist/types/module.optimization';
import { CampaignOptimizationCommunicationsV1AugurSettings as CampaignOptimizationCommunicationsAugurSettings } from 'common/dist/types/augurSettings/campaignOptimizationCommunicationsV1AugurSettings';
export type { CampaignOptimizationCommunicationsAugurSettings };
import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { CommunicationsErrorType } from './CampaignOptimizationCommunications';
import { DeepPartial } from 'react-hook-form';

export type CampaignOptimizationCommunicationsConfig = {
  channels: Channel[];
  propensities: Propensity[];
};
export type ConfigCampaignOptimizationCommunicationsConfig =
  Config<CampaignOptimizationCommunicationsConfig>;

export type PartialCampaignOptimizationCommunicationsAugurSettings =
  Partial<CampaignOptimizationCommunicationsAugurSettings>;

export type CampaignOptimizationCommunicationsValidationError =
  DeepPartial<CommunicationsErrorType>;

export const configAllowedInputsCampaignOptimizationCommunications: AllowedInputs<CampaignOptimizationCommunicationsConfig> =
  {
    channels: [
      {
        type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_CHANNELS,
        version: 'v1',
      },
    ],
    propensities: [
      {
        type: SETTINGS_ELEMENT_TYPES.CAMPAIGN_OPTIMIZATION_PROPENSITIES,
        version: 'v1',
      },
    ],
  };

export const configTypeCampaignOptimizationCommunications = `{
    channels: { source: 'input-element', elementUuid: string },
    propensities: { source: 'input-element', elementUuid: string },
}`;

export const defaultConfigCampaignOptimizationCommunications: ConfigCampaignOptimizationCommunicationsConfig =
  {
    channels: { source: 'input-element', elementUuid: '' },
    propensities: { source: 'input-element', elementUuid: '' },
  };

export const augurSettingsTypeCampaignOptimizationCommunications = `{
    id: string;
    name: string;
    description?: string;
    channelId: string;
    propensityId: string;
    boostFactor: string;
    variableCosts?: string;
    fixedCosts?: string;
}[]`;

export const defaultAugurSettingsDefaultCampaignOptimizationCommunications: PartialCampaignOptimizationCommunicationsAugurSettings =
  [];

// JSONSchemaType<CampaignOptimizationCommunicationsAugurSettings>
export const defaultValidationSchemaCampaignOptimizationCommunications = {
  type: 'array',
  items: {
    type: 'object',
    required: ['id', 'name', 'channelId', 'propensityId', 'boostFactor'],
  },
};
