import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import { LinkWithQuery } from '../../../atoms/link-with-query/LinkWithQuery';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';

type ResourceListEntry = {
  linkTo: string;
  columnValues: {
    [headlineId: string]: string;
  };
};

export type Props = {
  headlines: {
    /** ID of the headline (to lookup the data) */
    headlineId: string;
    label: MessageDescriptor;
  }[];
  entries: ResourceListEntry[];
  /** Specs for the column widths of the grid layout. Default: n-times 'auto' */
  gridTemplateColumns?: string;
  isLoading?: boolean;
};

const ResourceList: FC<Props> = (props: Props) => {
  const { headlines, entries, gridTemplateColumns, isLoading } = props;
  const gridTemplateColumnsOrDefault =
    gridTemplateColumns || headlines.map((unused) => 'auto').join(' ');

  return (
    <div
      className={styles.resourceList}
      style={{
        gridTemplateColumns: gridTemplateColumnsOrDefault,
      }}
    >
      {headlines.map((headline, columnIndex) => (
        <div
          className={styles.resourceListHeadlineCol}
          style={{
            gridRow: 1,
            gridColumn: columnIndex + 1,
          }}
        >
          <FormattedMessage {...headline.label} />
        </div>
      ))}

      {isLoading &&
        Array.from({ length: 10 }).map((_, i) => (
          <div
            key={i}
            className={classNames(
              styles.resourceListEntriesRow,
              styles.rowLoading
            )}
          >
            {headlines.map((headline, columnIndex) => (
              <div className={styles.loadingCol}>
                <LoadingPlaceholder key={`${i}_${columnIndex}`}>
                  <span>Loading Placeholder</span>
                </LoadingPlaceholder>
              </div>
            ))}
          </div>
        ))}

      {!isLoading &&
        entries.map((entry, rowIndex) => (
          <div key={rowIndex} className={styles.resourceListEntriesRow}>
            {headlines.map((headline, columnIndex) => (
              <LinkWithQuery
                data-testingIdentifier={entry.columnValues[headline.headlineId]}
                to={entry.linkTo}
                className={styles.resourceListEntriesCol}
                style={{
                  gridRow: rowIndex + 2, // +1 to compensate for the "index" and +1 to compensate for the headline
                  gridColumn: columnIndex + 1,
                }}
                key={`${rowIndex}_${columnIndex}`}
              >
                {entry.columnValues[headline.headlineId]}
              </LinkWithQuery>
            ))}
          </div>
        ))}
    </div>
  );
};

export default ResourceList;
