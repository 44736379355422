import React, { Component } from 'react';
import MainContainer from '../../pages/main-container/MainContainer';
import Button from '../../atoms/button/Button';
import IndicatorEmpty from '../../molecules/indicator-empty/IndicatorEmpty';
import * as routes from '../../index/routes';

export default class EmptyModelManagement extends Component {
  render() {
    return (
      <MainContainer
        additionalClassname={'ModelManagement ModelManagementEmpty'}
        transparent={false}
        fullWidth
      >
        <IndicatorEmpty
          classNameImage={'model-management-empty-pic'}
          headlineId={'no-id'}
          headlineDefault={'The Model Management is currently empty'}
          descriptionId={'no-id'}
          descriptionDefault={
            'Create your first Habitat or ask an Administrator for permission to existing Habitats'
          }
          Actions={() => (
            <Button
              buttonColor={'secondary'}
              buttonLabelId={'no-id'}
              buttonLabelDefault={'Add Habitat'}
              withLink={true}
              linkTo={
                routes.app.prefix +
                routes.app.models +
                '/' +
                routes.app.newHabitat
              }
            />
          )}
        />
      </MainContainer>
    );
  }
}
