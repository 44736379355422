import React, { FC } from 'react';
import PipelineTuningChart from '../../../../../../organisms/pipeline-tuning/pipeline-tuning-chart/PipelineTuningChart';
import { derivePathPipeline } from '../../../pipeline-tuning-single-results/v1/single/utils';
import { PathType, PipelineTuningValue } from 'common/dist/types/pipeline';
import styles from './styles.module.scss';

type Props = {
  pipeline: PipelineTuningValue;
  path: PathType;
  setActiveNodeId: (nodeId: string) => void;
};

const BestModelChart: FC<Props> = (props: Props) => {
  const { pipeline, path, setActiveNodeId } = props;

  const pathPipeline = derivePathPipeline(pipeline, path);

  return (
    <div className={styles.bestModelChart}>
      <PipelineTuningChart
        pipeline={pathPipeline}
        onSelectingNode={(n) => setActiveNodeId(n?.id)}
        inactiveNodeIds={[]}
      />
    </div>
  );
};

export default BestModelChart;