import classNames from 'classnames';
import { ScaleBand, ScaleLinear } from 'd3-scale';
import React, { FC, useState } from 'react';

import styles from './styles.module.scss';

import { PieChartData } from '../type';

type Props = {
  item?: PieChartData['data'];
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
  index: number;
  colors: { [label: string]: string };
  legendHoverIndex: number;
};

const StackedBar: FC<Props> = (props) => {
  const { xScale, yScale, item, index, colors, legendHoverIndex } = props;
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [barIndex, setBarIndex] = useState(-1);

  if (!item) {
    return (
      <g>
        <rect
          stroke='#A0A7AF55'
          strokeWidth={1}
          fill='#A0A7AF11'
          width={xScale.bandwidth()}
          height={yScale(0)}
          x={xScale(index)}
          y={yScale(100)}
        ></rect>

        <text
          fill='#A0A7AF'
          // Font size based on the bar width
          // with maximum of 14
          fontSize={
            xScale.bandwidth() < 60
              ? 0
              : xScale.bandwidth() / 5 > 14
              ? 14
              : xScale.bandwidth() / 5
          }
          // x position of the current bar + less then half of
          // the width of a bar, to center the text
          x={xScale(index)! + xScale.bandwidth() / 2 - 24}
          // y position is at 50%
          y={yScale(50) + 7}
        >
          No Data
        </text>
      </g>
    );
  }

  const keys = Object.keys(item);

  let maxHeight = 0;

  const heights: [number, number, string][] = [];

  // Here the heights of the barparts of one stackedbar are calculated
  // The first part is the else case. It starts at 0 and goes to its value
  // that is accessed with the key (item[k])
  // then it starts from the height of the previous part that was saved in heights
  keys.forEach((k, index) => {
    maxHeight = maxHeight + item[k];
    if (heights[index - 1]) {
      heights.push([
        heights[index - 1][1],
        +heights[index - 1][1] + +item[k],
        colors[k],
      ]);
    } else {
      heights.push([0, item[k], colors[k]]);
    }
  });

  const handleMouseEnter = (index: number) => {
    setShowTooltip(true);
    setBarIndex(index);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    setBarIndex(-1);
  };

  // Used to calculate the relative size based on the maxHeight of the stackedbar
  const toRelativeVal = (val: number) => {
    return Number(((val / maxHeight) * 100).toFixed(0));
  };

  return (
    <>
      {heights.map((height, index1) => {
        return (
          <g key={index1}>
            <rect
              // if hovered barIndex is set to the index of the barpart
              // then the rect grows and round its corners
              ry={barIndex === index1 || index1 === legendHoverIndex ? 2 : 0}
              rx={barIndex === index1 || index1 === legendHoverIndex ? 2 : 0}
              className={classNames(styles.barpart, {
                [styles.legendHover]: index1 === legendHoverIndex,
              })}
              fill={height[2]}
              // here the grow is calculated when hovered
              width={
                barIndex === index1 || index1 === legendHoverIndex
                  ? xScale.bandwidth() * 1.07
                  : xScale.bandwidth()
              }
              // heigth is head height minus foot heigth of the barpart
              height={Math.abs(
                yScale(toRelativeVal(height[1])) -
                  yScale(toRelativeVal(height[0]))
              )}
              x={xScale(index)}
              // y position must be the head (heigth[1])
              y={yScale(toRelativeVal(height[1]))}
              onMouseEnter={() => handleMouseEnter(index1)}
              onMouseLeave={handleMouseLeave}
            />

            {((showTooltip && barIndex === index1) ||
              index1 === legendHoverIndex) && (
              <>
                <rect
                  rx={2}
                  fill='#444'
                  // Because this is svg the sizes of the background rect
                  // for the tooltip is calculated based on the length of the
                  // tooltip length.
                  width={4 + 9 * String(height[1] - height[0]).length}
                  height={20}
                  x={xScale(index)}
                  y={yScale(toRelativeVal(height[0]))}
                ></rect>
                <text
                  scale={20}
                  // A little bit more right then the bar part
                  x={xScale(index) ? xScale(index)! + 4 : 0}
                  // A little bit lower then the bar parts foot
                  // Cant be above the the bar part, because it would
                  // be behind it. There is no z-index with svg.
                  // The one svg that is build later is on top.
                  y={yScale(toRelativeVal(height[0])) + 15}
                  fill='#eee'
                >
                  {height[1] - height[0]}
                </text>
              </>
            )}
          </g>
        );
      })}
    </>
  );
};

export default StackedBar;
