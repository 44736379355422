// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/text-output/v1/type.ts' --type 'ConfigTextOutputConfig'
export const schemaConfigTextOutput = {
  "$ref": "#/definitions/ConfigTextOutputConfig",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "Config<TextOutputConfig>": {
      "additionalProperties": false,
      "description": "Describes the non-resolved config of a layout element.",
      "type": "object"
    },
    "ConfigTextOutputConfig": {
      "$ref": "#/definitions/Config%3CTextOutputConfig%3E"
    }
  }
}