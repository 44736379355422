import { Config } from '../../../../../pages/augur/type';
import { S3BucketSelectAugurSettings } from '../../s3BucketSelect/v1/type';
import { S3FileSelectAugurSettings } from '../../s3FileSelect/v1/type';
import { SETTINGS_ELEMENT_TYPES } from '../../types/type';
import { AllowedInputs } from '../../types/util';
import { RejectedFeaturesS3V1AugurSettings as RejectedFeaturesS3AugurSettings } from 'common/dist/types/augurSettings/rejectedFeaturesS3V1AugurSettings';

export type { RejectedFeaturesS3AugurSettings };

export type RejectedFeaturesS3Config = {
  s3Bucket: S3BucketSelectAugurSettings;
  s3File: S3FileSelectAugurSettings;
};
export type ConfigRejectedFeaturesS3Config = Config<RejectedFeaturesS3Config>;

export type PartialRejectedFeaturesS3AugurSettings =
  Partial<RejectedFeaturesS3AugurSettings>;

export type RejectedFeaturesS3ValidationError = {};

export const configAllowedInputsRejectedFeaturesS3: AllowedInputs<RejectedFeaturesS3Config> =
  {
    s3Bucket: [
      { type: SETTINGS_ELEMENT_TYPES.S3_BUCKET_SELECT, version: 'v1' },
    ],
    s3File: [{ type: SETTINGS_ELEMENT_TYPES.S3_FILE_SELECT, version: 'v1' }],
  };

export const configTypeRejectedFeaturesS3 = `{
    s3Bucket: { source: 'input-element', elementUuid: string },
    file: { source: 'input-element', elementUuid: string }
}`;

export const augurSettingsTypeRejectedFeaturesS3 = `{
    rejected: string[],
    enforced: string[]
}`;

export const defaultConfigRejectedFeaturesS3: ConfigRejectedFeaturesS3Config = {
  s3Bucket: { source: 'input-element', elementUuid: '' },
  s3File: { source: 'input-element', elementUuid: '' },
};

export const defaultAugurSettingsDefaultRejectedFeaturesS3: PartialRejectedFeaturesS3AugurSettings =
  {
    rejected: [],
    enforced: [],
  };

//JSONSchemaType<RejectedFeaturesS3AugurSettings>
export const defaultValidationSchemaRejectedFeaturesS3 = {};
