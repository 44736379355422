import {
    Constraint,
    ConstraintDetails,
  } from 'common/dist/types/module.optimization';
  import { SPEAKING_CONSTRAINT_LEVEL } from './common';
  import { AugurSettingsDataOpt } from 'common/dist/types/augurSettings';
  
  export const getConstraintDetails = (
    constraint: Constraint,
    augurSettings: Pick<
      AugurSettingsDataOpt,
      'channels' | 'communications' | 'communicationGroups'
    >
  ): ConstraintDetails => {
    switch (constraint.constraintLevel) {
      case 'channel': {
        return {
          speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
          name: (augurSettings.channels || []).find(
            (ch) => ch.id === constraint.channelId
          )?.name,
        };
      }
      case 'communication': {
        return {
          speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
          name: (augurSettings.communications || []).find(
            (ch) => ch.id === constraint.communicationId
          )?.name,
        };
      }
      case 'communicationGroup': {
        return {
          speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
          name: (augurSettings.communicationGroups || []).find(
            (ch) => ch.id === constraint.communicationGroupId
          )?.name,
        };
      }
      case 'allCommunications': {
        return {
          speakingLevel: SPEAKING_CONSTRAINT_LEVEL[constraint.constraintLevel],
        };
      }
    }
  };
  