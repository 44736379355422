import { Point } from 'common/dist/types/reports';
import { scaleBand, scaleLinear } from 'd3-scale';
import React, { FC } from 'react';

import Axis from './Axis';
import Bars from './Bars';
import styles from './styles.module.scss';
import { useDimensions, useThemeColor } from '../../../../../../../utils';
import { SharedInputProps } from '../../../../common/utils';
import commonStyles from '../../../styles.module.scss';
import { MultiReportElementProps } from '../../../types/meta';
import { BarChartConfig, BarChartReportData } from '../type';

export interface PredictedValuesChartData {
  data: (Point[] | undefined)[];
  jobCodes: string[];
}

export type Props = PredictedValuesChartData &
  BarChartConfig &
  SharedInputProps;

export const PredictedValuesChart: FC<Props> = (props) => {
  const { data, jobCodes, xLabel, yLabel } = props;

  const [ref, { width, height }] = useDimensions<HTMLDivElement>();

  const sortedData: number[] = [];

  const xDomainValues: Set<number> = new Set();

  data.reverse().forEach((barData) => {
    if (barData) {
      barData.forEach((point) => {
        xDomainValues.add(point[0]);
        sortedData.push(point[1]);
      });
    }
  });

  const smallestValue = Math.min(...sortedData);
  const biggestValue = Math.max(...sortedData);

  // Calculating Boxplot values to handle outliers
  // Else the color scale would fail to provide colors equally
  sortedData.sort((a, b) => {
    return a - b;
  });

  function getPercentile(percentile: number) {
    return Math.floor(sortedData.length * percentile) ===
      sortedData.length * percentile
      ? sortedData[sortedData.length * percentile]
      : sortedData[Math.floor(sortedData.length * percentile) + 1];
  }

  const median = getPercentile(0.5);
  const percentile025 = getPercentile(0.25);
  const percentile075 = getPercentile(0.75);
  const interquartilleRange = percentile075 - percentile025;
  const whiskerRange = 1;
  const upperWhisker = percentile075 + interquartilleRange * whiskerRange;
  const lowerWhisker = percentile025 - interquartilleRange * whiskerRange;

  const margin = {
    top: 20,
    right: 40,
    bottom: xLabel ? 35 : 20,
    left: yLabel ? 35 : 15,
  };

  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const yLabelPadding = 20;
  const xLabelPadding = 30;

  const translate = `translate(${margin.left}, ${margin.top})`;

  const middleColor = useThemeColor('primary-highlight');
  const lightColor = useThemeColor('primary-highlight', '-lighter90');
  const darkColor = useThemeColor('primary-highlight', '-darker60');
  const veryLightColor = useThemeColor('primary-highlight', '-lighter99');
  const veryDarkColor = useThemeColor('primary-highlight', '-darker90');

  const colorScale = scaleLinear(
    [
      biggestValue,
      upperWhisker >= biggestValue ? biggestValue : upperWhisker,
      median,
      lowerWhisker <= smallestValue ? smallestValue : lowerWhisker,
      smallestValue,
    ],
    [veryDarkColor, darkColor, middleColor, lightColor, veryLightColor]
  );

  const toArray: number[] = [];
  xDomainValues.forEach((element) => {
    toArray.push(element);
  });
  const sortedxDomainValues = toArray.sort((a, b) => a - b);

  const yScale = scaleLinear().range([0, chartHeight]).domain([data.length, 0]);
  const xScale = scaleBand<number>().range([0, chartWidth]).paddingInner(0.2);
  xScale.domain(sortedxDomainValues);
  const xTickScale = scaleLinear().range([0, chartWidth]);

  // --- data available
  return (
    <div ref={ref} className={commonStyles.container}>
      <svg className={commonStyles.chart}>
        <g transform={translate}>
          <g transform={`translate(0,${chartHeight})`}>
            <Axis
              scale={xTickScale}
              ticks={6}
              tickSize={chartHeight}
              direction={'bottom'}
            />
          </g>
          {xLabel && (
            <text
              className={styles.axisLabel}
              x={chartWidth / 2}
              y={chartHeight + xLabelPadding}
              textAnchor={'middle'}
            >
              {xLabel}
            </text>
          )}
          {yLabel && (
            <g transform={`translate(${-yLabelPadding},${chartHeight / 2})`}>
              <text
                className={styles.axisLabel}
                textAnchor={'middle'}
                transform={`rotate(-90)`}
              >
                {yLabel}
              </text>
            </g>
          )}
          {data.map((mapData, index) => {
            return (
              <Bars
                key={index}
                colorScale={colorScale}
                data={mapData}
                position={index}
                jobCode={jobCodes[index]}
                xScale={xScale}
                yScale={yScale}
              />
            );
          })}
        </g>
      </svg>
    </div>
  );
};

export const PredictedValuesChartMulti: FC<
  MultiReportElementProps<BarChartReportData, BarChartConfig>
> = ({ input, config, ...rest }) => {
  const data = input.map((x) => x.reportValue?.data);
  const jobCodes = input.map((x) => x.jobCode);
  return (
    <PredictedValuesChart
      data={data}
      jobCodes={jobCodes}
      {...config}
      {...rest}
    />
  );
};
