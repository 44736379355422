import React, { Component, ComponentType, Fragment } from 'react';
import './styles.scss';
import { FormattedMessage } from 'react-intl';
import ReactLoading from 'react-loading';
import classNames from 'classnames';

import { LinkWithQuery } from '../link-with-query/LinkWithQuery';

export type ButtonProps = {
  /** Is the button supposed to be wrapped in a Link component? Precondition: The button will be a child of a
   * component with react-router injected */
  withLink: boolean;
  /** Target URL of the link */
  linkTo?: string;
  /** On Click listener */
  onClick?: () => void;
  /** Color of the button */
  buttonColor:
    | 'primary'
    | 'secondary'
    | 'blue'
    | 'white'
    | 'green'
    | 'red'
    | 'orange'
    | 'transparent';
  disabled?: boolean;
  /** @deprecated use label instead
   *  Label Intl message ID */
  buttonLabelId?: string;
  /** @deprecated use label instead
   *  Label Intl default message */
  buttonLabelDefault?: string;
  /** Either the separate buttonLabel* must be set or as a complete object here TODO make required once deprecated fields are removed */
  label?: React.ComponentProps<typeof FormattedMessage>;
  /** Render a busy indicator instead of the label */
  isBusy?: boolean;
  /** Optional icon to display on the left next to the text */
  Icon?: ComponentType;
  /** Id to identify the button (for e2e tests for example) */
  buttonId?: string;
  /** Additional classes to add to the button */
  additionalClassNames?: string[];
  /** Additional classes to add to the button text (for example to implement responsiveness) */
  additionalTextClassNames?: string[];
  /** Use HTML type='submit' instead of 'button' */
  isSubmitButton?: boolean;
  title?: string;
};

/**
 * Component to use for all regular sized buttons.
 */
export default class Button extends Component<ButtonProps> {
  static defaultProps = {
    onClick: () => {},
  };

  buttonClassName() {
    const { buttonColor, isBusy, additionalClassNames } = this.props;
    const buttonClassNames = `Button Button--${buttonColor}${
      isBusy ? ' Button--isbusy' : ''
    }`;
    return (
      buttonClassNames +
      (additionalClassNames ? ' ' + additionalClassNames.join(' ') : '')
    );
  }

  renderInnerButton() {
    const {
      Icon,
      isBusy,
      buttonLabelId,
      buttonLabelDefault,
      label,
      additionalTextClassNames,
    } = this.props;
    if (isBusy) {
      return (
        <div className={'Button--busy'} data-testingIdentifier={'busyButton'}>
          <ReactLoading className={'busy'} type={'bubbles'} color={'white'} />
        </div>
      );
    } else {
      return (
        <Fragment>
          {Icon && (
            <div className={'Button--icon'}>
              <Icon />
            </div>
          )}
          <span
            className={classNames((additionalTextClassNames || []).join(' '))}
          >
            {label ? (
              <FormattedMessage {...label} />
            ) : (
              <FormattedMessage
                id={buttonLabelId || 'no-intl-id'}
                defaultMessage={buttonLabelDefault}
              />
            )}
          </span>
        </Fragment>
      );
    }
  }

  renderButton() {
    const {
      withLink,
      disabled,
      isBusy,
      onClick,
      buttonId,
      isSubmitButton,
      buttonLabelDefault,
      title,
    } = this.props;
    return (
      <button
        data-testingIdentifier={buttonLabelDefault}
        type={isSubmitButton ? 'submit' : 'button'}
        className={this.buttonClassName()}
        id={buttonId}
        key={buttonId}
        title={title}
        disabled={disabled}
        // If the button doesn't have a link, the onClick listener must be attached to the button instead of the Link
        {...(withLink
          ? {}
          : { onClick: () => !disabled && !isBusy && onClick && onClick() })}
      >
        {this.renderInnerButton()}
      </button>
    );
  }

  render() {
    const {
      disabled,
      isBusy,
      withLink,
      linkTo,
      onClick,
      buttonLabelDefault,
      label,
    } = this.props;

    // TODO throw errors or only log if the component was misused?
    if ((!buttonLabelDefault && !label) || (buttonLabelDefault && label))
      throw new Error(
        'buttonLabel must be set either in the separate props or passed as one FormattedMessage prop'
      );

    if (withLink && linkTo === undefined) {
      console.error('withLink was set but linkTo is undefined');
    }
    if (withLink && linkTo !== undefined && !disabled) {
      return (
        <LinkWithQuery
          to={linkTo}
          onClick={() => !disabled && !isBusy && onClick && onClick()}
          style={{ textDecoration: 'none' }}
        >
          {this.renderButton()}
        </LinkWithQuery>
      );
    } else {
      return this.renderButton();
    }
  }
}
