import classNames from 'classnames';
import {
  BiographyEntry,
  BiographyType,
} from 'common/dist/types/augurBiography';
import { formatDate } from 'common/dist/utils/dates';
import qs from 'qs';
import React, { FC } from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import ErroneousEntry from './entries/ErroneousEntry';
import EvaluationEntry from './entries/EvaluationEntry';
import LearningEntry from './entries/LearningEntry';
import PredictionEntry from './entries/PredictionEntry';
import TimestampAndLine from './entries/TimestampAndLine';
import styles from './styles.module.scss';
import {
  locationWithRemovedQuery,
  locationWithUpdatedQuery,
} from '../../../utils';
import { AugurDetailsQueryParams } from '../../modelManagement/modules/common/augur-details-tabs/types';

export type Props = {
  biography: BiographyType;
  /** might be "undefined" while the data sources are still loading */
  dsType?: string;
  /** might be "undefined" while the data sources are still loading */
  dsCode?: string;
  selectable: boolean;
};

function renderGap() {
  return (
    <div className={classNames(styles.entry, styles.entryGap)}>
      <TimestampAndLine createdAt={null} renderDate={false} />

      <div className={styles.entryBody}>
        <FiMoreHorizontal size={16} />
      </div>
    </div>
  );
}

function shouldRenderDate(
  entry: BiographyEntry | undefined,
  previousEntry: BiographyEntry
) {
  if (!entry || !previousEntry) return true;
  return (
    formatDate(new Date(entry.createdAt), 'dd.MM.yyyy') !==
    formatDate(new Date(previousEntry.createdAt), 'dd.MM.yyyy')
  );
}

const AugurBiographyList: FC<
  Props & WrappedComponentProps & RouteComponentProps
> = ({ biography, dsType, dsCode, location, history, selectable }) => {
  const queryParams: AugurDetailsQueryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  function changeQueryParameters(entry) {
    if (entry.jobType === 'learning') {
      //Deselect option
      if (entry.modelCode === queryParams.modelCode) {
        history.push(locationWithRemovedQuery(location, ['modelCode']));
      } else {
        //Select Option
        history.push(
          locationWithUpdatedQuery(location, {
            modelCode: entry.modelCode,
          })
        );
      }
    }
    // Evaluation/ Prediction
    else {
      //Deselect option
      if (entry.modelCode === queryParams.modelCode) {
        history.push(locationWithRemovedQuery(location, ['modelCode']));
      } else {
        //Select option
        history.push(
          locationWithUpdatedQuery(location, {
            modelCode: entry.modelCode,
          })
        );
      }
    }
  }

  return (
    <div className={styles.augurBiographyList}>
      <div className={styles.entries}>
        {biography.entries.map((entry, i) => {
          const renderDate = shouldRenderDate(entry, biography.entries[i - 1]);
          if (entry.error)
            return <ErroneousEntry entry={entry} renderDate={renderDate} />;
          let entryComponent;

          switch (entry.jobType) {
            case 'learning':
              entryComponent = (
                <LearningEntry
                  entry={entry}
                  renderDate={renderDate}
                  selected={
                    selectable && entry.modelCode === queryParams.modelCode
                  }
                  selectable={selectable}
                />
              );
              break;
            case 'evaluation':
              entryComponent = (
                <EvaluationEntry
                  entry={entry}
                  renderDate={renderDate}
                  selected={
                    selectable && entry.modelCode === queryParams.modelCode
                  }
                  selectable={selectable}
                />
              );
              break;
            case 'prediction':
              entryComponent = (
                <PredictionEntry
                  entry={entry}
                  renderDate={renderDate}
                  selected={
                    selectable && entry.modelCode === queryParams.modelCode
                  }
                  selectable={selectable}
                />
              );
              break;
          }
          return (
            <div
              key={i}
              onClick={() => {
                selectable && changeQueryParameters(entry);
              }}
            >
              {entryComponent}
            </div>
          );
        })}
        {biography.overlappingWithGap && renderGap()}
        {biography.overlappingLearning && (
          <div key={'overlappingLearning'}>
            <LearningEntry
              entry={biography.overlappingLearning}
              renderDate={true}
              selected={false}
              selectable={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(withRouter(AugurBiographyList));
