import { DataSource } from 'common/dist/types/dataManagement/dataSource';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import Cassandra from './cassandra/Cassandra.container';
import Upload from './cassandra/upload/Upload.container';
import ManageDataSourcesContainer from './manage-ds/ManageDataSources.container';
import { dataManagementRoutes } from './routes';
import CreateBucketWizard from './s3/create/CreateBucketWizard';
import S3 from './s3/S3';
import UploadS3 from './s3/upload/Upload.container';
import { toLinkWhitelistQuery } from '../../utils';
import Busy from '../atoms/busy/Busy';
import Button from '../atoms/button/Button';
import IndicatorEmpty from '../molecules/indicator-empty/IndicatorEmpty';
import { MAIN_MENU_QUERY_WHITELIST } from '../organisms/main-menu/MainMenu';
import MainContainer from '../pages/main-container/MainContainer';
import NoPermissions from '../pages/no-permissions/NoPermissions';

type Props = {
  /** Injected by react-router-dom withRouter() */
  history: {
    push: (url: string) => void;
    location: {
      pathname: string;
    };
  };
  dataSources: {
    loading: boolean;
    loaded: boolean;
    error: string;
    /** List of available data sources */
    data: DataSource[];
  };
  fetchDataSources: () => void;
  isAdmin: boolean;
  missingPermissionsError: boolean;
};

class DataManagement extends Component<Props & RouteComponentProps> {
  getSelectedTab() {
    // TODO This is asking for trouble. Find a better implementation to get the active tab!
    const path = this.props.history.location.pathname;
    if (!path) return null;
    const rep = path.replace('/app/data/', '');
    const parts = rep.split('/');
    const first = parts[0];
    if (first === dataManagementRoutes.manage) {
      return first;
    } else {
      const type = parts[0];
      const code = parts[1];
      return `${type}/${code}`;
    }
  }

  componentDidMount() {
    const { fetchDataSources } = this.props;
    fetchDataSources();
  }

  renderPermissionsError() {
    return <NoPermissions fullViewHeight />;
  }

  renderLoading() {
    return (
      <MainContainer fullWidth>
        <Busy isBusy />
      </MainContainer>
    );
  }

  renderError() {
    const {
      dataSources: { error },
    } = this.props;
    return (
      <div className='content container--data'>{JSON.stringify(error)}</div>
    );
  }

  renderEmpty() {
    return (
      <MainContainer fullWidth>
        <IndicatorEmpty
          classNameImage={'data-sources-empty-pic'}
          headlineId={'no-id'}
          headlineDefault={'There are no managed Data Sources yet'}
          descriptionId={'no-id'}
          descriptionDefault={'Please add your first Data Source'}
          Actions={() => (
            <Button
              buttonColor={'secondary'}
              buttonLabelId={'no-id'}
              buttonLabelDefault={'Add Data Source'}
              withLink={true}
              linkTo={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}/add`}
            />
          )}
        />
      </MainContainer>
    );
  }

  renderLoaded() {
    const {
      dataSources,
      isAdmin,
      history: { location },
    } = this.props;
    // const selectedDataSourceCode = this.getActiveDataSource();
    // const handleSelect = (typeAndCode) => history.push(`${dataManagementRoutes.basePath}/${typeAndCode}`);

    return (
      <MainContainer fullWidth additionalClassname={'container--data'}>
        <Switch>
          {/* Upload to Cassandra Wizard */}
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/cassandra/:dataSourceCode/upload`}
            component={Upload}
          />

          {/* Upload to S3 Wizard */}
          <Route
            exact
            path={`${dataManagementRoutes.basePath}/s3/:dataSourceCode/upload`}
            component={UploadS3}
          />

          <Route
            exact
            path={`${dataManagementRoutes.basePath}/s3/:dataSourceCode/create`}
            component={CreateBucketWizard}
          />

          {dataSources &&
            dataSources.data &&
            dataSources.data.map((ds) => (
              <Route
                key={ds.code}
                path={`${dataManagementRoutes.basePath}/${ds.ds_type}/${ds.code}`}
                component={() => {
                  if (ds.ds_type === 'cassandra') {
                    return <Cassandra type={'cassandra'} {...ds} />;
                  } else if (ds.ds_type === 's3') {
                    return <S3 type={'s3'} {...ds} />;
                  } else {
                    return null;
                  }
                }}
              />
            ))}

          {isAdmin && (
            <Route
              path={`${dataManagementRoutes.basePath}/${dataManagementRoutes.manage}`}
              component={() => <ManageDataSourcesContainer />}
            />
          )}

          {dataSources && dataSources.data && dataSources.data.length > 0 && (
            <Redirect
              exact
              path={dataManagementRoutes.basePath}
              to={toLinkWhitelistQuery(
                `${dataManagementRoutes.basePath}/${dataSources.data[0].ds_type}/${dataSources.data[0].code}`,
                location,
                MAIN_MENU_QUERY_WHITELIST
              )}
            />
          )}
        </Switch>
      </MainContainer>
    );
  }

  render() {
    const {
      dataSources: { loading, error, data },
      missingPermissionsError,
    } = this.props;

    const isEmpty = data && data.length === 0;
    if (missingPermissionsError) return this.renderPermissionsError();
    else if (loading) return this.renderLoading();
    else if (error) return this.renderError();
    else if (isEmpty) return this.renderEmpty();
    else return this.renderLoaded();
  }
}

export default withRouter(DataManagement);
