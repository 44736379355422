import {
  FiCopy,
  FiCornerUpLeft,
  FiDownload,
  FiEdit3,
  FiLink2,
  FiMaximize,
  FiXSquare,
} from 'react-icons/fi';
import { DeprecatedRootState } from '../../../../store/state.type';
import {
  copyNotebookToClipboard,
  showEditNotebook,
} from '../../../../redux/workbench/modules/notebook.module';
import { Dispatch } from 'redux-act';
import { notificationCopy } from '../../../../redux/workbench/notifications/notifications';
import { info as infoNotification } from 'react-notification-system-redux';
import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import { notebookUser } from '../../../../redux/workbench/selectors/notebookUser.selector';
import {
  restoreContent,
  showDeleteContent,
} from '../../../../redux/workbench/modules/content.module';
import {
  ContentElement,
  ContentElementType,
} from '../generic-file-browser/GenericFileBrowser';
import { getSelectedDirPathQuery, openFile } from '../../../workbench/utils';
import { download } from './download';
import { showEditDirectory } from '../../../../redux/workbench/modules/directory.module';
import { ContextMenuEntry } from './ContextMenu';
import { RouteComponentProps } from 'react-router';

export const CopyName = {
  title: {
    id: 'no-id',
    defaultMessage: 'Copy Name',
  },
  icon: FiLink2,
  copy: (path: string, name: string) => ({
    data: name,
  }),
};
export const CopyPath = {
  title: {
    id: 'no-id',
    defaultMessage: 'Copy Path',
  },
  icon: FiLink2,
  copy: (path: string, name: string) => ({
    data: `/workbench/${path}`,
  }),
};

export const OpenNotebook: ContextMenuEntry = {
  title: {
    id: 'no-id',
    defaultMessage: 'Open',
  },
  icon: FiMaximize,
  onClick: openFile,
};
export const OpenDir = {
  title: {
    id: 'no-id',
    defaultMessage: 'Open',
  },
  icon: FiMaximize,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    history.push(
      `${history.location.pathname}${getSelectedDirPathQuery([
        ...selectedPath,
        element.name,
      ])}`
    );
  },
};
export const Copy = {
  title: {
    id: 'no-id',
    defaultMessage: 'Copy',
  },
  icon: FiCopy,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    dispatch(
      copyNotebookToClipboard({ path: element.path, name: element.name })
    );
    dispatch(infoNotification(notificationCopy('notebook', false)));
  },
};
export const Edit = {
  title: {
    id: 'no-id',
    defaultMessage: 'Edit',
  },
  icon: FiEdit3,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    // @ts-ignore: Type actions/reducers first
    dispatch(showEditNotebook(element.name, element.path));
  },
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.editNotebook}`,
};

export const EditDirectory = {
  title: {
    id: 'no-id',
    defaultMessage: 'Edit',
  },
  icon: FiEdit3,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    // @ts-ignore: Type actions/reducers first
    dispatch(showEditDirectory(element.name, element.path, ''));
  },
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.editDirectory}`,
};
export const Download = {
  title: {
    id: 'no-id',
    defaultMessage: 'Download',
  },
  icon: FiDownload,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    download(
      element.name,
      `/jupyter/user/${notebookUser(state)}/files/${element.path}`
    );
  },
};

function getDeleteContentType(type: ContentElementType): string {
  switch (type) {
    case ContentElementType.FILE_NOTEBOOK:
      return 'notebook';
    case ContentElementType.DIRECTORY_PLAIN:
    case ContentElementType.DIRECTORY_REPOSITORY:
      return 'directory';
    default:
      return 'file';
  }
}

export const Delete = {
  title: {
    id: 'no-id',
    defaultMessage: 'Delete',
  },
  icon: FiXSquare,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    dispatch(
      // @ts-ignore: Type actions/reducers first
      showDeleteContent(element.path, getDeleteContentType(element.type))
    );
  },
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${workbenchRoutes.deleteContent}`,
};

export const Restore = {
  title: {
    id: 'no-id',
    defaultMessage: 'Restore',
  },
  icon: FiCornerUpLeft,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    dispatch(restoreContent(element.name));
  },
};

export const DeletePermanently = {
  title: {
    id: 'no-id',
    defaultMessage: 'Delete Permanently',
  },
  icon: FiXSquare,
  onClick: (
    state: DeprecatedRootState,
    dispatch: Dispatch,
    history: RouteComponentProps['history'],
    selectedPath: string[],
    element: Omit<ContentElement, 'content'>
  ) => {
    dispatch(
      // @ts-ignore: Type actions/reducers first
      showDeleteContent(element.path, getDeleteContentType(element.type), true)
    );
  },
  linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.recycleBin}${workbenchRoutes.deleteContent}`,
};
