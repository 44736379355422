import React, { Component } from 'react';
import { HierarchyPointNode, hierarchy, tree as treeLayout } from 'd3-hierarchy';
import { TransitionGroup } from 'react-transition-group';
import { expand, getDepth } from './treeChart/tree';
import Node from './Node';
import Link from './Link';
import 'react-tooltip/dist/react-tooltip.css';
import NodeTooltip from './NodeTooltip';
import Tooltip from '../../../../../atoms/tooltip/Tooltip';
import { ClassificationTreeNode } from './type';
import { BinaryTreeState } from './BinaryTreeShadowModel';
import { TreeNode } from './Node';
import { createPortal } from 'react-dom';

const MAX_DEPTH = 12;
const LEVEL_HEIGHT = 45;
const LEVEL_PADDING = 20;
const LEVEL_PADDING_WIDTH = 15;
const WIDTH = 200;

interface Props {
  data: {
    root: ClassificationTreeNode;
  }
  state: BinaryTreeState;
  dispatch: (action: any) => void;
  nodePositiveClassName: string;
  nodeNegativeClassName: string;
  nodeLeafClassName: string;
  linkShapeFunc(...args: unknown[]): unknown;
  linkThicknessFunc(...args: unknown[]): unknown;
  adjustTreeFunc(...args: unknown[]): any;
  animationDuration: {
    mount: {
      delay: number;
      duration: number;
    };
    update: {
      delay: number;
      duration: number;
    };
    exit: {
      delay: number;
      duration: number;
    };
  };
  expandHeight?: number;
  initialHeight?: number;
  margins: {
    top: number;
    left: number;
    right: number;
    bottom: number;
  };
}
interface State {
  dataRoot: TreeNode;
  currentDepth: number;
  parentWidth: number;
  parentHeight: number;
}

class Tree extends Component<Props, State> {
  private svgRef: SVGSVGElement | null;
  private treeChartRef: React.RefObject<HTMLInputElement>;

  static defaultProps = {
    nodePositiveClassName: 'tree-chart_node--positive',
    nodeNegativeClassName: 'tree-chart_node--negative',
    nodeLeafClassName: 'tree-chart_node--leaf',
    expandHeight: 1,
    initialHeight: 4,
    padding: 10,
    margins: { top: 20, left: 20, bottom: 20, right: 20 },
  };

  constructor(props: Props) {
    super(props);

    const dataRoot = expand(
      Object.assign({}, props.data.root),
      0,
      props.initialHeight
    );
    const depth = getDepth(dataRoot);
    this.state = {
      currentDepth: depth,
      dataRoot,
      parentWidth: 0,
      parentHeight: 0,
    };

    this.svgRef = null;
    this.treeChartRef = React.createRef();
    this.setSvgRef = this.setSvgRef.bind(this);
    this.adjustTree = this.adjustTree.bind(this);
  }

  componentDidMount() {
    const parentWidth =
      this.treeChartRef.current?.getBoundingClientRect().width || 0;
    const parentHeight =
      this.treeChartRef.current?.getBoundingClientRect().height || 0;
    this.setState({ parentWidth });
  }

  componentDidUpdate(_prevProps: Props, _prevState: State, _snapshot: any) {
    const parentWidth =
      this.treeChartRef.current?.getBoundingClientRect().width || 0;
    if (parentWidth !== this.state.parentWidth) {
      this.setState({ parentWidth });
    }
  }

  setSvgRef(ref: SVGSVGElement | null) {
    this.svgRef = ref;
  }

  adjustTree(node: HierarchyPointNode<TreeNode>) {
    if (node.depth === 0) {
      // The root node was clicked -> Reset the tree
      const dataRoot = expand(
        Object.assign({}, this.props.data.root),
        0,
        this.props.initialHeight
      );
      const depth = getDepth(dataRoot);
      this.setState({
        dataRoot,
        currentDepth: depth,
      });
    } else {
      // A node that is not the root was clicked
      const dataRoot = this.props.adjustTreeFunc(node, this.props.expandHeight);
      const depth = getDepth(dataRoot);
      this.setState({
        dataRoot,
        currentDepth: depth,
      });
    }
  }

  render() {
    const {
      state,
      dispatch,
      nodePositiveClassName,
      nodeNegativeClassName,
      nodeLeafClassName,
      linkShapeFunc,
      linkThicknessFunc,
      animationDuration,
      margins,
    } = this.props;
    const { dataRoot, currentDepth } = this.state;

    const depth = Math.min(MAX_DEPTH, currentDepth);
    const root = hierarchy(dataRoot, (node) => node.renderedChildren) as HierarchyPointNode<TreeNode>;

    const tree = treeLayout().size([WIDTH * 2, LEVEL_HEIGHT * depth]);

    tree(root);

    const resultWidth = Math.max(
      WIDTH * 2 + LEVEL_PADDING_WIDTH,
      this.state.parentWidth
    );
    const resultHeight = (LEVEL_HEIGHT + LEVEL_PADDING) * (currentDepth - 1);

    const nodes = root.descendants();
    const links = root.links();
    const viewBox = `0, 0, ${resultWidth}, ${resultHeight}`;
    // padding-bottom hack to scale the inline svg to the container width
    // see https://css-tricks.com/scale-svg/#article-header-id-10
    return (
      <div
        className='tree-chart'
        // style={{ paddingBottom: `${100 * (resultHeight / resultWidth)}%` }}
        ref={this.treeChartRef}
      >
        {createPortal(
          <Tooltip
            anchorSelect='.tree-chart_node'
            place='bottom'
            className={'tree-chart_tooltip'}
          >
            <NodeTooltip 
              state={state}
            />
          </Tooltip>,
          document.body
        )}
        <svg
          ref={this.setSvgRef}
          viewBox={viewBox}
          preserveAspectRatio='xMinYMin meet'
        >
          <g transform={`translate(${margins.left}, ${margins.top})`}>
            <TransitionGroup component={null}>
              {links.map((link) => (
                <Link
                  state={state}
                  totalRecordCount={Number(dataRoot.recordCount)}
                  key={`${link.source.data.id}-${link.target.data.id}`}
                  link={link}
                  linkShapeFunc={linkShapeFunc}
                  linkThicknessFunc={linkThicknessFunc}
                  animationDuration={animationDuration}
                />
              ))}
              {nodes.map((node) => (
                <Node
                  state={state}
                  dispatch={dispatch}
                  key={node.data.id}
                  node={node}
                  positiveClassName={nodePositiveClassName}
                  negativeClassName={nodeNegativeClassName}
                  leafClassName={nodeLeafClassName}
                  onClickCallback={this.adjustTree}
                  animationDuration={animationDuration}
                />
              ))}
            </TransitionGroup>
          </g>
        </svg>
      </div>
    );
  }
}

export default Tree;
