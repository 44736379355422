import classNames from 'classnames';
import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';

type Props = {
  title: MessageDescriptor;
  linkTo: string;
  buttonLabelDefault: string;
  buttonLabelId?: string;
};

const NoElements: FC<Props> = (props) => {
  const { linkTo, buttonLabelDefault, buttonLabelId, title } = props;

  return (
    <div className={styles.noElements}>
      <div className={styles.noElementsContent}>
        <div className={classNames(styles.augurPic, 'augur--pic')} />
        <FormattedMessage {...title} />
      </div>
    </div>
  );
};

export default NoElements;
