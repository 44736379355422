import classNames from 'classnames';
import { BiographyEntryLearning } from 'common/dist/types/augurBiography';
import React, { FC } from 'react';
import { FiChevronsRight } from 'react-icons/fi';

import CommonEntryParts from './CommonEntryParts';
import TimestampAndLine from './TimestampAndLine';
import styles from '../styles.module.scss';

type Props = {
  entry: BiographyEntryLearning;
  renderDate: boolean;
  selectable: boolean;
  selected: boolean;
};

const LearningEntry: FC<Props> = ({
  entry,
  renderDate,
  selected,
  selectable,
}) => {
  return (
    <div
      className={classNames(styles.entry, styles.entryLearning, {
        [styles.selected]: selected,
        [styles.selectable]: selectable,
      })}
    >
      <TimestampAndLine createdAt={entry.createdAt} renderDate={renderDate} />

      <div className={styles.entryBody}>
        <CommonEntryParts entry={entry} isReactivated={entry.isReactivated} />

        <div className={styles.info}>
          <div className={styles.infoTitle}>Model Code:</div>
          <div className={styles.infoValue}>{entry.modelCode}</div>
        </div>
      </div>
      {selected && (
        <div className={styles.selectedMarker}>
          <FiChevronsRight size={16} />
        </div>
      )}
    </div>
  );
};

export default LearningEntry;
