import { getElements } from './config';
import { transformConfigToConfigProps } from './transformation';
import {
  CFEAugurSettings,
  DropdownSelectCFEConfig,
  OptionsType,
} from '../../../molecules/augur-layout-elements/settings-elements/dropdown-select-cfe/v1/type';
import { SETTINGS_ELEMENT_TYPES } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import {
  AugurReportElement,
  AugurSettingsElement,
  ModuleConfiguration,
} from '../type';

export type CFEConfigProperties = {
  options: OptionsType[];
};

const cfeTypes = [SETTINGS_ELEMENT_TYPES.DROPDOWN_SELECT] as const;

type VisibilityCheck = (data: Record<string, unknown>) => boolean;

/**
 * Determines whether an element type is a CFE type
 * @param elementType type of a layout element
 */
export function isCFEType(
  elementType: string
): elementType is typeof cfeTypes[number] {
  return cfeTypes.includes(elementType as typeof cfeTypes[number]);
}

/**
 * Checks whether an element is a CFE (Control Flow Element) or not.
 * @param element a layout element
 */
export const isCFE = (
  element: AugurSettingsElement | AugurReportElement
): element is AugurSettingsElement<typeof cfeTypes[number]> =>
  isCFEType(element.type);

const isCFEAugurSettings = (data: unknown): data is CFEAugurSettings => {
  return Array.isArray(data) && data.every((item) => typeof item === 'string');
};

/**
 * Filters all elements of a ModuleConfiguration objects that do not pass the checks of the given visibility
 *   dependency lookup table with the given AugurSettings.
 * @param config ModuleConfiguration objects
 * @param visibilityDependencyChecks lookup table for visibility dependency checks
 * @param settings the current AugurSettings
 */
export function filterConfigForElementVisibility(
  config: ModuleConfiguration,
  visibilityDependencyChecks: Record<string, Array<VisibilityCheck>>,
  settings: Record<string, unknown>
): ModuleConfiguration {
  return {
    ...config,
    augurSettingsConfiguration: config.augurSettingsConfiguration.map(
      (page) => ({
        ...page,
        elements: page.elements.filter((element) =>
          visibilityDependencyChecks[element.uuid].every((check) =>
            check(settings)
          )
        ),
        elementArrangement: page.elementArrangement.filter((layout) =>
          visibilityDependencyChecks[layout.i].every((check) => check(settings))
        ),
      })
    ),
  };
}

/**
 * Recursive helper function to traverse visibility dependency tree and add visibility check to lookup map.
 * DFS to detect cycles in the dependency tree.
 * @param id element id from which algorithm starts its cycle search
 * @param queue Current traversal chain of DFS
 * @param visited Set of ids of previously visited elements
 * @param mappedElements Map of layout elements accessible by their uuid
 * @throws Error if a cycle is detected in the visibility dependency tree.
 */
function detectCycle(
  id: string,
  queue: string[],
  visited: Set<string>,
  mappedElements: {
    [id: string]: AugurReportElement | AugurSettingsElement;
  }
) {
  // node already in queue, cycle detected
  if (queue.includes(id))
    throw new Error('Cycle detected in visibility dependencies.');

  // already checked this element
  if (visited.has(id)) return;

  // add element to visited array
  visited.add(id);

  const element = mappedElements[id];
  if (isCFE(element)) {
    const config: CFEConfigProperties = transformConfigToConfigProps(
      element.config
    );
    config.options.forEach((option) => {
      (option.dependants || []).forEach((dependantId) => {
        detectCycle(dependantId, [...queue, id], visited, mappedElements);
      });
    });
  }
}

export function hasCycle(
  elements: {
    [id: string]: AugurReportElement | AugurSettingsElement;
  },
  startId: string
) {
  try {
    detectCycle(startId, [], new Set(), elements);
    return false;
  } catch (e) {
    console.debug('Cycle Detection Error: ', e);
    return true;
  }
}

/**
 * Recursive helper function to traverse visibility dependency tree and add visibility check to lookup map.
 * DFS to add visibility check to each successor of the given element.
 * Doesn't return a value but modifies the given visibilityChecks object.
 * Has built-in cycle detection.
 * @param id uuid of a dependent elements that requires a visibility check
 * @param queue Current traversal chain of DFS
 * @param visited Set of ids of previously visited elements
 * @param mappedElements Map of layout elements accessible by their uuid
 * @param check Function that returns a boolean based on the value in the given AugurSettings
 * @param visibilityChecks Map of visibility checks for each element accessible by id, PARAMETER IS MODIFIED DURING COMPUTATION
 * @throws Error if a cycle is detected in the visibility dependency tree.
 */
function visibilityCheckHelper(
  id: string,
  queue: string[],
  visited: Set<string>,
  mappedElements: {
    [id: string]: AugurReportElement | AugurSettingsElement;
  },
  check: VisibilityCheck,
  visibilityChecks: Record<string, Array<VisibilityCheck>>
): Record<string, Array<VisibilityCheck>> {
  // node already in queue, cycle detected
  if (queue.includes(id))
    throw Error('Cycle detected in visibility dependencies.');

  // already added checks to this component
  if (visited.has(id)) return;

  // add element to visited array
  visited.add(id);

  visibilityChecks[id].push(check);
  const element = mappedElements[id];
  if (isCFE(element)) {
    const config: CFEConfigProperties = transformConfigToConfigProps(
      element.config
    );
    config.options.forEach((option) => {
      (option.dependants || []).forEach((dependantId) => {
        visibilityCheckHelper(
          dependantId,
          [...queue, id],
          visited,
          mappedElements,
          check,
          visibilityChecks
        );
      });
    });
  }
}

/**
 * Builds a lookup table for each element that holds a list of checks that take AugurSettings and
 *   return a boolean that indicates whether an element should be visible or not.
 * @param moduleConfig ModuleConfiguration to obtain the elements
 * @throws Error if a cycle is detected in the dependencies
 * @returns a map of type { [uuid: string] -> Array<(data: Record<string, unknown>) => boolean> }
 */
export function getVisibilityDependencyArray(
  moduleConfig: ModuleConfiguration
): Record<string, Array<VisibilityCheck>> {
  const elements = getElements(moduleConfig);
  const cfeElements = elements.filter(isCFE);

  // create a lookup table for elements to avoid having to search for an element multiple times
  const mappedElements = Object.fromEntries(elements.map((e) => [e.uuid, e]));

  // prefill lookup table with empty arrays
  const visibilityChecks: Record<
    string,
    Array<VisibilityCheck>
  > = elements.reduce(
    (acc, element) => ({
      ...acc,
      [element.uuid]: [] as Array<VisibilityCheck>,
    }),
    {} as Record<string, Array<VisibilityCheck>>
  );

  // construct visibility check lookup table by iterating over all CFE and their options and starting a DFS from its dependants
  // for each successor (direct and transitive) the specific visibility check of each option is added
  cfeElements.forEach((element) => {
    const config: DropdownSelectCFEConfig = transformConfigToConfigProps(
      element.config
    );
    config.options.forEach((option) => {
      const check: VisibilityCheck = (data) => {
        // because element is a CFE its output must be string[]
        const value = data[element.uuid];
        return isCFEAugurSettings(value) && value.includes(option.value);
      };
      return (option.dependants || []).forEach((dependantId) => {
        visibilityCheckHelper(
          dependantId,
          [element.uuid],
          new Set([element.uuid]),
          mappedElements,
          check,
          visibilityChecks
        );
      });
    });
  });

  return visibilityChecks;
}
