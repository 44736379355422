import React, { ComponentProps, FC, useEffect, useState } from 'react';
import Wizard from '../../../pages/wizard/Wizard';
import GenericFormStep from '../../../molecules/generic-form-step/GenericFormStep';
import {
  Data as FormData,
  ErrorType,
  fieldDescription,
  fieldJobGroup,
  fieldName,
  form,
  formName,
} from './form';
import { Field, InjectedFormProps, isValid } from 'redux-form';
import { WrappedTextInputLine } from '../../../atoms/input-elements/text-input-line/TextInputLine';
import { WrappedTextInputArea } from '../../../atoms/input-elements/text-input-area/TextInputArea';
import { orchestrationRoutes } from '../../routes';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../store/store';
import { JobGroupInputType } from 'common/dist/types/job';
import {
  addJobGroup,
  fetchJobGroup,
  updateJobGroup,
} from '../../../../store/jobGroups/slice';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Summary } from 'common/dist/types/habitat';
import {
  jobGroupField,
  newScheduleFormName,
} from '../../job-schedules/add-schedule/newSchedule.form';
import JobGroupEditorField from '../../job-schedules/add-schedule/steps/JobGroupEditorField';
import { StepComponent as JobGroupStepComponent } from '../../job-schedules/add-schedule/steps/job-group/StepComponent';

export interface Props {
  edit?: boolean;
  jobGroupCode?: string;
  jobGroupSubmitting?: boolean;
}

const JobGroupWizard: FC<
  Props & InjectedFormProps<FormData, Props, ErrorType> & RouteComponentProps
> = (props) => {
  const {
    handleSubmit,
    history,
    edit,
    jobGroupCode,
    destroy,
    touch,
    initialValues,
    jobGroupSubmitting,
  } = props;
  const appDispatch = useAppDispatch();

  const [jobPickerIsActive, setjobPickerIsActive] = useState(false);
  const formIsValid = useSelector(isValid(formName));
  const summary = useSelector<RootState, Summary>((state) => state.summary);

  // componentDidMount effect for fetching info
  useEffect(() => {
    if (edit) {
      appDispatch(fetchJobGroup({ jobGroupCode: jobGroupCode }));
    }
  }, [appDispatch]);

  useEffect(() => {
    if (edit) {
      touch(fieldName, fieldDescription, fieldJobGroup);
    }
  }, [initialValues]);

  // componentWillUnmount to destroy form explicitly now that fields are no longer needed
  useEffect(() => {
    return destroy;
  }, []);

  if (jobPickerIsActive) {
    return (
      <Field
        name={fieldJobGroup}
        component={JobGroupEditorField}
        onClickSave={() => setjobPickerIsActive(false)}
      />
    );
  } else {
    return (
      <form
        onSubmit={handleSubmit((values: FormData) => {
          const jobGroup: JobGroupInputType = {
            ...values[fieldJobGroup],
            name: values[fieldName],
            description: values[fieldDescription],
            trigger: 'manual',
            priority: 'medium', // Is the default in the backend, but should still not be missing
          };
          if (edit) {
            appDispatch(
              updateJobGroup({
                jobGroupCode,
                jobGroup,
                callback: (success: boolean) => {
                  history.push(
                    `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroups.path}`
                  );
                },
              })
            );
          } else {
            appDispatch(
              addJobGroup({
                jobGroup,
                callback: (success: boolean) => {
                  history.push(
                    `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroups.path}`
                  );
                },
              })
            );
          }
        })}
        style={{ height: '100%' }}
      >
        <Wizard
          withHeadline={true}
          headlineDefault={`${edit ? 'Edit' : 'Add'} Job Group`}
          buttons={[
            {
              buttonId: 'wizard.cancel',
              buttonLabelDefault: 'Cancel',
              buttonColor: 'white',
              withLink: true,
              linkTo: `${orchestrationRoutes.basePath}/${orchestrationRoutes.jobGroups.path}`,
              disabled: jobGroupSubmitting,
            },
            {
              buttonId: 'wizard.finish',
              buttonLabelDefault: 'Finish',
              buttonColor: 'secondary',
              isSubmitButton: true,
              disabled: !formIsValid || jobGroupSubmitting,
              isBusy: jobGroupSubmitting,
              withLink: false,
            },
          ]}
        >
          <GenericFormStep
            fieldName={fieldName}
            formName={formName}
            component={WrappedTextInputLine}
            renderError={false}
            title={{
              id: 'orchestration.add_edit_job_group.field_name.name',
              defaultMessage: 'Name',
            }}
            description={{
              id: 'orchestration.add_edit_job_group.field_name.description',
              defaultMessage: 'Enter a name for the Job Group',
            }}
            num={1}
            fieldProps={
              {
                placeholderId:
                  'orchestration.add_edit_job_group.field_name.name',
                placeholderDefault: 'Name',
                labelId:
                  'orchestration.add_edit_job_group.field_name.description',
                labelDefault: 'Enter a name for the Job Group',
                hasLabel: true,
              } as ComponentProps<typeof WrappedTextInputLine>
            }
          />
          <GenericFormStep
            fieldName={fieldDescription}
            formName={formName}
            component={WrappedTextInputArea}
            renderError={false}
            title={{
              id: 'orchestration.add_edit_job_group.field_description.name',
              defaultMessage: 'Description',
            }}
            description={{
              id: 'orchestration.add_edit_job_group.field_description.description',
              defaultMessage: 'Enter a description for the Job Group',
            }}
            num={2}
            fieldProps={
              {
                hasLabel: true,
                label: {
                  id: 'orchestration.add_edit_job_group.field_description.name',
                  defaultMessage: 'Description',
                },
                placeholder: {
                  id: 'orchestration.add_edit_job_group.field_description.name',
                  defaultMessage: 'Description',
                },
              } as ComponentProps<typeof WrappedTextInputArea>
            }
          />
          <GenericFormStep
            fieldName={jobGroupField}
            formName={newScheduleFormName}
            component={JobGroupStepComponent}
            title={{
              id: 'newSchedule.stepJobGroup.name',
              defaultMessage: 'Design the Job Group you want to schedule',
            }}
            description={{
              id: 'newSchedule.stepJobGroup.description',
              defaultMessage:
                'Add the Jobs you want to execute and create dependencies by connecting them',
            }}
            num={3}
            fieldProps={{
              onClick: (e) => setjobPickerIsActive(true),
            }} //  as ComponentProps<typeof JobGroupStepComponent>
          />
        </Wizard>
      </form>
    );
  }
};

export default withRouter(JobGroupWizard);
