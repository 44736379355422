import classNames from 'classnames';
import { SearchResults } from 'common/dist/types/responseBodies/dashboard';
import { debounce } from 'lodash';
import React, { Component } from 'react';
import { FiX } from 'react-icons/fi';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import { Field, InjectedFormProps } from 'redux-form';

import QueryInput from './QueryInput';
import styles from './styles.module.scss';
import SearchResult from '../search-result/SearchResult';

export type Props = {
  reset: () => void;
  handleSubmit: (query: string) => void;
  loading: boolean;
  loaded: boolean;
  result: SearchResults;
  search: (query: string) => void;
  query?: string;
  error?: string;
};

type State = {
  closed: boolean;
};

class SearchBar extends Component<
  Props & WrappedComponentProps & InjectedFormProps,
  State
> {
  searchDebounced = null;

  constructor(props) {
    super(props);

    this.state = {
      closed: true,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.showSuggestions = this.showSuggestions.bind(this);
    this.closeSuggestions = this.closeSuggestions.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentDidMount() {
    this.searchDebounced = debounce(this.props.search, 500);
  }

  handleClickOutside() {
    if (!this.state.closed) {
      this.setState({ closed: true });
    }
  }

  showSuggestions() {
    if (this.state.closed) {
      this.setState({ closed: false });
    }
  }

  closeSuggestions() {
    if (!this.state.closed) {
      this.setState({ closed: true });
    }
  }

  reset() {
    const { reset, search } = this.props;
    reset();
    // @ts-ignore
    search();
  }

  render() {
    const { handleSubmit, query, loading, result, intl } = this.props;
    const { closed } = this.state;

    return (
      <form className={styles.searchBar} onSubmit={handleSubmit}>
        <div className={styles.inputGroup}>
          <div className={styles.inputGroupButton}>
            <button type='submit' className={styles.button} disabled={!query}>
              {!loading && <span className='icon-search' />}
              {loading && (
                <span className={classNames('icon-refresh', 'icon-spin')} />
              )}
            </button>
          </div>
          <Field
            name='query'
            component={QueryInput}
            search={this.searchDebounced}
            showSuggestions={this.showSuggestions}
            intl={intl}
          />
          {query && (
            <div className={styles.inputGroupButton}>
              <button
                type='button'
                className={styles.button}
                onClick={this.reset}
              >
                <FiX size={16} className={styles.iconCross} />
              </button>
            </div>
          )}
        </div>
        {!closed && query && (
          <SearchResult
            query={query}
            result={result}
            onSuggestionClick={this.closeSuggestions}
          />
        )}
      </form>
    );
  }
}

// @ts-ignore
export default injectIntl(onClickOutside(SearchBar));
