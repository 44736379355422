import { HeatmapV1ReportData as HeatmapReportData } from 'common/dist/types/reportData/heatmapV1ReportData';

import { data, days, hours } from './single/exampleData';
import { Config } from '../../../../../pages/augur/type';
export type { HeatmapReportData };

export type HeatmapConfig = {
  showNumbers?: boolean;
  yAxisLabel?: string;
  xAxisLabel?: string;
};
export type ConfigHeatmapConfig = Config<HeatmapConfig>;

export const configTypeHeatmap = `{
  showNumbers?: boolean;
  yAxisLabel?: string;
  xAxisLabel?: string;
}`;
export const reportDataTypeHeatmap = `{
  data: [number, number, number][],
  xAxis: string[],
  yAxis: string[]
}`;

export const defaultConfigHeatmap: ConfigHeatmapConfig = {};

export const defaultReportDataDefaultHeatmap: HeatmapReportData = {
  data: data,
  xAxis: hours,
  yAxis: days,
};
