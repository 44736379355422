import classNames from 'classnames';
import React, { FC, useState } from 'react';
import {
  Controller,
  FieldValues,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import { MdInfoOutline } from 'react-icons/md';
import { MessageDescriptor } from 'react-intl';

import styles from './styles.module.scss';
import { FieldInputProps } from '../../../molecules/augur-layout-elements/settings-elements/types/type';
import GenericCollapsibleContainer from '../../../organisms/collapsible-container/generic/GenericCollapsibleContainer';
import Modal from '../../../organisms/modal/Modal';
import { ButtonProps } from '../../button/Button';
import ExpandCollapseIcon from '../../expand-collapse-icon/ExpandCollapseIcon';
import InputError from '../../input-error/InputError';
import IntlWrapper from '../../intl-wrapper/IntlWrapper';
import { DeIntl } from '../../intl-wrapper/type';

export type TextInputAreaProps = {
  className?: string;
  label: string | MessageDescriptor;
  placeholder?: string | MessageDescriptor;
  error?: string | MessageDescriptor;
  amountRows?: number;
  isCollapsible?: boolean;
  initialCollapsed?: boolean;
  onToggle?: (isCollapsed: boolean) => void;
  description?: string;
  showInfo?: boolean; //Shows the info icon on the right
};

// FIXME-CM: this shouldn't really depend on the Dirk elements type
export type Props = TextInputAreaProps & Partial<FieldInputProps<string>>;

export const TextInputArea: FC<DeIntl<Props>> = (props) => {
  const [showModal, setModalShown] = useState(false);

  const {
    label,
    placeholder,
    amountRows = 5,
    className,
    isCollapsible,
    initialCollapsed = true,
    onToggle,
    description,
    showInfo,
    error,
    disabled,
    onChange,
    isTouched = false,
    value,
    onBlur,
    inputRef,
    name,
  } = props;

  const fieldClassName = classNames(
    styles.TextInputAreaInput,
    className,
    isTouched && {
      [styles.error]: error,
    }
  );

  function renderBody() {
    return (
      <textarea
        rows={amountRows}
        onChange={onChange}
        onBlur={onBlur}
        className={fieldClassName}
        placeholder={placeholder}
        disabled={disabled}
        style={{ resize: 'none' }}
        ref={inputRef}
        value={value || ''}
        aria-invalid={error ? 'true' : 'false'}
        name={name}
      />
    );
  }

  const buttons: ButtonProps[] = [
    {
      buttonColor: 'white',
      withLink: false,
      onClick: () => {
        setModalShown(false);
      },
      buttonLabelDefault: 'Ok',
    },
  ];

  function renderHeader() {
    return (
      <div className={styles.TextInputAreaHeader}>
        {label && <p className={styles.TextInputAreaLabel}>{label}</p>}
        {showInfo && (
          <MdInfoOutline
            size={17}
            style={{ marginLeft: 10 }}
            title={description}
            onClick={() => setModalShown(true)}
          />
        )}
        {showModal && (
          <Modal
            show={showModal}
            hideModal={() => setModalShown(false)}
            headline={{
              id: 'no-id',
              defaultMessage: 'Information',
            }}
            buttons={buttons}
          >
            <span>{description}</span>
          </Modal>
        )}
        {error && (
          <div className={styles.TextInputAreaError}>
            <InputError touched={isTouched} error={error} />
          </div>
        )}
      </div>
    );
  }

  function renderDefaultElement() {
    return (
      <div className={styles.TextInputArea}>
        {renderHeader()}
        {renderBody()}
      </div>
    );
  }

  function renderCollapsible() {
    return (
      <GenericCollapsibleContainer
        title={label}
        initialCollapsed={initialCollapsed}
        onToggle={(isCollapsed) => onToggle && onToggle(isCollapsed)}
        renderHeader={(title: string, collapsed: boolean) => (
          <div className={styles.Header}>
            <div className={styles.Icon}>
              <ExpandCollapseIcon isExpanded={!collapsed} onClick={() => {}} />
            </div>
            <div className={styles.TextInputArea}>{renderHeader()}</div>
          </div>
        )}
        renderBody={() => <div className={styles.Body}>{renderBody()}</div>}
      />
    );
  }

  return isCollapsible ? renderCollapsible() : renderDefaultElement();
};
export const IntlTextInputArea: FC<Props> = (props) => {
  return <IntlWrapper WrappedComponent={TextInputArea} props={props} />;
};

type ControllerPropsExtension = {
  rules?: RegisterOptions<FieldValues, string>;
};
//Specify explicitly which property has to be required
type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};
export type WrappedTextInputAreaProps = WithRequiredProperty<
  Props,
  'name' | 'isCollapsible' | 'initialCollapsed'
> &
  ControllerPropsExtension;
export const WrappedRHFTextInputArea: FC<WrappedTextInputAreaProps> = (
  props
) => {
  const { name, rules, onChange } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field, fieldState }) => {
        const { ref, ...rest } = field; // extract ref to pass as inputRef
        return (
          <IntlTextInputArea
            {...props}
            {...rest}
            {...fieldState}
            inputRef={ref}
            onChange={onChange ?? rest.onChange}
            error={fieldState.error?.message}
          />
        );
      }}
    />
  );
};

// DON'T ADD DEFAULT EXPORTS; IT'S TOO CONFUSING
