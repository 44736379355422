import classNames from 'classnames';
import { AugurSettingsDataOpt } from 'common/dist/types/augurSettings';
import {
  ConstraintChannel,
  ConstraintCommunication,
  ConstraintCommunicationGroup,
  Operator,
  ConstraintType,
  EnrichedConstraint,
} from 'common/dist/types/module.optimization';
import React, { FC } from 'react';
import { DeepPartial } from 'react-hook-form';
import { FiMinus } from 'react-icons/fi';
import { ConstraintsErrorType } from './CampaignOptimizationConstraints';
import {
  SPEAKING_CONSTRAINT_LEVEL,
  SPEAKING_CONSTRAINT_TYPE,
  SPEAKING_OPERATOR,
} from './common';
import styles from './styles.module.scss';
import { DropdownSelectInput } from '../../../../../atoms/react-hook-form-input-elements/dropdown-select-input/DropdownSelectInput';
import { IntlTextInputLine } from '../../../../../atoms/react-hook-form-input-elements/text-input-line/TextInputLine';
import commonStyles from '../../../../tuple-list-table/commonStyles.module.scss';

type Option = { label: string; value: string };

export type Props = {
  augurSettings: Pick<
    AugurSettingsDataOpt,
    'channels' | 'communications' | 'communicationGroups'
  >;
  rowIndex: number;
  constraintId: string;
  /** Show the remove icons on the right? */
  removableRows?: boolean;
  /** Callback for when a row is removed */
  onRemoveRow?: (removedRow: EnrichedConstraint) => void;
  value: EnrichedConstraint[];
  onChange?: (constraint: EnrichedConstraint[]) => void;
  invalid?: boolean;
  onBlur?: React.FocusEventHandler;
  error?: DeepPartial<ConstraintsErrorType>;
};

const constraintLevelOptions: Option[] = [
  'channel',
  'communication',
  'communicationGroup',
  'allCommunications',
].map((c) => ({
  // @ts-ignore
  label: SPEAKING_CONSTRAINT_LEVEL[c],
  value: c,
}));

const constraintTypeOptions: Option[] = [
  'quantity',
  'cost',
  'individualValue',
].map((c) => ({
  // @ts-ignore
  label: SPEAKING_CONSTRAINT_TYPE[c],
  value: c,
}));

const operatorOptions: Option[] = ['leq', 'geq', 'eq'].map((c) => ({
  // @ts-ignore
  label: SPEAKING_OPERATOR[c],
  value: c,
}));

const channelIdOptions = (props: Props): Option[] => {
  if (!props.augurSettings.channels) return [];

  return props.augurSettings.channels.map((ch) => ({
    value: ch.id,
    label: ch.name,
  }));
};

const communicationIdOptions = (props: Props): Option[] => {
  if (!props.augurSettings.communications) return [];
  return props.augurSettings.communications.map((ch) => ({
    value: ch.id,
    label: ch.name,
  }));
};

const communicationGroupIdOptions = (props: Props): Option[] => {
  if (!props.augurSettings.communicationGroups) return [];
  return props.augurSettings.communicationGroups.map((ch) => ({
    value: ch.id,
    label: ch.name,
  }));
};

const renderNameField = (props: Props) => {
  const { rowIndex = 0, error, invalid, value, onChange, onBlur, constraintId } =
    props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.name}
      invalid={invalid}
      label={'Name'}
      placeholder={'Name'}
      value={value?.[rowIndex]?.name}
      onChange={(e) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'name',
          rowIndex
        );
        onChange?.(newValue);
      }}
      onBlur={onBlur}
    />
  );
};

const drvNewValue = (
  value: EnrichedConstraint[],
  eventValue: string,
  key: string,
  rowIndex: number
) => {
  return [
    ...value.slice(0, rowIndex),
    {
      ...value[rowIndex],
      [key]: eventValue,
    },
    ...value.slice(rowIndex + 1),
  ];
};

const renderDescriptionField = (props: Props) => {
  const { rowIndex = 0, value, error, invalid, onChange, onBlur, constraintId } =
    props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.description}
      invalid={invalid}
      label={'Description'}
      placeholder={'description'}
      value={value[rowIndex]?.description}
      onChange={(e) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...value[rowIndex],
            description: e.target.value,
          },
          ...value.slice(rowIndex + 1),
        ];

        onChange?.(newValue);
      }}
      onBlur={onBlur}
    />
  );
};

const renderConstraintLevelField = (props: Props) => {
  const { rowIndex = 0, value, onChange, onBlur, error, constraintId } = props;
  return (
    <DropdownSelectInput<Option, false>
      id={'optConstraint_constraintlevel'}
      label={'Constraint Level'}
      placeholder={'Please select the Constraint Level'}
      options={constraintLevelOptions}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        const newValue = drvNewValue(
          value,
          option.value,
          'constraintLevel',
          rowIndex
        );

        if (option.value !== value[rowIndex]?.constraintLevel) {
          // If the constraint level was really changed: reset the fields that depend on the constraint level
          newValue[rowIndex] = {
            ...newValue[rowIndex],
            // @ts-ignore
            channelId: undefined,
            // @ts-ignore
            communicationId: undefined,
            // @ts-ignore
            communicationGroupId: undefined,
          };
        }

        onChange?.(newValue);
      }}
      value={constraintLevelOptions.find(
        (o) => o.value === value[rowIndex]?.constraintLevel
      )}
      error={error?.rows?.[constraintId]?.constraintLevel}
      isTouched={true}
    />
  );
};

const renderChannelIdField = (props: Props) => {
  const {
    rowIndex = 0,
    onChange,
    onBlur,
    error,
    constraintId,
    augurSettings,
    value,
  } = props;

  return (
    <DropdownSelectInput<Option, false>
      key={`channelId-${JSON.stringify(augurSettings.channels)}`}
      id={'optConstraint_channelId'}
      label={'Channel'}
      placeholder={'Please select a Channel'}
      options={channelIdOptions(props)}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...value[rowIndex],
            channelId: option.value,
          },
          ...value.slice(rowIndex + 1),
        ];

        onChange?.(newValue);
      }}
      value={channelIdOptions(props).find(
        (o) => o.value === (value as ConstraintChannel[])[rowIndex]?.channelId
      )}
      error={error?.rows?.[constraintId]?.channelId}
      isTouched={true}
    />
  );
};

const renderCommunicationIdField = (props: Props) => {
  const {
    rowIndex = 0,
    value,
    onChange,
    onBlur,
    error,
    constraintId,
    augurSettings,
  } = props;
  return (
    <DropdownSelectInput<Option, false>
      key={`communicationId-${JSON.stringify(augurSettings.communications)}`}
      id={'optConstraint_communicationId'}
      label={'Communication'}
      placeholder={'Please select a Communication'}
      options={communicationIdOptions(props)}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...value[rowIndex],
            communicationId: option.value,
          },
          ...value.slice(rowIndex + 1),
        ];

        onChange?.(newValue);
      }}
      value={communicationIdOptions(props).find(
        (o) =>
          o.value ===
          (value as ConstraintCommunication[])[rowIndex]?.communicationId
      )}
      error={error?.rows?.[constraintId]?.communicationId}
      isTouched={true}
    />
  );
};

const renderCommunicationGroupIdField = (props: Props) => {
  const {
    rowIndex = 0,
    value,
    onChange,
    onBlur,
    error,
    constraintId,
    augurSettings,
  } = props;
  return (
    <DropdownSelectInput<Option, false>
      key={`communicationGroupId-${JSON.stringify(
        augurSettings.communicationGroups
      )}`}
      id={'optConstraint_communicationGroupId'}
      label={'CommunicationGroup'}
      placeholder={'Please select a CommunicationGroup'}
      options={communicationGroupIdOptions(props)}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...value[rowIndex],
            communicationGroupId: option.value,
          },
          ...value.slice(rowIndex + 1),
        ];

        onChange?.(newValue);
      }}
      value={communicationGroupIdOptions(props).find(
        (o) =>
          o.value ===
          (value as ConstraintCommunicationGroup[])[rowIndex]
            ?.communicationGroupId
      )}
      error={error?.rows?.[constraintId]?.communicationGroupId}
      isTouched={true}
    />
  );
};

const renderConstraintTypeField = (props: Props) => {
  const { rowIndex = 0, value, onChange, onBlur, error, constraintId } = props;
  return (
    <DropdownSelectInput<Option, false>
      id={'optConstraint_constraintType'}
      label={'Constraint Type'}
      placeholder={'Please select the Constraint Type'}
      options={constraintTypeOptions}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...value[rowIndex],
            constraintType: option.value as ConstraintType,
          },
          ...value.slice(rowIndex + 1),
        ];

        onChange?.(newValue);
      }}
      value={constraintTypeOptions.find(
        (o) => o.value === value[rowIndex]?.constraintType
      )}
      error={error?.rows?.[constraintId]?.constraintType}
      isTouched={true}
    />
  );
};

const renderOperatorField = (props: Props) => {
  const { rowIndex = 0, value, onChange, onBlur, error, constraintId } = props;
  return (
    <DropdownSelectInput<Option, false>
      id={'optConstraint_operator'}
      label={'Operator'}
      placeholder={'Please select the Operator'}
      options={operatorOptions}
      onBlur={onBlur}
      // @ts-ignore
      onChange={(option: Option) => {
        const newValue = [
          ...value.slice(0, rowIndex),
          {
            ...value[rowIndex],
            operator: option.value as Operator,
          },
          ...value.slice(rowIndex + 1),
        ];

        onChange?.(newValue);
      }}
      value={operatorOptions.find((o) => o.value === value[rowIndex]?.operator)}
      error={error?.rows?.[constraintId]?.operator}
      isTouched={true}
    />
  );
};

const renderValueField = (props: Props) => {
  const {
    rowIndex = 0,
    value,
    onChange,
    onBlur,
    error,
    invalid,
    constraintId,
  } = props;
  return (
    <IntlTextInputLine
      isTouched={true}
      error={error?.rows?.[constraintId]?.value}
      invalid={invalid}
      label={'Value'}
      placeholder={'1000'}
      value={value[rowIndex].value}
      onChange={(e) => {
        const newValue = drvNewValue(
          value,
          e.target.value,
          'value',
          rowIndex
        );
        onChange?.(newValue);
      }}
      onBlur={onBlur}
    />
  );
};

const AddConstraint: FC<Props> = (props) => {
  const { value, rowIndex = 0, removableRows, onRemoveRow } = props;
  return (
    <div className={commonStyles.addElement}>
      {removableRows && (
        <div className={commonStyles.RemoveIconContainer}>
          <div
            className={commonStyles.RemoveIcon}
            onClick={() => onRemoveRow && onRemoveRow(value[rowIndex])}
          >
            <FiMinus size={16} />
          </div>
        </div>
      )}
      <div className={commonStyles.addElementFields}>
        <div className={commonStyles.addElementField}>
          {renderConstraintLevelField(props)}
        </div>

        {value[rowIndex]?.constraintLevel === 'channel' && (
          <div className={commonStyles.addElementField}>
            {renderChannelIdField(props)}
          </div>
        )}

        {value?.[rowIndex]?.constraintLevel === 'communication' && (
          <div className={commonStyles.addElementField}>
            {renderCommunicationIdField(props)}
          </div>
        )}

        {value?.[rowIndex]?.constraintLevel === 'communicationGroup' && (
          <div className={commonStyles.addElementField}>
            {renderCommunicationGroupIdField(props)}
          </div>
        )}

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.constraintTypeField
          )}
        >
          {renderConstraintTypeField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderOperatorField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderValueField(props)}
        </div>

        <div className={commonStyles.addElementField}>
          {renderNameField(props)}
        </div>

        <div
          className={classNames(
            commonStyles.addElementField,
            styles.descriptionField
          )}
        >
          {renderDescriptionField(props)}
        </div>
      </div>
    </div>
  );
};

export default AddConstraint;
