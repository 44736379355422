import { workbenchRoutes } from '../../../../workbench/common/workbenchRoutes';
import { openFile } from '../../../workbench/utils';
import {
  DeletePermanently,
  Restore,
} from '../context-menu/ContextMenu.defaults';
import { ContentElementType } from '../generic-file-browser/GenericFileBrowser';
import { EmptyRecycleBin } from '../icon-button-bar/IconButtonBar.defaults';

export const buttonBarButtons = [EmptyRecycleBin];

export const onClickListeners = {
  [ContentElementType.FILE_CODE]: openFile,
  [ContentElementType.FILE_DEFAULT]: undefined,
  [ContentElementType.FILE_MARKDOWN]: openFile,
  [ContentElementType.FILE_NOTEBOOK]: openFile,
  [ContentElementType.FILE_REPOSITORY_INFO]: openFile,
  [ContentElementType.FILE_TEXT]: openFile,
  [ContentElementType.FILE_AUGUR_SETTINGS]: openFile,
  [ContentElementType.FILE_CONFIG]: openFile,
  [ContentElementType.FILE_GITIGNORE]: openFile,
  [ContentElementType.DIRECTORY_PLAIN]: undefined,
  [ContentElementType.DIRECTORY_REPOSITORY]: undefined,
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: undefined,
  [ContentElementType.SPECIAL_LAUNCHER]: undefined,
  [ContentElementType.RECYCLE_BIN]: undefined,
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: (
    state,
    dispatch,
    history,
    selectedPath: string[],
    element
  ) => {
    history.push(
      `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}${history.location.search}`
    );
  },
};

export const contextMenuEntries = {
  [ContentElementType.FILE_CODE]: [Restore, DeletePermanently],
  [ContentElementType.FILE_DEFAULT]: [Restore, DeletePermanently],
  [ContentElementType.FILE_MARKDOWN]: [Restore, DeletePermanently],
  [ContentElementType.FILE_NOTEBOOK]: [Restore, DeletePermanently],
  [ContentElementType.FILE_REPOSITORY_INFO]: [Restore, DeletePermanently],
  [ContentElementType.FILE_TEXT]: [Restore, DeletePermanently],
  [ContentElementType.FILE_AUGUR_SETTINGS]: [Restore, DeletePermanently],
  [ContentElementType.FILE_CONFIG]: [Restore, DeletePermanently],
  [ContentElementType.FILE_GITIGNORE]: [Restore, DeletePermanently],
  [ContentElementType.DIRECTORY_PLAIN]: [Restore, DeletePermanently],
  [ContentElementType.DIRECTORY_REPOSITORY]: [Restore, DeletePermanently],
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: [],
  [ContentElementType.SPECIAL_LAUNCHER]: [],
  [ContentElementType.RECYCLE_BIN]: [],
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: [],
};

export const dnd = {
  [ContentElementType.FILE_CODE]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_DEFAULT]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_MARKDOWN]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_NOTEBOOK]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_REPOSITORY_INFO]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_TEXT]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_AUGUR_SETTINGS]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_CONFIG]: {
    isDraggable: false,
  },
  [ContentElementType.FILE_GITIGNORE]: {
    isDraggable: false,
  },
  [ContentElementType.DIRECTORY_PLAIN]: {
    isDraggable: false,
  },
  [ContentElementType.DIRECTORY_REPOSITORY]: {
    isDraggable: false,
  },
  [ContentElementType.SPECIAL_ONE_DIRECTORY_UP]: {
    isDraggable: false,
  },
  [ContentElementType.SPECIAL_LAUNCHER]: {
    isDraggable: false,
  },
  [ContentElementType.RECYCLE_BIN]: {
    isDraggable: false,
  },
  [ContentElementType.SPECIAL_LEAVE_RECYCLE_BIN]: {
    isDraggable: false,
  },
};
