import React, { FC, useEffect } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { fieldKernel, fieldName, form } from '../notebook.form';
import { workbenchRoutes } from '../../../../../../workbench/common/workbenchRoutes';
import Wizard from '../../../../../pages/wizard/Wizard';
import Step1 from './Step1.container';
import Step2 from './Step2.container';
import { Redirect } from 'react-router-dom';
import { ButtonProps } from '../../../../../atoms/button/Button';
import { JupyterNotebookFormat } from 'common/dist/utils/workbench/jupyterNotebookFormat';
import { useSelectedDirPath } from '../../../../hooks';
import { contentArrayToPath } from 'common/dist/utils/workbench/content';

interface Props {
  /** Headline of the Wizard. TODO Currently plain text, but should be made an intl ID */
  headline: string;
  /** Form value */
  selectedNotebookName: string | null;
  /** Form value */
  selectedKernel: JupyterNotebookFormat['metadata']['kernelspec'] | null;
  /** Is step 2 disabled? For example true when editing a notebook: The Kernel of an existing notebook can't be changed. */
  step2disabled: boolean;
  /** Click listener for the Save button */
  onSave: (
    selectedNotebookName: string,
    selectedDirPath: string,
    kernelName: string,
    kernelDisplayName: string,
    notebookOldPath: string
  ) => void;
  /** Click listener for the Cancel button */
  onCancel: () => void;
  /** Is a notebook currently being created */
  isCreatingNoteBook: boolean;
  /** Redirect flag back to workbench, if for example some crucial information is missing (clipboard, showEditNotebook name) */
  redirect?: boolean;
  /** Old path + name of the notebook that is supposed to be edited */
  notebookOldPath: string;
}

const NotebookWizard: FC<Props & InjectedFormProps> = ({
  headline,
  selectedNotebookName,
  selectedKernel,
  step2disabled,
  onSave,
  onCancel,
  valid,
  isCreatingNoteBook,
  redirect,
  initialValues,
  touch,
  notebookOldPath,
}) => {
  useEffect(() => {
    // Touch a field with initialValues so that it is shown as valid
    if (initialValues[fieldName]) {
      touch(fieldName);
    }
    if (initialValues[fieldKernel]) {
      touch(fieldKernel);
    }
  }, [initialValues, touch]);

  const selectedDirPath = useSelectedDirPath();

  if (redirect) {
    return <Redirect to={workbenchRoutes.basePath} />;
  }

  const buttons: ButtonProps[] = [
    {
      withLink: true,
      linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`,
      buttonColor: 'white',
      buttonLabelDefault: 'Cancel',
      onClick: onCancel,
      buttonId: 'NotebookWizard--cancel',
    },
    {
      withLink: true,
      linkTo: `${workbenchRoutes.basePath}${workbenchRoutes.fileBrowser}`,
      buttonColor: 'secondary',
      buttonLabelDefault: 'Finish',
      disabled: !valid && !isCreatingNoteBook,
      onClick: () => {
        if (valid && !isCreatingNoteBook) {
          const kernelName = selectedKernel ? selectedKernel.name : null;
          const kernelDisplayName = selectedKernel
            ? selectedKernel.display_name
            : null;
          onSave(
            selectedNotebookName,
            contentArrayToPath(selectedDirPath),
            kernelName,
            kernelDisplayName,
            notebookOldPath
          );
        }
      },
      buttonId: 'NotebookWizard--submit',
    },
  ];

  return (
    <div
      style={{
        paddingTop:
          '40px' /* This wizard requires additional space in the top to look good */,
      }}
    >
      <Wizard headlineDefault={headline} withHeadline={true} buttons={buttons}>
        <form
          onKeyPress={(e) => {
            if (e.which === 13) e.preventDefault();
          }}
        >
          <Step1 />
          {!step2disabled && <Step2 />}
        </form>
      </Wizard>
    </div>
  );
};

export default reduxForm(form)(NotebookWizard);
