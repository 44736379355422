import { DeepPartial } from 'react-hook-form';
import { Config } from '../../../../../pages/augur/type';
import { AllowedInputs } from '../../types/util';
import { TargetTaxonomyErrorType } from './TargetTaxonomy';
import {
  TargetTaxonomyV1AugurSettings as TargetTaxonomyAugurSettings,
  Taxonomy,
} from 'common/dist/types/augurSettings/targetTaxonomyV1AugurSettings';

export type { TargetTaxonomyAugurSettings, Taxonomy };

export type TargetTaxonomyConfig = {};
export type ConfigTargetTaxonomyConfig = Config<TargetTaxonomyConfig>;

export type PartialTargetTaxonomyAugurSettings =
  Partial<TargetTaxonomyAugurSettings>; // Partial<TargetTaxonomyAugurSettings>

export type TargetTaxonomyValidationError =
  DeepPartial<TargetTaxonomyErrorType>;

export const configAllowedInputsTargetTaxonomy: AllowedInputs<TargetTaxonomyConfig> =
  {};

export const configTypeTargetTaxonomy = `{
}`;

export const augurSettingsTypeTargetTaxonomy = `type Taxonomy = {
    id: string;
    name: string;
    sub: Taxonomy[];
  }

  settings = Taxonomy[]`;

export const defaultConfigTargetTaxonomy: ConfigTargetTaxonomyConfig = {};

export const defaultAugurSettingsDefaultTargetTaxonomy: PartialTargetTaxonomyAugurSettings =
  [];

// JSONSchemaType<TargetTaxonomyAugurSettings>
export const defaultValidationSchemaTargetTaxonomy = {};
