import classNames from 'classnames';
import { User } from 'common/dist/types/users';
import React, { Component, FC, useCallback } from 'react';

import styles from './styles.module.scss';
import { fetchUserPermissions } from '../../../../redux/modules/admin.users.module';
import { Users as UsersState } from '../../../../store/admin/state.types';
import { Header } from '../../../admin/users/user-list/UsersList';
import { RenderIntlError } from '../../../atoms/input-error/InputError';
import LoadingPlaceholder from '../../../atoms/loading-placeholder/LoadingPlaceholder';
import ThemedSwitch from '../../../atoms/themed-switch/ThemedSwitch';
import Paging from '../../../molecules/paging/Paging';

const PAGE_SIZE = 20;

type Props = {
  /** Name of the resource to list the group permissions for */
  resourceName: string;
  /** List of available scopes */
  scopes: {
    id: string;
    displayName: string;
  }[];
  /** Resource type */
  resourceType: string;

  /* --- Users --- */
  users: UsersState;

  userPermissions?: {
    [userId: string]: {
      data?: {
        [resourceName: string]: {
          name: string;
        }[];
      };
      loading?: boolean;
      loaded?: boolean;
      error?: string;
    };
  };

  loadUsersList: (
    fetchPermissions: boolean,
    offset?: number,
    limit?: number,
    search?: string
  ) => void;
  addUserPermission: (
    resourceName: string,
    resourceType: string,
    scopeName: string,
    user: User,
    refreshAction: () => void
  ) => void;
  removeUserPermission: (
    resourceName: string,
    scopeName: string,
    user: User,
    refreshAction: () => void
  ) => void;
};

export class Users extends Component<Props> {
  static defaultProps = {
    scopesView: true,
    scopesEdit: true,
  };

  sortUsers: (a: User, b: User) => number = (a, b) => {
    if (a.lastName > b.lastName) return 1;
    if (a.lastName < b.lastName) return -1;
    return a.firstName > b.firstName ? 1 : -1;
  };

  render() {
    const {
      resourceName,
      resourceType,
      addUserPermission,
      removeUserPermission,
      userPermissions,
      scopes,
      users,
    } = this.props;

    const { data, error, loading } = users;

    const sortedUsers = (data || []).sort(this.sortUsers);

    if (error) {
      return (
        <div className={'groups-permissions-container ct-list'}>
          <span className='center error'>
            Something went wrong trying to load users.
          </span>
          <br />
          <span className='center error'>
            <RenderIntlError error={JSON.stringify(error)} />
          </span>
        </div>
      );
    }

    const renderHeadline = () => {
      return (
        <div className={'ct-row ct-headline'}>
          <div className={'ct-col ct-col-200px'}>
            <span>First Name</span>
          </div>
          <div className={'ct-col ct-col-flex-grow'}>
            <span>{'Last Name'}</span>
          </div>
          {scopes.map((scope) => (
            <div className={'ct-col ct-col-80px'}>
              <span>{scope.displayName}</span>
            </div>
          ))}
        </div>
      );
    };

    if ((!data || data.length === 0) && loading) {
      return (
        <div className={'groups-permissions-container ct-list'}>
          {renderHeadline()}
          {Array.from({ length: 10 }).map((_, i) => (
            <div className={'ct-row'}>
              <div className={'ct-col ct-col-200px'}>
                <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
              </div>
              <div className={'ct-col ct-col-200px'}>
                <LoadingPlaceholder>Loading Placeholder</LoadingPlaceholder>
              </div>
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={'groups-permissions-container ct-list'}>
        {renderHeadline()}

        {sortedUsers?.map((user) => {
          const isTechnical =
            (user.attributes?.isTechnical || [''])[0] === 'true';
          if (isTechnical) {
            return (
              <div key={user.id} className={'ct-row'}>
                <div
                  className={'ct-col ct-col-200px'}
                  style={{ color: '#9f9f9f' }}
                >
                  [Technical User]
                </div>
              </div>
            );
          }
          return (
            <div
              key={user.id}
              className={classNames('ct-row', styles.permissionsRow)}
              data-testingIdentifier={user?.email}
            >
              <div className={'ct-col ct-col-200px'}>
                <span>{user.firstName}</span>
              </div>
              <div className={'ct-col ct-col-flex-grow-200px'}>
                <span>{user.lastName}</span>
              </div>

              {!userPermissions[user.id]?.error &&
                scopes.map((scope) => (
                  <div
                    key={`${user.id}_${scope.id}`}
                    className={'ct-col ct-col-80px'}
                    data-testingIdentifier={'accessToggle'}
                  >
                    <ThemedSwitch
                      onChange={() => {
                        if (
                          (
                            (userPermissions[user.id]?.data || [])[
                              resourceName
                            ] || []
                          )
                            .map((s) => s.name)
                            .includes(scope.id)
                        ) {
                          removeUserPermission(
                            resourceName,
                            scope.id,
                            user,
                            () => fetchUserPermissions(user.id)
                          );
                        } else {
                          addUserPermission(
                            resourceName,
                            resourceType,
                            scope.id,
                            user,
                            () => fetchUserPermissions(user.id)
                          );
                        }
                      }}
                      checked={(
                        (userPermissions[user.id]?.data || [])[resourceName] ||
                        []
                      )
                        .map((s) => s.name)
                        .includes(scope.id)}
                      height={20}
                      width={40}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
                      activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
                      themeColor={'primary'}
                      isLoading={userPermissions[user.id]?.loading}
                    />
                  </div>
                ))}
              {userPermissions[user.id]?.error && (
                <div className={'ct-col ct-col-flex-grow-200px'}>
                  <span className='center error'>
                    Something went wrong trying to load user permissions.
                  </span>
                  <br />
                  <span className='center error'>
                    <RenderIntlError error={userPermissions[user.id]?.error} />
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

const UsersPaginated: FC<Props> = (props) => {
  const { loadUsersList, users } = props;
  const updatePagingParameters = useCallback(
    (offset?: number, limit?: number, search?: string) =>
      loadUsersList(true, offset, limit, search),
    [loadUsersList]
  );
  return (
    <Paging
      itemsPerPage={PAGE_SIZE}
      updatePagingParameters={updatePagingParameters}
      Headline={Header}
      currentItems={users?.data?.length || 0}
      searchEnabled
    >
      <Users {...props} />
    </Paging>
  );
};

export default UsersPaginated;
