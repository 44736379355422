import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';

import Node from './Node';
import Hierarchy from './Hierarchy';

import { ToBeRefined } from 'common/dist/types/todo_type';
import { Taxonomy } from 'common/dist/types/augurSettings';
import styles from './styles.module.scss';
import classNames from 'classnames';

type Props = {
  options: Taxonomy[];
  value: string[];
  onChange: ((...event: any[]) => void) | undefined;
  loading: boolean;
  loaded: boolean;
};

type State = {
  /** Value of the text input field that is used as a filter for the options */
  filterValue: string;
};

/**
 * Determines whether an item is supposed to be rendered depending on the filter value of the input field.
 * If there is a search query in the input field, elements are displayed if either the query matches the element itself
 * or one of the children (in which case the parent should be rendered too, to keep the hierarchy).
 * @param target
 * @param filterValue
 */
function shouldItemRender(target: Taxonomy, filterValue: string): boolean {
  // @ts-ignore
  return (
    target.name.toLowerCase().indexOf(filterValue) !== -1 ||
    (target.sub && target.sub.some((t) => shouldItemRender(t, filterValue)))
  );
}

/**
 * Returns the name of an Taxonomy
 * @param item
 */
function getItemValue(item: Taxonomy) {
  return item.name;
}
export default class HierarchyCheckboxes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.handleFilterValueChange = this.handleFilterValueChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.renderMenu = this.renderMenu.bind(this);
    this.renderItem = this.renderItem.bind(this);

    this.state = {
      filterValue: '',
    };
  }

  handleFilterValueChange(event: ToBeRefined, filterValue: string) {
    // Change of the text input! not of the checkboxes.
    this.setState({ filterValue });
  }

  handleSelect(selectedIds: string[], isAdded: boolean) {
    const { value, onChange } = this.props;
    const previousValues = Array.isArray(value) ? value : [];
    if (isAdded) {
      if(onChange) {
        onChange([...previousValues, ...selectedIds]);
      }
    } else {
      if(onChange) {
        onChange(previousValues.filter((tid) => !selectedIds.includes(tid)));
      }
    }
    this.setState({ filterValue: '' });
  }

  renderMenu(items: React.ReactNode[]) {
    const { loading, options, value } = this.props;
    if (loading) {
      // --- Loading
      return (
        <div className={styles.HierarchyCheckboxes_targets_menu}>
          <div style={{ padding: 6 }}>Loading...</div>
        </div>
      );
    } else if (
      items.length === 0 &&
      (this.state.filterValue || '').length > 0
    ) {
      // --- No targets for the given filter query
      return (
        <div className={styles.HierarchyCheckboxes_targets_menu}>
          <div style={{ padding: 6 }}>
            There are no targets for the given filter query
          </div>
        </div>
      );
    } else if (items.length === 0) {
      // --- Empty - no taxonomy has been specified yet
      return (
        <div className={styles.HierarchyCheckboxes_targets_menu}>
          <div style={{ padding: 6 }}>
            The Taxonomy of the prediction targets has not been specified yet.
            Please go to the Taxonomy page and specify the targets.
          </div>
        </div>
      );
    } else {
      // --- Loaded and has elements
      return (
        <div className={styles.HierarchyCheckboxes_targets_menu}>
          <Hierarchy
            options={options}
            onChange={this.handleSelect}
            initial={value}
          >
            {/* @ts-ignore */}
            {items}
          </Hierarchy>
        </div>
      );
    }
  }

  renderItem(item: Taxonomy) {
    const { filterValue } = this.state;
    const tempItem = {
      ...item,
      sub: item.sub && item.sub.filter((t) => shouldItemRender(t, filterValue)),
    };
    return <Node key={item.id} element={tempItem} />;
  }

  render() {
    const { options, loaded } = this.props;

    return (
      <div className={styles.HierarchyCheckboxesInput}>
        <div className={styles.HierarchyCheckboxes_input}>
          <Autocomplete
            open
            value={this.state.filterValue}
            getItemValue={getItemValue}
            items={options}
            renderItem={this.renderItem}
            renderMenu={this.renderMenu}
            shouldItemRender={shouldItemRender}
            onChange={this.handleFilterValueChange}
            wrapperStyle={{
              display: 'block',
            }}
            inputProps={{
              id: 'hierarchy-checkboxes',
              className: classNames(
                styles.HierarchyCheckboxes_autocomplete_input,
                styles.form_field
              ),
              disabled: !loaded,
              name: 'hierarchy-checkboxes',
              placeholder: 'Type here to start searching...',
            }}
          />
        </div>
      </div>
    );
  }
}
