import { axisBottom } from 'd3-axis';
import { ScaleBand } from 'd3-scale';
import { select } from 'd3-selection';
import React, { FC, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  scale: ScaleBand<string>;
};

const AxisBottom: FC<Props> = (props) => {
  const { scale } = props;

  const ticksRef = useRef<SVGGElement>(null);

  useEffect(() => {
    renderAxisTicks();
  }, [scale]);

  const renderAxisTicks = () => {
    const axis = axisBottom(scale);

    if (ticksRef.current) {
      select(ticksRef.current).call(axis);

      select(ticksRef.current)
        .selectAll('text')
        .attr('transform', 'translate(0, 10) rotate(-10)');
    }
  };

  return (
    <g className='chartAxis'>
      <g className='bottomTicks' ref={ticksRef} />
    </g>
  );
};

export default AxisBottom;
