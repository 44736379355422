import { init, getInstanceByDom } from 'echarts';
import type { EChartsOption, ECharts, SetOptionOpts } from 'echarts';
import React, { useRef, useEffect } from 'react';
import type { CSSProperties, FC } from 'react';

export interface ReactEChartsProps {
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
}

// Component copied from
// https://dev.to/manufac/using-apache-echarts-with-react-and-typescript-353k
const EChartWrapper: FC<ReactEChartsProps> = ({
  option,
  style,
  settings,
  loading,
}): JSX.Element => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current);
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
    // style needs to be a dependency to resize properly when it changes
  }, [style]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option as unknown, settings);
    }
  }, [option, settings]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (loading) {
        chart?.showLoading();
      } else {
        chart?.hideLoading();
      }
    }
  }, [loading]);

  return (
    <div ref={chartRef} style={{ width: '100%', height: '100%', ...style }} />
  );
};

export default EChartWrapper;
