import { Location, LocationDescriptorObject } from 'history';
import _ from 'lodash';
import qs from 'qs';
import {
  ChangeEvent,
  FocusEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { WrappedFieldProps } from 'redux-form';
import { EventHandler, WrappedFieldInputProps } from 'redux-form/lib/Field';
import tinycolor from 'tinycolor2';

import vars from '../scss/base/var.module.scss';

export type Result<T, E> = Ok<T, E> | Err<T, E>;

export class Ok<T, E> {
  public constructor(public readonly value: T) {}

  public isOk(): this is Ok<T, E> {
    return true;
  }

  public isErr(): this is Err<T, E> {
    return false;
  }
}

export class Err<T, E> {
  public constructor(public readonly error: E) {}

  public isOk(): this is Ok<T, E> {
    return false;
  }

  public isErr(): this is Err<T, E> {
    return true;
  }
}

/**
 * Construct a new Ok result value.
 */
export const ok = <T, E>(value: T): Ok<T, E> => new Ok(value);

/**
 * Construct a new Err result value.
 */
export const err = <T, E>(error: E): Err<T, E> => new Err(error);

/**
 * Take an object apply the function to each element and return the resulting object
 * @param object
 * @param mapFn
 */
export function mapObject<T, R, O>(
  object: { [key in keyof Required<O>]: T },
  mapFn: (key: keyof Required<O>, val: T) => R
): { [key in keyof Required<O>]: R } {
  const result: { [key in keyof Required<O>]: R } = {} as any;
  // @ts-ignore
  Object.keys(object).forEach((key) => (result[key] = mapFn(key, object[key])));
  return result;
}

export type LoadableData<Data, Error> = {
  loading: boolean;
  loaded: boolean;
  data?: Data;
  // TODO shouldn't this be optional?
  error: Error;
};

export function mapData<Data, Error, Result>(
  loadableData: LoadableData<Data, Error>,
  transform: (data: Data) => Result
): LoadableData<Result, Error> {
  if (loadableData.data)
    return {
      ...loadableData,
      data: transform(loadableData.data),
    };
  else
    return {
      ...loadableData,
      data: undefined,
    };
}

/**
 * Update the passed location by adding in the passed query object
 * @param location
 * @param updatedQuery
 */
export function locationWithUpdatedQuery(
  location: Location,
  updatedQuery: Record<string, string>
): Location {
  return {
    ...location,
    search: qs.stringify({
      ...qs.parse(location.search, { ignoreQueryPrefix: true }),
      ...updatedQuery,
    }),
  };
}

/**
 * Update the passed location by removing the passed keys from the query object
 * @param location
 * @param removedQuery
 */
export function locationWithRemovedQuery(
  location: Location,
  removedQuery: string[]
): Location {
  const remaining = Object.entries(
    qs.parse(location.search, { ignoreQueryPrefix: true })
  ).filter(([key, value]) => !removedQuery.includes(key));
  return {
    ...location,
    search: qs.stringify(Object.fromEntries(remaining)),
  };
}

export function locationWithWhitelistQuery(
  location: Location,
  whitelistQuery: string[]
): Location {
  const remaining = Object.entries(
    qs.parse(location.search, { ignoreQueryPrefix: true })
  ).filter(([key, value]) => whitelistQuery.includes(key));
  return {
    ...location,
    search: qs.stringify(Object.fromEntries(remaining)),
  };
}

export function toLinkWhitelistQuery(
  to: string,
  location: Location,
  whitelistQuery: string[]
): LocationDescriptorObject {
  return {
    pathname: to,
    search: locationWithWhitelistQuery(location, whitelistQuery).search,
  };
}

export interface TypedEventOrValueHandler<Event, T>
  extends EventHandler<Event> {
  (value: T): void;
}

interface TypedWrappedFieldInputProps<T> extends WrappedFieldInputProps {
  value: T;
  onBlur: TypedEventOrValueHandler<FocusEvent, T>;
  onChange: TypedEventOrValueHandler<ChangeEvent, T>;
}

export interface TypedWrappedFieldProps<T> extends WrappedFieldProps {
  input: TypedWrappedFieldInputProps<T>;
}

/**
 * Custom hook to obtain style values.
 * @param key The key of the field of which the value should get extracted.
 */
export const useCustomProperty = (key: string) => {
  const [property, setProperty] = useState<string>(null);

  useEffect(() => {
    const value = getComputedStyle(document.documentElement)
      .getPropertyValue(key)
      .trim();
    setProperty(value);
  }, [key]);

  return property;
};

export type ThemeColor = 'primary' | 'secondary' | 'primary-highlight';
export type ThemeColorModifier =
  | ''
  | '-lighter30'
  | '-lighter60'
  | '-lighter90'
  | '-lighter99'
  | '-darker30'
  | '-darker60'
  | '-darker90'
  | '-transparent';

/**
 * Custom hook to obtain the value of a theme color.
 * Necessary when one wants to use the actual color value for further computations instead of just using it for simple styling.
 * @param color The key used for this theme color (e.g. primary or secondary).
 * @param modifier This can be used to get the lighter/darker/.. variants of the color.
 */
export const useThemeColor = (
  color: ThemeColor,
  modifier: ThemeColorModifier = ''
) => {
  const col = useCustomProperty(`--color-${color}${modifier}`);

  if (!col) {
    return getDefaultColor(color, modifier);
  }
  return col;
};

export const getDefaultColor = (
  color: ThemeColor,
  modifier: ThemeColorModifier = ''
) => {
  let col;
  switch (color) {
    case 'primary-highlight':
      col = vars.colorPrimaryHighlightDefault;
      break;
    case 'secondary':
      col = vars.colorSecondaryDefault;
      break;
    default:
      col = vars.colorPrimaryDefault;
      break;
  }

  const { h, s, l } = tinycolor(col).toHsl();
  switch (modifier) {
    case '-lighter30':
      return tinycolor({ h, s, l: l + (1 - l) * 0.3 }).toHexString();
    case '-lighter60':
      return tinycolor({ h, s, l: l + (1 - l) * 0.6 }).toHexString();
    case '-lighter90':
      return tinycolor({ h, s, l: l + (1 - l) * 0.9 }).toHexString();
    case '-lighter99':
      return tinycolor({ h, s, l: l + (1 - l) * 0.99 }).toHexString();
    case '-darker30':
      return tinycolor({ h, s, l: l - l * 0.3 }).toHexString();
    case '-darker60':
      return tinycolor({ h, s, l: l - l * 0.6 }).toHexString();
    case '-darker90':
      return tinycolor({ h, s, l: l - l * 0.6 }).toHexString();
    case '-transparent':
      return tinycolor(col).setAlpha(0.66).toHex8String();
    default:
      return col;
  }
};

/**
 * Custom hook to obtain the path of the logo for a custom theme.
 */
export const useThemeLogoPath = () => useCustomProperty('--theme-logo-path');

/**
 * Custom hook to obtain the dimensions of an HTML element.
 * @see https://stackoverflow.com/a/60218754
 */
export function useDimensions<T extends Element>(
  updateTime?: number
): [
  MutableRefObject<T>,
  {
    width: number;
    height: number;
    left: number;
    top: number;
  }
] {
  const ref: MutableRefObject<T> = useRef<T>();

  const getDimensions = () => {
    return {
      width: ref.current ? ref.current.getBoundingClientRect().width : 0,
      height: ref.current ? ref.current.getBoundingClientRect().height : 0,
      left: ref.current ? ref.current.getBoundingClientRect().left : 0,
      top: ref.current ? ref.current.getBoundingClientRect().top : 0,
    };
  };

  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
    left: number;
    top: number;
  }>(getDimensions);

  const updateDimensions = useCallback(() => {
    setDimensions(getDimensions());
  }, []);

  // this is responsible for supplying the dimensions before the initial render
  useLayoutEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  // this is responsible for supplying the dimensions after the element was dynamically resized
  useEffect(() => {
    const target = ref.current;
    if (!target) return;

    const resizeObserver = new ResizeObserver(
      updateTime ? _.debounce(updateDimensions, updateTime) : updateDimensions
    );
    resizeObserver.observe(target);
    return () => resizeObserver.unobserve(target);
  }, [updateDimensions, updateTime]);

  return [ref, dimensions];
}

export function isValidNumber(str: string) {
  const num = parseFloat(str);
  return !Number.isNaN(num) && num.toString() === str.trim();
}

export const extractErrorMessage = (error: Error): string => {
  let errorMessage: string;
  const jsonMatch = error.message.match(/{.*}/);
  if (jsonMatch) {
    try {
      const errorDetails = JSON.parse(jsonMatch[0]);
      errorMessage = errorDetails?.message.split('(')?.[0];
    } catch (e) {
      // If JSON parsing fails, fallback to the original message
      errorMessage = error.message;
    }
  } else {
    // Fallback if the regex match does not work as expected
    errorMessage = error.message;
  }

  // we have to remove pointy brackets because intl can't handle them
  return errorMessage?.replaceAll('<', '')?.replaceAll('>', '');
};
