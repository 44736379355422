// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/constraint-exhaustion/v1/type.ts' --type 'ConfigConstraintExhaustionConfig'
export const schemaConfigConstraintExhaustion = {
  "$ref": "#/definitions/ConfigConstraintExhaustionConfig",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "definitions": {
    "Config<ConstraintExhaustionConfig>": {
      "additionalProperties": {
        "$ref": "#/definitions/ConfigEntry%3Cnever%3E"
      },
      "description": "Describes the non-resolved config of a layout element.",
      "type": "object"
    },
    "ConfigConstraintExhaustionConfig": {
      "$ref": "#/definitions/Config%3CConstraintExhaustionConfig%3E"
    },
    "ConfigEntry<never>": {
      "additionalProperties": false,
      "description": "A single config entry. Is given a generic type T that corresponds to a field in a ConfigType of a layout element.",
      "properties": {
        "elementUuid": {
          "type": "string"
        },
        "source": {
          "const": "input-element",
          "type": "string"
        }
      },
      "required": [
        "source",
        "elementUuid"
      ],
      "type": "object"
    }
  }
}