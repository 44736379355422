import { ScaleBand, ScaleLinear } from 'd3-scale';
import React, { FC } from 'react';

import StackedBar from './StackedBar';

import { PieChartData } from '../type';

type Props = {
  data: (PieChartData['data'] | undefined)[];
  xScale: ScaleBand<number>;
  yScale: ScaleLinear<number, number>;
  colors: { [label: string]: string };
  legendHoverIndex: number;
};

const StackedBars: FC<Props> = (props) => {
  const { data, xScale, yScale, colors, legendHoverIndex } = props;

  xScale.domain(data.map((x, index) => +index));
  yScale.domain([0, 100]);

  return (
    <>
      {data.map((item, index) => (
        <StackedBar
          key={index}
          xScale={xScale}
          yScale={yScale}
          item={item}
          index={index}
          colors={colors}
          legendHoverIndex={legendHoverIndex}
        />
      ))}
    </>
  );
};

export default StackedBars;
