import msgsWorkbench from 'common/dist/messages/workbench';
import { K8sResources } from 'common/dist/types/moduleVersion';
import { ToBeRefined } from 'common/dist/types/todo_type';
import React, { FC, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Notifications from 'react-notification-system-redux';
import { useSelector } from 'react-redux';

import StartServerButton from './StartServerButton.container';
import styles from './styles.module.scss';
import { useNodeInfo } from '../../../../core/api/codeCapsules';
import { RootState } from '../../../../store/store';
import DropdownSelectInput from '../../../atoms/input-elements/dropdown-select-input/DropdownSelectInput';
import K8sNodeInfo from '../../../molecules/k8s-node-info/K8sNodeInfo';
import { K8sResourcesSelect } from '../../../molecules/k8s-resources-select/K8sResourcesSelect';
import CollapsibleHeadlineArea from '../../../organisms/collapsible-container/collapsible-headline-area/CollapsibleHeadlineArea';
import MainContainer from '../../../pages/main-container/MainContainer';

type Props = {
  notifications: ToBeRefined[];
};

type Image = {
  label: string;
  value: string;
};

export type FormData = K8sResources & { image: string };

const StartServer: FC<Props> = (props) => {
  const notifications = useSelector<RootState, ToBeRefined[]>(
    (state) => state.workbench.notifications
  );

  const images = useSelector<RootState, Image[]>(
    (state) => state.config?.images
  );

  const isSpawning = useSelector<RootState, boolean>(
    (state) => state.workbench.notebookStarting.loading
  );
  //Get the currentuser attributes
  const userAttributes = useSelector<
    RootState,
    Record<string, string> | undefined
  >((state) => state.currentUser.attributes);
  const nodeInfo = useNodeInfo();

  const [message, onMessageChange] = useState('');

  const form = useForm<FormData, 'K8sResources'>({
    mode: 'all',
  });

  //We need this option to determine which form has to be rendered
  form.control._options.context = 'K8sResources';

  const { isValid } = useFormState({ control: form.control });

  return (
    <MainContainer transparent={true} fullWidth>
      <div className={styles.startServer}>
        <div className={styles.startServerParent} id={'start-server-parent'}>
          <div
            id={'start-server-headline-parent'}
            className={styles.startServerHeadlineParent}
          >
            <span className={styles.startServerHeadline}>
              Start your AI Workbench Server
            </span>
          </div>
          <div
            id={'start-server-button-parent'}
            className={styles.startServerButtonParent}
          >
            <div className={styles.imageSelectParent}>
              <span className={styles.imageSelectHeadline}>
                Workbench Image
              </span>
              <Controller
                name={'image'}
                control={form.control}
                defaultValue={images?.[0]?.value}
                render={({ field }) => (
                  <DropdownSelectInput
                    id={'workbench-image-select'}
                    isLoading={false}
                    disabled={isSpawning}
                    scrollMenuIntoView
                    options={images || []}
                    value={images?.find((i) => i.value === field.value)}
                    onChange={(selectedOption: {
                      label: string;
                      value: string;
                    }) => {
                      field.onChange(selectedOption?.value);
                    }}
                    label={msgsWorkbench.workbenchSelectImage}
                  />
                )}
              />
            </div>
            <div className={styles.resourcesSelectParent}>
              <K8sResourcesSelect
                nodeInfo={nodeInfo?.data}
                userAttributes={userAttributes || {}}
                //@ts-ignore FIXME-CM
                form={form}
              />
            </div>
            <CollapsibleHeadlineArea
              title={{ id: 'no-id', defaultMessage: 'Kubernetes Node Info' }}
              initialCollapsed
              renderBody={() => (
                <K8sNodeInfo renderTitle={false} nodeInfo={nodeInfo?.data} />
              )}
            />

            <StartServerButton
              onMessageChange={onMessageChange}
              handleSubmit={form.handleSubmit}
              isFormValid={isValid}
            />
          </div>
          <div
            id={'start-server-message-parent'}
            className={styles.startServerMessageParent}
          >
            <span>{message}</span>
          </div>
        </div>
      </div>
      <Notifications notifications={notifications} />
    </MainContainer>
  );
};

export default StartServer;
