// npx ts-json-schema-generator --no-type-check --path 'src/js/components/molecules/augur-layout-elements/report-elements/image/v1/type.ts' --type 'ConfigImageConfig'
export const schemaConfigImage = {
  $ref: '#/definitions/ConfigImageConfig',
  $schema: 'http://json-schema.org/draft-07/schema#',
  definitions: {
    'Config<ImageConfig>': {
      additionalProperties: false,
      description: 'Describes the non-resolved config of a layout element.',
      type: 'object',
    },
    ConfigImageConfig: {
      $ref: '#/definitions/Config%3CImageConfig%3E',
    },
  },
};
