import { StylesConfig } from 'react-select/dist/declarations/src/styles';

import styles from './styles.module.scss';
import vars from '../../../../../scss/base/var.module.scss';

const borderRadius = '4px';
export const customStyles = <Option, IsMulti extends boolean>(
  appearance?: 'regular' | 'small' | 'multiLines'
): StylesConfig<Option, IsMulti> => {
  // --- Appearance related overrides
  const appearanceOverridesControl: Record<string, unknown> = {};
  const appearanceOverridesValueContainer: Record<string, unknown> = {};
  const appearanceOverridesIndicatorsContainer: Record<string, unknown> = {};
  const appearanceOverridesInput: Record<string, unknown> = {};
  const appearanceOverridesInputInput: Record<string, unknown> = {};
  const appearanceOverridesDropdownIndicator: Record<string, unknown> = {};
  const appearanceOverridesDropdownIndicatorSvg: Record<string, unknown> = {};
  const appearanceOverridesPlaceholder: Record<string, unknown> = {};
  const appearanceOverridesOption: Record<string, unknown> = {};
  const appearanceOverridesOptionAfter: Record<string, unknown> = {};
  if (appearance === 'small') {
    appearanceOverridesControl['height'] = '30px';
    appearanceOverridesControl['minHeight'] = '30px';
    appearanceOverridesControl['minWidth'] = 'unset';
    appearanceOverridesValueContainer['height'] = '28px';
    appearanceOverridesIndicatorsContainer['height'] = '28px';
    appearanceOverridesInput['height'] = '24px';
    appearanceOverridesInputInput['height'] = '20px';
    appearanceOverridesDropdownIndicator['padding'] = '3px';
    appearanceOverridesPlaceholder['height'] = '20px';
    appearanceOverridesOption['paddingTop'] = '4px';
    appearanceOverridesOption['paddingBottom'] = '4px';
    appearanceOverridesOptionAfter['top'] = '0';
    appearanceOverridesDropdownIndicatorSvg['height'] = '18px';
    appearanceOverridesDropdownIndicatorSvg['width'] = '18px';
  } else if (appearance === 'multiLines') {
    appearanceOverridesControl['height'] = 'unset';
    appearanceOverridesValueContainer['height'] = 'unset';
    appearanceOverridesIndicatorsContainer['height'] = 'unset';
    appearanceOverridesInput['height'] = 'unset';
    appearanceOverridesInputInput['height'] = 'unset';
  }

  return {
    // @ts-ignore
    control: (provided, state) => ({
      ...provided,
      border: `1px solid ${styles.colorTextLight}`,
      '&:hover': {
        borderColor: styles.colorTextLight,
      },
      borderBottomLeftRadius: state.menuIsOpen ? 0 : borderRadius,
      borderBottomRightRadius: state.menuIsOpen ? 0 : borderRadius,
      boxShadow: 'none',
      height: '46px',
      cursor: 'pointer',
      ...appearanceOverridesControl,
    }),
    // @ts-ignore
    valueContainer: (provided, state) => ({
      ...provided,
      height: '44px',
      ...appearanceOverridesValueContainer,
    }),
    // @ts-ignore
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '44px',
      ...appearanceOverridesIndicatorsContainer,
    }),
    // @ts-ignore
    input: (provided, state) => ({
      ...provided,
      height: '40px',
      input: {
        ...((provided.input as Record<string, unknown>) || {}),
        height: '36px',
        ...appearanceOverridesInputInput,
      },
      ...appearanceOverridesInput,
    }),
    // @ts-ignore
    placeholder: (provided, state) => ({
      ...provided,
      ...appearanceOverridesPlaceholder,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    // @ts-ignore
    menu: (provided, state) => ({
      ...provided,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      marginTop: 0,
      border: `1px solid ${styles.colorTextLight}`,
      borderTopWidth: 0,
      boxShadow: 'none',
      zIndex: 100,
    }),
    // @ts-ignore
    menuList: (provided, state) => ({
      ...provided,
      padding: 0,
      // backgroundColor: 'white',
    }),
    // @ts-ignore
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
    // @ts-ignore
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: vars.colorPrimary,
      '&:hover': {
        color: vars.colorPrimary,
      },
      svg: {
        width: '24px',
        height: '24px',
        ...appearanceOverridesDropdownIndicatorSvg,
      },
      ...appearanceOverridesDropdownIndicator,
    }),
    // @ts-ignore
    option: (provided, state) => ({
      ...provided,
      color: styles.colorText,
      backgroundColor: state.isSelected
        ? vars.colorSecondaryLighter90
        : 'transparent',
      position: 'relative',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: styles.colorLightGrey,
      },

      '&:after': {
        content: state.isSelected ? '"\\e904"' : undefined,
        fontFamily: 'dashboardNG',
        position: 'absolute',
        fontSize: '18px',
        right: '10px',
        top: '4px',
        lineHeight: '23px',
        color: vars.colorSecondary,
        ...appearanceOverridesOptionAfter,
      },
      '&:last-child': {
        borderBottomLeftRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
      ...appearanceOverridesOption,
    }),
    // @ts-ignore
    clearIndicator: (provided, state) => ({
      ...provided,
      color: styles.colorTextLight,
      paddingRight: 0,
      '&:hover': {
        color: styles.colorRedDark,
      },
      svg: {
        width: '18px',
        height: '18px',
      },
    }),
  };
};
